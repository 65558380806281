/**
 * Created by wangli on 2019/5/30 0030.
 */
 //18589055778 测试账号
const serverList = {
  tmp: "http://192.168.18.144:50123/hxy",
  dev: "http://192.168.20.17:12080/hxy",
  dev18: "http://192.168.20.18:12080/hxy",
  devhn: "http://192.168.20.17:12080/hxy-hunan",
  test: "http://47.107.108.226:80/hxy",
  yunshuitest: "http://139.159.186.51:80/hxy",
  product: "https://new.huixiaoyun.com/hxy",
  hu: "http://192.168.20.88:8083/hxy",
  liang: "http://192.168.20.126:8081/hxy",
  liuhaitao: "http://192.168.20.207/hxy",
  hudeyuan: "http://192.168.18.88:8083/hxy",
  test8080: window.location.protocol + "//"+ window.location.host+':8080/hxy',
  server: window.location.protocol + "//"+ window.location.host+'/hxy',
}

export const webUrlList = {
  login: "http://localhost:3000/#/login",
  home: "http://localhost:3000/",
  test: "http://47.107.108.226:8080/hxy",
  product: "https://www.huixiaoyun.com/interface",
  ht2DEditor: 'https://2d.huixiaoyun.com',
  ht3DEditor: 'https://3d.huixiaoyun.com'
};

const requestConfigTemp = {
  "url": "#",
  "data": {},
  "type": "GET",
  "async": false,
  "cache": false,
  "dataType": "json",
  "error": function(){},
  "success": function(){},
  "xhrFields": {"withCredentials": true},
  "headers": {"Accept": "application/json, text/plain, */*", "ws_auth_token": "123"}
};

const serverUrl = serverList.server;
const homeUrl = webUrlList.home;
const loginUrl = webUrlList.login;

//0 新增站点对象 1 新增功能位置 2 新增设备对象 3 新增总加组
const subNodeType = {
  station: 0,
  location: 1,
  equipment: 2,
  group: 3
}

const accountType = {
  normal: '0', //普通账号
  admin: '1' //管理账号
}

const ht2DUrl = "https://2d.huixiaoyun.com/";
const ht3DUrl = "https://3d.huixiaoyun.com/";

export {
  serverUrl,
  homeUrl,
  loginUrl,
  ht2DUrl,
  ht3DUrl,
  requestConfigTemp,

  subNodeType,
  accountType
}
