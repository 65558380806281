import { put, takeLatest, call, } from 'redux-saga/effects';
import { errorBox, successBox } from "../../common";

import { getService } from "./SDNDatauploadConstant";
import { yunshui, hunan } from "../../service";

export const DATAUPLOAD_initPage = 'HN_DATAUPLOAD_initPage';

export const DATAUPLOAD_setShowModel = 'HN_DATAUPLOAD_setShowModel';
export const DATAUPLOAD_setShowEdit = 'HN_DATAUPLOAD_setShowEdit';

export const DATAUPLOAD_importFile = 'HN_DATAUPLOAD_importFile';
export const HN_DATAUPLOAD_importFile_success = 'HN_DATAUPLOAD_importFile_success';

export const DATAUPLOAD_delData = 'HN_DATAUPLOAD_delData';
export const HN_DATAUPLOAD_delData_success = 'HN_DATAUPLOAD_delData_success';

export const DATAUPLOAD_getData = 'HN_DATAUPLOAD_getData';
export const HN_DATAUPLOAD_getData_success = 'HN_DATAUPLOAD_getData_success';

export const DATAUPLOAD_editData = 'HN_DATAUPLOAD_editData';
export const HN_DATAUPLOAD_editData_success = 'HN_DATAUPLOAD_editData_success';

export const DATAUPLOAD_addData = 'HN_DATAUPLOAD_addData';
export const HN_DATAUPLOAD_addData_success = 'HN_DATAUPLOAD_addData_success';

export const DATAUPLOAD_editField = 'HN_DATAUPLOAD_editField';
export const HN_DATAUPLOAD_editField_success = 'HN_DATAUPLOAD_editField_success';

export const GET_DATA_COLLECT_LIST = 'HN_GET_DATA_COLLECT_LIST';
export const SET_DATA_COLLECT_LIST = 'HN_SET_DATA_COLLECT_LIST';
export const GET_CONVERT_RATION = 'HN_GET_CONVERT_RATION';
export const SET_CONVERT_RATION = 'HN_SET_CONVERT_RATION';

export const hnDataUploadSagas = [    
    takeLatest(DATAUPLOAD_importFile, importFile),
    takeLatest(DATAUPLOAD_delData, delData),    
    takeLatest(DATAUPLOAD_getData, getData),
    takeLatest(DATAUPLOAD_editField, editField),
    takeLatest(DATAUPLOAD_editData, editData),
    takeLatest(DATAUPLOAD_addData, addData),
];

export function HNDataUploadReducer(
    state = {
        page: null,
        isShowModel: false,
        isImporting: false,
        isShowEdit: false,
        itemDetailData: {},
        coefficient:'',
        searchKey:'',
        process:''
    },
    action) {
    switch (action.type) {

        case DATAUPLOAD_initPage: {
            return {
                ...state,
                page:action.page,
            }
        }

        case HN_DATAUPLOAD_delData_success: {
            if (action.res && action.res.code == 200) {
                successBox("删除成功");
                if (state.page) {
                    state.page.reloadData();
                }                
            }
            return {
                ...state,
            }
        }

        case HN_DATAUPLOAD_getData_success: {
            if (action.res) {
                return {
                    ...state,
                    itemDetailData: action.res,
                }
                               
            }
            return {
                ...state,
            }
        }  

        case HN_DATAUPLOAD_editData_success: {
            if (action.res && action.res.code == 200) {
                successBox("修改成功");
                if (state.page) {
                    state.page.reloadData();
                }  
                return {
                    ...state,
                    isShowEdit: false,
                }       
            }
            return {
                ...state,
            }
        }

        case HN_DATAUPLOAD_addData_success: {
            if (action.res && action.res.code == 200) {
                successBox("新建成功");
                if (state.page) {
                    state.page.reloadData();
                }  
                return {
                    ...state,
                    isShowEdit: false,
                }       
            }
            return {
                ...state,
            }
        }      
        
        case HN_DATAUPLOAD_editField_success: {
            if (action.res && action.res.code == 200) {
                successBox("修改成功");
                if (state.page) {
                    state.page.reloadData();
                }  
                return {
                    ...state,
                }       
            }
            return {
                ...state,
            }
        }      
        
        case 'HN_DATAUPLOAD_setShowModel': {
            return {
                ...state,
                isShowModel:action.isShowModel,
            }
        }

        case 'HN_DATAUPLOAD_setShowEdit': {
            return {
                ...state,
                isShowEdit:action.isShowEdit,
            }
        }        

        case 'HN_DATAUPLOAD_importFile_success': {
            if (action.res && action.res.code == 200) {
                successBox("导入成功");
                if (state.page) {
                    state.page.reloadData();
                }

                return {
                    ...state,
                    isImporting:false,
                    isShowModel:false,
                }
            }

            return {
                ...state,
                isImporting:false,
            }
        }

        case 'SET_DATA_COLLECT_LIST': {
            return {
                ...state,
                itemDetailData:action.res,
            }
        }

        case 'SET_CONVERT_RATION': {
            return {
                ...state,
                coefficient:action.res,
            }
        }           

        default:
            return state;
    }
}

export function* delData(action) {
    try {
        const service = getService(action.index);
        const id = action.id;

        if (service && service.del) {
            const res = yield call(service.del, id);          
            yield put({ type: HN_DATAUPLOAD_delData_success, res: res });
        } 
    } catch (e) {

    }
}

export function* getData(action) {
    try {
        const service = getService(action.index);
        const id = action.id;

        if (service && service.edit) {
            const res = yield call(service.get, id);          
            yield put({ type: HN_DATAUPLOAD_getData_success, res: res });
        } 
    } catch (e) {

    }
}

export function* editData(action) {
    try {
        const service = getService(action.index);
        const mapParams = action.mapParams;

        if (service && service.edit) {
            const res = yield call(service.edit, mapParams);          
            yield put({ type: HN_DATAUPLOAD_editData_success, res: res });
        } 
    } catch (e) {

    }
}

export function* addData(action) {
    try {
        const service = getService(action.index);
        const mapParams = action.mapParams;

        if (service && service.add) {
            const res = yield call(service.add, mapParams);          
            yield put({ type: HN_DATAUPLOAD_addData_success, res: res });
        } 
    } catch (e) {

    }
}

export function* editField(action) {
    try {
        const service = getService(action.index);
        let {id, fieldName, fieldValue, remarks} = action;
        if (remarks == undefined) {
            remarks = '';
        }

        if (service && service.editField) {
            const res = yield call(service.editField, id, fieldName, fieldValue, remarks);          
            yield put({ type: HN_DATAUPLOAD_editField_success, res: res });
        } 
    } catch (e) {
        
    }
}

export function* importFile(action) {
    try {
        const service = getService(action.index);
        const {upFile, orgId} = action;

        if (service && service.importFile) {
            if(action.index < 19){
                const res = yield call(service.importFile, upFile, orgId);          
                yield put({ type: HN_DATAUPLOAD_importFile_success, res: res });
            }else{
                let mapParams={};
                mapParams.upFile=action.upFile;
                mapParams.orgId=action.orgId;
                mapParams.type=action.fileType;
                const res = yield call(service.add, mapParams);          
                yield put({ type: HN_DATAUPLOAD_importFile_success, res: res });
            }
        } else {
            yield put({ type: HN_DATAUPLOAD_importFile_success, res: null });
        }  
    } catch (e) {

    }
}