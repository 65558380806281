import React from "react";
import { Select } from 'antd';
import { connect } from 'react-redux';
import { SET_SELECTED_FIRM } from '../../system';
const { Option, OptGroup } = Select;

class SDNFirmSelect extends React.Component {

  static defaultProps = {
    width: 'auto',
    onSelectedFirm: null
  }

  company = this.props.company || [];
  
  handleChange = value => {
    const firm = JSON.parse(value);
    if (this.props.onSelectedFirm) {
      this.props.onSelectedFirm(firm);
    }
    this.props.setSelectedFirm(firm);
  }

  renderOptions = () => {
    const options = [];
    this.props.firmList.map((firm, index)=>{
      const firmStr = JSON.stringify(firm);
      options.push(
        <Option value={firmStr} key={index}>
          {firm.orgName}
        </Option>
      );
    });
    return options;
  }

  render() {
    const {
      width,
      firmList
    } = this.props;
    let defaultValue = {};
    if (firmList.length > 0) {
      defaultValue = firmList[0];
    }

    return (
      <Select style={{width: width}} showSearch defaultValue={defaultValue.orgName || ''} onChange={(value) => this.handleChange(value)} >
        { this.renderOptions() }
      </Select>
    );
  }
}

const mapStateToProps = state => {
  return {
    firmList: state.system.firmList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedFirm: firm => dispatch({ type: SET_SELECTED_FIRM, payload: firm })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SDNFirmSelect);
