import {archive, efficiency} from "../../../service";
import {put, select, takeLatest, call, all} from 'redux-saga/effects';
import moment from 'moment';
//用能监控
export const GET_ENERGY_CLASSIFIED_TYPE = 'GET_ENERGY_CLASSIFIED_TYPE';
export const GET_ENERGY_CLASSIFIED_TYPE_SUCCESS = 'GET_ENERGY_CLASSIFIED_TYPE_SUCCESS';
export const GET_ENERGY_GRAPH_DATA = 'GET_ENERGY_GRAPH_DATA';
export const GET_ENERGY_GRAPH_DATA_SUCCESS = 'GET_ENERGY_GRAPH_DATA_SUCCESS';
export const GET_OVERLAY_ENERGY_GRAPH_DATA = 'GET_OVERLAY_ENERGY_GRAPH_DATA';
export const GET_OVERLAY_ENERGY_GRAPH_DATA_SUCCESS = 'GET_OVERLAY_ENERGY_GRAPH_DATA_SUCCESS';
export const REMOVE_ENERGY_LINE = 'REMOVE_ENERGY_LINE';
export const CLEAR_ALL_ENERGY_LINES = 'CLEAR_ALL_ENERGY_LINES';
export const CLEAR_ENERGY_GRAPH_DATA = 'CLEAR_ENERGY_GRAPH_DATA';
export const CLEAR_SELECTED_ENERGY_LINES = 'CLEAR_SELECTED_ENERGY_LINES';
export const GET_ENERGY_OVERLAY_LINE = 'GET_ENERGY_OVERLAY_LINE';

export const REQUEST_DATA = 'REQUEST_ENERGY_DATA';
export const REQUEST_DATA_DONE = 'REQUEST_ENERGY_DATA_DONE';
export const REQUEST_CLASSIFIEDTYPE = 'REQUEST_ENERGY_CLASSIFIEDTYPE';
export const REQUEST_CLASSIFIEDTYPE_DONE = 'REQUEST_ENERGY_CLASSIFIEDTYPE_DONE';
export const SET_FORMAT = 'ENERGY_SET_FORMAT'

export function* getListByOrgIdAndMeasureMode(action) {
    const {
        id,
        mode
    } = action.payload;
    yield put({type: GET_ENERGY_CLASSIFIED_TYPE_SUCCESS, payload: []}); //清空
    yield put({ type: REQUEST_CLASSIFIEDTYPE });
    const ret = yield call(
        archive.DataClassifyService.getListByOrgIdAndMeasureMode,
        id,
        mode
    );
    yield put({ type: REQUEST_CLASSIFIEDTYPE_DONE });
    yield put({type: GET_ENERGY_CLASSIFIED_TYPE_SUCCESS, payload: !ret.code ? ret : []});
}

export function* getEnergyGraphData(action) {
    const {
        orgId,
        dataCode,
        interval,
        beginDate,
        ammeterId,
        ammeterName,
        format,
        endDate,
        title,
        divisor,
        unit
    } = action.payload;
    yield put({ type: REQUEST_DATA });

    // let begin = beginDate.format('YYYY-MM-DD HH:mm:ss');
    // let end = endDate.format('YYYY-MM-DD HH:mm:ss');
    // if (interval === 115) {
    //     begin = beginDate.format('YYYY-MM-DD HH') + ':00:00';
    //     end = endDate.format('YYYY-MM-DD HH:mm:ss');
    // }
    // else if (interval === 201) {
    //     begin = beginDate.format('YYYY-MM-DD HH') + ':00';
    //     end = endDate.format('YYYY-MM-DD HH:mm:ss');
    // }
    // else if (interval === 301) {
    //     begin = beginDate.format('YYYY-MM-DD');
    //     end = endDate.format('YYYY-MM-DD');
    // }
    // else if (interval === 501) {
    //     begin = beginDate.format('YYYY-MM');
    //     end = endDate.format('YYYY-MM');
    // }
    const ret = yield call(
        efficiency.ElecMonitoringService.getAllByOrgId,
        dataCode,
        ammeterId,
        interval,
        beginDate.format(format),
        endDate.format(format),
        1
    );
    yield put({ type: REQUEST_DATA_DONE });

    if (!ret.code) {
        const dataCodes = dataCode.split(',');
        const lineArray = [];
        for (const code of dataCodes) {
            const suffix = '';
            if (code === 'IA') {
                suffix = '_A相电流';
            }
            else if (code === 'IB') {
                suffix = '_B相电流';
            }
            else if (code === 'IC') {
                suffix = '_C相电流';
            }
            lineArray.push({
                orgId: orgId,
                source: title + suffix,
                ammeterId: ammeterId,
                ammeterName: ammeterName,
                type: ammeterName,
                beginDate: beginDate,
                endDate: endDate,
                interval: interval,
                format: format,
                dateRange: beginDate.format(format) + '至' + endDate.format(format),
                canBeOperated: true,
                date: [],
                data: [],
                dataCode: code,
                divisor: divisor,
                unit: unit
            });
        }

        for (const line of lineArray) {
            const { data, dataCode } = line;
            for (const item of ret) {
                const date = Number.parseInt(moment(item.dt).format('x'));
                if (item.hasOwnProperty(dataCode)) {
                    data.push([date, item[dataCode]]);
                }
                else {
                    data.push([date,  '-']);
                }
            }
        }
        yield put({
            type: GET_ENERGY_GRAPH_DATA_SUCCESS,
            payload: lineArray
        });
    }
}

export function* getOverlayEnergyGraphData (action) {
    const {
        orgId,
        dataCodes,
        type,
        ammeterIds,
        ammeterName,
        interval,
        beginDate,
        endDate,
        source,
        format,
        lineCount,
        unit
    } = action.payload;

    yield put({ type: SET_FORMAT, payload: format });
    yield put({ type: REQUEST_DATA });
    const ret = yield call(
        efficiency.ElecMonitoringService.operation,
        dataCodes,
        type,
        ammeterIds,
        interval,
        beginDate.format(format),
        endDate.format('YYYY-MM-DD HH:mm:ss'),
        1
    );
    yield put({ type: REQUEST_DATA_DONE });

    if (!ret.code) {
        let line = {
            orgId: orgId,
            source: source,
            dataCode: dataCodes,
            ammeterId: ammeterIds,
            ammeterName: ammeterName,
            type: type === 1 ? '叠加' : '平均',
            beginDate: beginDate,
            endDate: endDate,
            interval: interval,
            format: format,
            dateRange: beginDate.format(format) + '至' + endDate.format(format),
            canBeOperated: false,
            date: [],
            data: [],
            unit,
            lineCount: lineCount
        };

        const { data } = line;
        for (const item of ret) {
            const date = Number.parseInt(moment(item.dt).format('x'));
            if (item.hasOwnProperty('val')) {
                data.push([date, item['val'] || '-']);
            }
            else {
                data.push([date, null]);
            }
        }

        yield put({
            type: GET_ENERGY_GRAPH_DATA_SUCCESS,
            payload: [line]
        });
    }
}

export const energySaga = [
    takeLatest(GET_ENERGY_CLASSIFIED_TYPE, getListByOrgIdAndMeasureMode),
    takeLatest(GET_ENERGY_GRAPH_DATA, getEnergyGraphData),
    takeLatest(GET_OVERLAY_ENERGY_GRAPH_DATA, getOverlayEnergyGraphData),
];

export function SDNEnergyReducer (
    state = {
        //用能监控
        loadingData: false,
        loadingEnergyClassifiedType: false,
        getGraphDataCount: 0, //向后台获取接口次数, 用来标记曲线唯一标识符
        energyClassifiedType: [],
        energyData: [],
        format: 'YYYY-MM'
    },
    action
) {
    switch (action.type) {
        case REMOVE_ENERGY_LINE: {
            const { energyData } = state;
            const index = energyData.findIndex(item => item.index === action.payload);
            energyData.splice(index, 1);
            //
            return {
                ...state,
                energyData: energyData
            }
        }

        case GET_ENERGY_CLASSIFIED_TYPE_SUCCESS: {
            return {
              ...state,
              energyClassifiedType: action.payload
            }
        }

        case CLEAR_SELECTED_ENERGY_LINES: {
            const { energyData } = state;
            for (const item of action.payload) {
                energyData.splice(energyData.findIndex(energyItem => energyItem.index === item.index), 1);
            }
            return {
                ...state,
                energyData: energyData
            }
        }

        case CLEAR_ALL_ENERGY_LINES: {
            return {
                ...state,
                energyData: []
            }
        }

        case GET_ENERGY_GRAPH_DATA_SUCCESS: {
            let { energyData } = state;
            energyData = energyData.concat(action.payload);
            for (let index = 0; index < energyData.length; index++) {
                const element = energyData[index];
                element.index = index + 1;
            }
            //
            return {
                ...state,
                loadingData: false,
                energyData: energyData
            };
        }

        case REQUEST_DATA: {
            return {
                ...state,
                loadingData: true
            };
        }

        case REQUEST_DATA_DONE: {
            return {
                ...state,
                loadingData: false
            };
        }

        case REQUEST_CLASSIFIEDTYPE: {
            return {
                ...state,
                loadingEnergyClassifiedType: true
            };
        }

        case REQUEST_CLASSIFIEDTYPE_DONE: {

            return {
                ...state,
                loadingEnergyClassifiedType: false
            };
        }

        case SET_FORMAT: {
            return { ...state, format: action.payload };
        }

        default:
            return state;
    }
}
