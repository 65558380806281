import React from "react";

import { Layout } from 'antd';

const { Footer } = Layout;

export default class SDNFooter extends React.Component {
  render() {
    return (
      <Footer 
        style={{ 
          color:'#FFF', 
          fontSize: 14, 
          textAlign: 'center',
          background: '#001529' 
        }}
      >
        Copyright ©{(new Date()).getFullYear()} 版权所有 不得转载 <a href="https://beian.miit.gov.cn" target="_Blank" style={{color:'white'}}>粤ICP备16015391号</a>
      </Footer>
    );
  }
}
