import React from 'react';

export class MultipleListSeclectView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            selectedItem: null,
            itemdatas: []
        }
    }

    componentDidMount() {
        this.getItemdatas(null);
    }

    getItemdatas = (selectedItem) => {
        let tempArray = [];
        for (let index in this.props.items) {
            let item = this.props.items[index];
            if (selectedItem) {
                if (item.parentIndustry && item.parentIndustry.industryId == selectedItem.industryId) {
                    tempArray.push(item);
                }
            } else {
                if (!item.parentIndustry) {
                    tempArray.push(item);
                }
            }            
        }

        let itemdatas = this.state.itemdatas;

        while (itemdatas.length > this.state.selectedTab + 1) {
            itemdatas.pop();
        }

        itemdatas.push(tempArray);

        this.setState({
            selectedItem: selectedItem,
            itemdatas: itemdatas
        });
    };

    handleItemOnClick = selectedItem => { 
        let hasSub = false;
        for (let index in this.props.items) {
            let item = this.props.items[index];
            if (item.parentIndustry && item.parentIndustry.industryId == selectedItem.industryId) {
                hasSub = true;
                break;
            }
        }
        
        if (hasSub && this.state.selectedTab < this.props.tab.length - 1) {
            this.getItemdatas(selectedItem);
            this.setState({
                selectedTab: this.state.selectedTab + 1
            });
        } else {
            this.props.handleMultipleListSelectItem(selectedItem);
        }        
    };

    handleTabOnClick = selectedIndex => {       
        this.setState({
            selectedTab: selectedIndex
        });
    };

    render() {
        let tab = this.props.tab;
        let selectedTab = this.state.selectedTab;

        let itemdatas = [];
        if (selectedTab < this.state.itemdatas.length) {
            itemdatas = this.state.itemdatas[selectedTab];
        }

        let tabData = tab.map((item, index) => {
            let active = selectedTab == index ? "active": "";
            return <a key={item} href="javascript:;" className={active} onClick={() => this.handleTabOnClick(index)}>{item}</a>;
        });

        let itemData = itemdatas.map((item, i) => {      
            let active = "";
            let selectedItem = this.state.selectedItem;
            while (selectedItem) {
                if (item.industryId == selectedItem.industryId) {
                    active = "active";
                    break;
                }
                selectedItem = selectedItem.parentIndustry;
            }

            return <li key={item.industryCode+i}><a href="javascript:;" className={active} title={item.industryName} data-code={item.industryCode} onClick={() => this.handleItemOnClick(item)}>{item.industryName}</a></li>;
        });

        return (
            <div className="multipleChoice" style={{ height: "250px"}}>
                <div className="itemTab" >
                    {tabData}
                </div>
                <div className="itemList" style={{width: "100%" }}>
                    <div className="item item-1" style={{width: "100%"}}>
                        <ul className="mCustomScrollbar _mCS_1 mCS-autoHide" style={{ overflow: "auto"}}>
                        {itemData}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

}