import React, { Component } from "react";
import SDNDatePicker from "../../ui/components/SDNDatePicker";
import SDNQuestionPopover from "../../ui/components/SDNQuestionPopover";
import { Input, Select, DatePicker } from "antd";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

export default class TableTitles extends Component {
  constructor(props) {
    super(props);
    this.state = { inputValue: {}, selectedValue: {}, startDate: {}, endDate: {} };
  }

  reset = () => this.setState({ inputValue: {}, selectedValue: {}, startDate: {}, endDate: {} });

  handleInput = (e, index) => {
    if (this.props.onSearch) {
      this.props.onSearch(index, e.target.value);
    }
  };

  handleStartDateChange = (title, date, index) => {
    if (title.onStartDateChange) title.onStartDateChange(date);
    const startDate = { ...this.state.startDate };
    startDate[index] = date;
    this.setState({ startDate });
    const { endDate } = this.state;
    if (this.props.onSearch) {
      if (date && endDate[index]) {
        const value = [moment(date).format("YYYY-MM-DD 00:00:00"), "-TIME-", moment(endDate[index]).format("YYYY-MM-DD 23:59:59")].join("");
        this.props.onSearch(index, value);
      }
      if (!date && !endDate[index]) this.props.onSearch(index, "");
    }
  };

  handleEndDateChange = (title, date, index) => {
    if (title.onEndDateChange) title.onEndDateChange(date);
    const endDate = { ...this.state.endDate };
    endDate[index] = date;
    this.setState({ endDate });
    const { startDate } = this.state;
    if (this.props.onSearch) {
      if (date && startDate[index]) {
        const value = [moment(startDate[index]).format("YYYY-MM-DD 00:00:00"), "-TIME-", moment(date).format("YYYY-MM-DD 23:59:59")].join("");
        this.props.onSearch(index, value);
      }
      if (!date && !startDate[index]) this.props.onSearch(index, "");
    }
  };

  getControl = (item, index) => {
    let html = null;
    switch (item.type) {
      case "select":
        html = (
          <div className="select">
            <select className="form-control" onChange={e => this.handleInput(e, item.targets)}>
              {item.options.map((option, idx) => (
                <option key={idx} value={option.value} defaultValue="" children={option.text} />
              ))}
            </select>
            <i />
          </div>
        );
        break;
      case "date-range":
        html = (
          <div className="row" style={{ margin: 0 }}>
            <div className="col-sm-6">
              <SDNDatePicker
                value={this.state.startDate[index]}
                defaultDate={null}
                dateFormat="yyyy-MM-dd"
                placeholderText="From"
                maxDate={new Date()}
                onChange={date => this.handleStartDateChange(item, date, index)}
              />
            </div>
            <div className="col-sm-6">
              <SDNDatePicker
                className="dateselect-filter-from form-control datepicker"
                defaultDate={null}
                value={this.state.endDate[index]}
                dateFormat="yyyy-MM-dd"
                placeholderText="To"
                maxDate={new Date()}
                onChange={date => this.handleEndDateChange(item, date, index)}
              />
            </div>
          </div>
        );
        break;
      case "range":
        html = (
          <div className="input">
            <input type="text" className="form-control" placeholder="选择范围" onChange={e => this.handleInput(e, item.targets)} />
          </div>
        );
        break;
      default:
        html = (
          <div className="input">
            <input type="text" className="form-control" placeholder={"请输入" + item.label} onChange={e => this.handleInput(e, item.targets)} />
          </div>
        );
        break;
    }
    const width = `${100 / this.props.controlscols}%`;
    return (<div key={index} style={{ width, padding: "10px 5px" }} children={html} />);
  };

  renderCtrls = () => {
    const { controls, titles } = this.props;
    if (!Array.isArray(controls) || !controls[0]) return null;
    const ctrlsHtml = <div className="smart-form" style={{display: "flex"}}>{controls.map(this.getControl)}</div>;
    return (<tr><th colSpan={titles.length} style={{ padding: "0 9px" }} children={ctrlsHtml} /></tr>);
  };

  onInputChange = ({ target: { value } }, index) => {
    const inputValue = { ...this.state.inputValue };
    inputValue[index] = value;
    this.setState({ inputValue });

    if (!this.props.onSearch) return;
    this.props.onSearch(index, value || value === 0 ? value : "");
  };

  onSelectChange = (value, index) => {
    const selectedValue = { ...this.state.selectedValue };
    selectedValue[index] = value;
    this.setState({ selectedValue });

    if (!this.props.onSearch) return;
    this.props.onSearch(index, value || value === 0 ? value : "");
  };

  onRangeChange = (dates, index) => {
    const [beginDt, endDt] = dates;
    const startDate = { ...this.state.startDate };
    const endDate = { ...this.state.endDate };
    startDate[index] = beginDt;
    endDate[index] = endDt;
    this.setState({ startDate, endDate });

    if (!this.props.onSearch) return;

    if (beginDt && endDt) {
      const value = [moment(beginDt).format("YYYY-MM-DD 00:00:00"), "-TIME-", moment(endDt).format("YYYY-MM-DD 23:59:59")].join("");
      this.props.onSearch(index, value);
    }
    if (!beginDt && !endDt) this.props.onSearch(index, "");
  };

  getFilter = ({ type, options }, index) => {
    switch (type) {
      default:
      case "text":
        return (
          <Input type="text" allowClear={true} placeholder="请输入" value={this.state.inputValue[index]} onChange={value => this.onInputChange(value, index)} />
        );
      case "select":
        return (
          <Select
            allowClear={true}
            placeholder="请选择"
            value={this.state.selectedValue[index]}
            style={{ display: "block", fontWeight: "normal" }}
            onChange={value => this.onSelectChange(value, index)}
          >
            {options.map(({ title, value }, i) => (
              <Select.Option {...{ key: i, value, title, children: title }} />
            ))}
          </Select>
        );
      case "date-range":
        return (
          <DatePicker.RangePicker
            allowClear={true}
            suffixIcon={<></>}
            format="YYYY-MM-DD"
            mode={["date", "date"]}
            style={{ width: "100%" }}
            onChange={dates => this.onRangeChange(dates, index)}
            value={[this.state.startDate[index], this.state.endDate[index]]}
          />
        );
    }
  };

  renderFilter = () => {
    const { showFilter, titles } = this.props;
    return showFilter ? (
      <tr>
        {titles.map((title, index) => (
          <th className="hasinput" key={index} children={title.show === false ? null : this.getFilter(title, index)} />
        ))}
      </tr>
    ) : null;
  };

  renderTitles = () => {
    const { titles } = this.props;
    if (Array.isArray(titles[0])) {
      return titles.map((trs, i) => (
        <tr style={{ background: "none" }} key={i}>
          {trs.map((col, j) => {
            const { name = "", style = {}, ref = null, popoverContent: text = "", rowSpan = 1, colSpan = 1 } = col;
            const popover = text && text.length > 0 ? <SDNQuestionPopover content={text} /> : null;
            return (
              <th key={j} ref={ref} rowSpan={rowSpan} colSpan={colSpan} className={col.className} style={{ textAlign: "center", ...style }}>
                {name} {popover}
              </th>
            );
          })}
        </tr>
      ));
    }
    return (
      <tr>
        {titles.map((col, i) => {
          const { name = "", style = {}, popoverContent: text = "" } = col;
          const popover = text && text.length > 0 ? <SDNQuestionPopover content={text} /> : null;
          return (
            <th key={i} className={col.className} style={{ textAlign: "center", ...style }}>
              {name} {popover}
            </th>
          );
        })}
      </tr>
    );
  };

  render() {
    return (
      <>
        <thead ref="dtHeader">
          {this.renderCtrls()}
          {this.renderFilter()}
          {this.renderTitles()}
        </thead>
      </>
    );
  }
}
