import { put, takeLatest, call, } from 'redux-saga/effects';
import { Utils } from '../../../../common/utils';
import sdata from "../../../../service/API/sdata";
import { archive, eprice } from "../../../../service";

export const GET_SITE_LIST = 'GET_SITE_LIST';
export const GET_SITE_LIST_SUCCESS = 'GET_SITE_LIST_SUCCESS';

export const GET_EMS_DATA = 'GET_EMS_DATA';
export const GET_EMS_DATA_SUCCESS = 'GET_EMS_DATA_SUCCESS';

export const QUERY_PLANCURVE = 'QUERY_PLANCURVE';
export const QUERY_PLANCURVE_SUCCESS = 'QUERY_PLANCURVE_SUCCESS';

export const PLAN_CURVE_MONITORED = 'PLAN_CURVE_MONITORED';
export const PLAN_CURVE_MONITORED_SUCCESS = 'PLAN_CURVE_MONITORED_SUCCESS';

export const GET_REVENUE_COST = 'GET_REVENUE_COST';
export const GET_REVENUE_COST_SUCCESS = 'GET_REVENUE_COST_SUCCESS';

export const GET_REVENUE_COST_CHART = 'GET_REVENUE_COST_CHART';
export const GET_REVENUE_COST_CHART_SUCCESS = 'GET_REVENUE_COST_CHART_SUCCESS';

export const GET_REVENUE_COST_EVENT = 'GET_REVENUE_COST_EVENT';
export const GET_REVENUE_COST_EVENT_SUCCESS = 'GET_REVENUE_COST_EVENT_SUCCESS';

export const queryCurveSagas = [
    takeLatest(GET_SITE_LIST, getSiteList),
    takeLatest(GET_EMS_DATA, getSysData),
    takeLatest(QUERY_PLANCURVE, queryPlanCurve),
    takeLatest(PLAN_CURVE_MONITORED, planCurveMonitored),
    takeLatest(GET_REVENUE_COST_CHART, getRevenueCostChart),
    takeLatest(GET_REVENUE_COST_EVENT, getRevenueCostEvent),
    takeLatest(GET_REVENUE_COST, getRevenueCost),
];

export function QueryCurveReducer(
    state = {
        siteList: [],
        sysList: [],
        chartDatas: [],
        mergeChartData: [],
        unionChartData: [],
        pieChartData: [],
        recordChartData: [],
        xAxisData: [],
        revenueCostType: {},
        groupFirmTree: [], //管理员集团公司列表
        selectedFirm: {}, //当前选中的公司
        costCharData: {}
    },
    action) {
    switch (action.type) {

        case GET_SITE_LIST_SUCCESS: {
            if (action.res) {
                let array = [...action.res];
                let setArray = array.map(({ siteId, siteName }) => ({ value: siteId, text: siteName }));
                return {
                    ...state,
                    siteList: setArray,
                }

            }
            return {
                ...state,
            }
        }

        case GET_EMS_DATA_SUCCESS: {
            if (action.res) {
                let array = [...action.res];
                let setArray = array.map(({ ammeterId, sysName }) => ({ value: ammeterId, text: sysName }));
                return {
                    ...state,
                    sysList: setArray,
                }

            }
            return {
                ...state,
            }
        }

        case QUERY_PLANCURVE_SUCCESS: {
            if (action.res) {
                const { xAxis, values } = action.res;
                const chartDatas = [];
                const mergeChartData = [];
                const chartTitle = values[0].consumerName + '-' + values[0].siteName + '的策略曲线';
                values.forEach(({ name, ammeterId, sendCurve }) => {
                    const data = sendCurve.datas.map((item, i) => [xAxis.datas[i], item]);
                    const lineData = { name, data, symbol: "none" };
                    if (ammeterId === "merge") mergeChartData.push(lineData);
                    else chartDatas.push(lineData);
                });
                return {
                    ...state,
                    chartDatas,
                    mergeChartData,
                    chartTitle
                }
            }
            return {
                ...state,
            }
        }

        case PLAN_CURVE_MONITORED_SUCCESS: {
            if (action.res) {
                const { xAxis, values } = action.res;
                const unionChartData = [];
                const chartTitle = values[0].consumerName + '-' + values[0].siteName + '的策略曲线';
                values.forEach(({ sendCurve, runCurve, SPZ, SOC }) => {
                    const data1 = sendCurve.datas.map((item, i) => [xAxis.datas[i], item]);
                    const data2 = runCurve.datas.map((item, i) => [xAxis.datas[i], item]);
                    const data3 = SPZ.datas.map((item, i) => [xAxis.datas[i], item]);
                    const data4 = SOC.datas.map((item, i) => [xAxis.datas[i], item]);
                    const sendCurveData = { name: '充放电计划（下发策略）', data: data1, symbol: "none" };
                    const runCurveData = { name: '充放电策略（EMS上报策略）', data: data2, symbol: "none" };
                    const SPZData = { name: '实际充放电功率（总有功功率）', data: data3, symbol: "none" };
                    const SOCData = { name: 'SOC', data: data4, symbol: "none" };
                    unionChartData.push(sendCurveData, runCurveData, SPZData, SOCData);
                });
                return {
                    ...state,
                    unionChartData,
                    chartTitle
                }
            }
            return {
                ...state,
            }
        }

        case GET_REVENUE_COST_SUCCESS: {
            if (action.res) {
                return {
                    ...state,
                    revenueCostType: action.res,
                }
            }
            return {
                ...state,
            }
        }

        case GET_REVENUE_COST_CHART_SUCCESS: {
            if (action.res) {
                const costCharData = action.res;
                return {
                    ...state,
                    costCharData
                }
            }
            return {
                ...state,
            }
        }

        case GET_REVENUE_COST_EVENT_SUCCESS: {
            if (action.res) {
                const list = action.res;
                const recordChartData = [];
                const data1 = list.map((item, i) => item.amountValue);
                const data2 = list.map((item, i) => item.netIncome);
                const xAxisData = list.map((item, i) => item.eventTime + item.eventName);
                const amountData = { name: null, data: data1, type: 'bar', barWidth: '40', symbol: "none" };
                const netIncomeData = { name: null, data: data2 };
                recordChartData.push(amountData, netIncomeData);
                return {
                    ...state,
                    recordChartData,
                    xAxisData
                }
            }
            return {
                ...state,
            }
        }

        default:
            return state;
    }
}

export function* getSiteList(action) {
    try {
        const service = archive.MonitorSiteService.getByConsumerId
        const consumerId = action.payload;

        if (service) {
            const res = yield call(service, consumerId);
            yield put({ type: GET_SITE_LIST_SUCCESS, res: res });
        }
    } catch (e) {

    }
}

export function* getSysData(action) {
    try {
        const service = sdata.PlanCurveReportService.getEmsBySiteIds
        const siteIds = action.payload;

        if (service) {
            const res = yield call(service, siteIds);
            yield put({ type: GET_EMS_DATA_SUCCESS, res: res });
        }
    } catch (e) {

    }
}

export function* queryPlanCurve(action) {
    try {
        const service = sdata.PlanCurveMonitorQryService.queryPlanCurve
        const { ammeterIds, interval, fromDt, toDt } = action.payload;
        const res = yield call(service, ammeterIds, interval, fromDt, toDt);
        yield put({ type: QUERY_PLANCURVE_SUCCESS, res: res });
    } catch (e) {

    }
}

export function* planCurveMonitored(action) {
    try {
        const service = sdata.PlanCurveMonitorQryService.planCurveMonitored
        const { ammeterId, interval, fromDt, toDt } = action.payload;
        const res = yield call(service, ammeterId, interval, fromDt, toDt);
        yield put({ type: PLAN_CURVE_MONITORED_SUCCESS, res: res });
    } catch (e) {

    }
}

export function* getRevenueCost() {
    try {
        const service = eprice.RevenueCostService.getRevenueCostType
        const res = yield call(service);
        yield put({ type: GET_REVENUE_COST_SUCCESS, res: res });
    } catch (e) {

    }
}

export function* getRevenueCostChart(action) {
    try {
        const service = eprice.RevenueCostService.getRevenueCostCharData
        const { siteId, fromDt, toDt } = action.payload;
        const res = yield call(service, siteId, fromDt, toDt);
        yield put({ type: GET_REVENUE_COST_CHART_SUCCESS, res: res });
    } catch (e) {

    }
}

export function* getRevenueCostEvent(action) {
    try {
        const service = eprice.RevenueCostService.getRevenueCostEventCharData
        const { siteId, fromDt, toDt } = action.payload;
        const res = yield call(service, siteId, fromDt, toDt);
        yield put({ type: GET_REVENUE_COST_EVENT_SUCCESS, res: res });
    } catch (e) {

    }
}