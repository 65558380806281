import React from 'react';
import { DatePicker, Button, message } from 'antd';
import Flex, { FlexItem } from 'styled-flex-component';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
const { RangePicker} = DatePicker;

export const dateRangePickerType = { min15: 0, hour: 1, day: 2, month: 3, year: 4 };

export default class SDNDateRangePicker extends React.Component {

  date = moment();
  dateString = moment().format('YYYY-MM-DD');

  static defaultProps = {
    dateType: dateRangePickerType.hour,
  }

  constructor (props) {
    super(props);
    this.state = {
      isOpen: false,
      value: [this.props.begin || moment().subtract(1, 'hours'), this.props.end || moment()],
      mode: ['time', 'time'],
      dateType: props.dateType
    };
  }

  componentDidMount () {
    if (this.props.onChange) {
      this.props.onChange(this.state.value, '');
    }
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.begin != nextProps.begin || this.props.end != nextProps.end) {
      this.setState({value: [nextProps.begin, nextProps.end]});
    }
    if (this.props.dateType != nextProps.dateType) {
      this.setState({dateType: nextProps.dateType});
    }
  }

  handleTimeChange = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    this.setState({ value });
  }

  getRange = (dates, range) => {
    const [beginDt, endDt] = dates;
    let newEndDt = endDt, isOpen = false;
    const maxEndDt = this.getMaxEndDt(beginDt, range);
    if (beginDt.isAfter(endDt)) {
      isOpen = true;
      newEndDt = beginDt.endOf('day');
      message.info("结束时间不能超过"+newEndDt.format('YYYY-MM-DD HH:mm:ss'));
    }
    else if (maxEndDt.isBefore(endDt)) {
      isOpen = true;
      newEndDt = maxEndDt;
      message.info("结束时间不能超过"+newEndDt.format('YYYY-MM-DD HH:mm:ss'));
    }
    const value = [beginDt, newEndDt];
    return {value, isOpen}
  }

  handleChange = (dates, range, dateType) => {
    const {value, isOpen} = this.getRange(dates, range)
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    let newState = { value };
    if (dateType === dateRangePickerType.day) newState = { value, isOpen };
    this.setState(newState);
  }

  handlePanelChange = (dates, mode, range) => {
    const [m0, m1] = mode;
    if(m0==='date' && m1==='month') return;
    const {value, isOpen} = this.getRange(dates, range)
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    this.setState({ value, isOpen });
  };

  handleOpenChange = (status) => {
    if (status) {
      this.setState({ isOpen: true })
    } else {
      this.setState({ isOpen: false })
    }
  }

  getMaxEndDt = (start, range) => {
    const { base, maxLength } = range;
    const mom = moment().endOf('day');
    let max = moment(start)
      .add(maxLength, base)
      .endOf('day');
    if (max > mom) return mom;
    return max;
  };

  render () {
    let { value, isOpen, dateType } = this.state;
    const [val0, val1] = value;
    let props = {
      value,
      locale,
      open: isOpen,
      onOk: this.handleTimeChange,
      onOpenChange: this.handleOpenChange,
      disabledDate: cellDt => cellDt > moment().endOf('day')
    }
    let range = { base: 'days', maxLength: 3 };
    if (dateType === dateRangePickerType.year) {
      props.format = 'YYYY';
      props.mode = ['year','year'];
      range = { base: 'years', maxLength: 30, ...this.props.range };
    }
    else if (dateType === dateRangePickerType.month) {
      props.format = 'YYYY-MM';
      props.mode = ['month','month'];
      range = { base: 'years', maxLength: 30, ...this.props.range };
    }
    else if (dateType === dateRangePickerType.day) {
      props.format = 'YYYY-MM-DD';
      props.mode = ['date','date'];
      range = { base: 'years', maxLength: 1, ...this.props.range };
    }
    else if (dateType === dateRangePickerType.hour) {
      props.format = 'YYYY-MM-DD HH:mm';
      props.showTime = { format: 'HH' };
      range = { base: 'months', maxLength: 1, ...this.props.range };
      props.value = [val0.minute(0).seconds(0), val1.minute(0).seconds(0)];
    }
    else {
      props.format = 'YYYY-MM-DD HH:mm';
      props.showTime = { format: 'HH:mm', minuteStep: 15 };
      props.value = [val0.seconds(0), val1.seconds(0)];
    }
    props.onChange = dates => this.handleChange(dates, range, dateType);
    props.onPanelChange = (dates, mode) => this.handlePanelChange(dates, mode, range);
    return (
      <RangePicker { ...props } />
    )
  }
}
