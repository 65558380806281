// 共用
export const INIT_PAGE = 'INIT_PAGE';

// 数据项配置
export const GET_ROLE_DETAIL = 'GET_ROLE_DETAIL';
export const CONFIG_SELECTED_FUN = 'CONFIG_SELECTED_FUN';
export const EDIT_ROLE = 'EDIT_ROLE';
export const SUBMITE_EDIT_ROLE_SUCCESS = 'SUBMITE_EDIT_ROLE_SUCCESS';
export const SUBMITE_EDIT_ROLE_DEFEATE = 'SUBMITE_EDIT_ROLE_DEFEATE';
export const SUBMITE_ADD_ROLE_SUCCESS = 'SUBMITE_ADD_ROLE_SUCCESS';
export const REMOVE_DATAITEM_ROLE = 'REMOVE_DATAITEM_ROLE';
export const DATAITEM_ROLE_REMOVED = 'DATAITEM_ROLE_REMOVED';
export const GET_ROLE_WITH_FUN = 'GET_ROLE_WITH_FUN';
export const SET_ROLE_WITH_FUN = 'SET_ROLE_WITH_FUN';
export const SUBMITE_EDIT_DATAITEM_ROLE = 'SUBMITE_EDIT_DATAITEM_ROLE';
export const SUBMITE_ADD_DATAITEM_ROLE = 'SUBMITE_ADD_DATAITEM_ROLE';
export const GET_ITEM_CATEGORYS = 'GET_ITEM_CATEGORYS';
export const GET_ITEM_CATEGORYS_SUCCESS = 'GET_ITEM_CATEGORYS_SUCCESS';

// 设备类型
export const REMOVE_DEVICETYPE_ROLE = 'REMOVE_DEVICETYPE_ROLE';
export const EDIT_DEVICETYPE_ROLE = 'EDIT_DEVICETYPE_ROLE';
export const CLEAR_DEVICETYPE_ROLE = 'CLEAR_DEVICETYPE_ROLE';
export const GET_DEVICETYPE_ROLE_WITH_FUN = 'GET_DEVICETYPE_ROLE_WITH_FUN';
export const SET_DEVICETYPE_ROLE_WITH_FUN = 'SET_DEVICETYPE_ROLE_WITH_FUN';
export const SUBMITE_EDIT_DEVICETYPE_ROLE = 'SUBMITE_EDIT_DEVICETYPE_ROLE';
export const SUBMITE_NEW_DEVICETYPE_ROLE = 'SUBMITE_NEW_DEVICETYPE_ROLE';
export const SET_SELECT_MENU = 'SET_SELECT_MENU';
export const GET_SELECT_MENU = 'GET_SELECT_MENU';

// 管理单元类型
export const REMOVE_UNITTYPE_ROLE = 'REMOVE_UNITTYPE_ROLE';
export const SUBMITE_EDIT_UNITTYPE_ROLE = 'SUBMITE_EDIT_UNITTYPE_ROLE';
export const SUBMITE_NEW_UNITTYPE_ROLE = 'SUBMITE_NEW_UNITTYPE_ROLE';
export const SUBMITE_NEW_UNITTYPE_ROLE_BACK = 'SUBMITE_NEW_UNITTYPE_ROLE_BACK';
export const GET_UNITTYPE_ROLE_WITH_FUN = 'GET_UNITTYPE_ROLE_WITH_FUN';
export const SET_UNITTYPE_ROLE_WITH_FUN = 'SET_UNITTYPE_ROLE_WITH_FUN';

//监控对象类型
export const REMOVE_MONITOROBJECT_ROLE = 'REMOVE_MONITOROBJECT_ROLE';
export const SUBMITE_EDIT_MONITOROBJECT_ROLE = 'SUBMITE_EDIT_MONITOROBJECT_ROLE';
export const SUBMITE_NEW_MONITOROBJECT_ROLE = 'SUBMITE_NEW_MONITOROBJECT_ROLE';
export const GET_MONITOROBJECT_ROLE_WITH_FUN = 'GET_MONITOROBJECT_ROLE_WITH_FUN';

//监控对象类型扩展信息
export const DATAITEMCONFIG_GET_MAIN_TITLE_INFO = 'DATAITEMCONFIG_GET_MAIN_TITLE_INFO';
export const DATAITEMCONFIG_GET_DATA_ARRAY = 'DATAITEMCONFIG_GET_DATA_ARRAY';
export const DATAITEMCONFIG_GET_DATA_ARRAY_BACK = 'DATAITEMCONFIG_GET_DATA_ARRAY_BACK';
export const DATAITEMCONFIG_GET_MAIN_TITLE_INFO_BACK = 'DATAITEMCONFIG_GET_MAIN_TITLE_INFO_BACK';
export const GET_DATAITEMCONFIG_ROLE_WITH_FUN = 'GET_DATAITEMCONFIG_ROLE_WITH_FUN';
export const SUBMITE_EDIT_DATAITEMCONFIG_ROLE = 'SUBMITE_EDIT_DATAITEMCONFIG_ROLE';
export const SUBMITE_NEW_DATAITEMCONFIG_ROLE = 'SUBMITE_NEW_DATAITEMCONFIG_ROLE';
export const DATAITEMCONFIG_GET_DATE_ZONE_LIST = 'DATAITEMCONFIG_GET_DATE_ZONE_LIST';
export const DATAITEMCONFIG_GET_DATE_ZONE_LIST_BACK = 'DATAITEMCONFIG_GET_DATE_ZONE_LIST_BACK';
export const DATAITEMCONFIG_ROLE_REMOVED = 'DATAITEMCONFIG_ROLE_REMOVED';
export const REMOVE_DATAITEMCONFIG_ROLE = 'REMOVE_DATAITEMCONFIG_ROLE';

export const GET_MONI_OB_EXTRA_ROLE_WITH_FUN = 'GET_MONI_OB_EXTRA_ROLE_WITH_FUN';
export const GET_MONI_OB_EXTRA_ROLE_WITH_FUN_BACK = 'GET_MONI_OB_EXTRA_ROLE_WITH_FUN_BACK';
export const SUBMITE_EDIT_MONI_OB_EXTRA_ROLE = 'SUBMITE_EDIT_MONI_OB_EXTRA_ROLE';
export const SUBMITE_NEW_MONI_OB_EXTRA_ROLE = 'SUBMITE_NEW_MONI_OB_EXTRA_ROLE';
export const REMOVE_MONI_OB_EXTRA_ROLE = 'REMOVE_MONI_OB_EXTRA_ROLE';
export const EDIT_MONI_OB_CONFIG_ROLE = 'EDIT_MONI_OB_CONFIG_ROLE';
export const REMOVE_MONI_OB_CONFIG_ROLE = 'REMOVE_MONI_OB_CONFIG_ROLE';
export const SUBMITE_ADD_MONI_OB_ITEM = 'SUBMITE_ADD_MONI_OB_ITEM';
export const GET_MONI_OB_ALL_GRAPH_ENTITY = 'GET_MONI_OB_ALL_GRAPH_ENTITY';
export const GET_MONI_OB_ALL_GRAPH_ENTITY_BACK = 'GET_MONI_OB_ALL_GRAPH_ENTITY_BACK';
export const GET_MONI_OB_SETTING_ITEM_DICTIONARY = 'GET_MONI_OB_SETTING_ITEM_DICTIONARY';
export const GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK = 'GET_MONI_OB_SETTING_ITEM_DICTIONARY_BACK';
export const REMOVE_MONI_OB_GRAPH_ENTITY_ROLE = 'REMOVE_MONI_OB_GRAPH_ENTITY_ROLE';
export const EDIT_MONI_OB_CONFIG_GRAPHENTITY_ROLE = 'EDIT_MONI_OB_CONFIG_GRAPHENTITY_ROLE';
export const GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST = 'GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST';
export const GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST_BACK = 'GET_MONI_OB_GRAPH_ENTITY_EXTRA_LIST_BACK';
export const GET_ALL_INFO_LIST = 'GET_ALL_INFO_LIST';
export const DO_REFLASH_TABLE = 'DO_REFLASH_TABLE';
export const SUBMITE_ADD_GRAPH_ENTITY_OB_ITEM = 'SUBMITE_ADD_GRAPH_ENTITY_OB_ITEM';
export const ADD_MONI_OB_GRAPH_ATTR = 'ADD_MONI_OB_GRAPH_ATTR';
export const SUBMITE_NEW_GRAPH_ENTITY_ROLE = 'SUBMITE_NEW_GRAPH_ENTITY_ROLE';
export const SUBMITE_EDIT_GRAPH_ENTITY_ROLE = 'SUBMITE_EDIT_GRAPH_ENTITY_ROLE';
export const REMOVE_EDIT_GRAPH_ROLE = 'REMOVE_EDIT_GRAPH_ROLE';

export const SUBMITE_NEW_GRAPH_ENTITY_ROLE_BACK = 'SUBMITE_NEW_GRAPH_ENTITY_ROLE_BACK';
export const SUBMITE_EDIT_GRAPH_ENTITY_ROLE_BACK = 'SUBMITE_EDIT_GRAPH_ENTITY_ROLE_BACK';




