import { efficiency } from "../../../service";
import { datePickerType } from "../../../common/ui/components/SDNDatePicker";
import { dateRangePickerType } from "../../../common/ui/components/SDNDateRangePicker";
import { put, select, takeLatest, call, all } from 'redux-saga/effects';
import moment from 'moment';
import { Utils, infoBox } from "../../../common/utils/functions";

export const RESET_COMPARE_PARAMS = 'RESET_COMPARE_PARAMS';
export const EDIT_COMPARE_PARAMS = 'EDIT_COMPARE_PARAMS';
export const GET_COMPARE_DATA = 'GET_COMPARE_DATA';
export const GET_COMPARE_DATA_SUCCESS = 'GET_COMPARE_DATA_SUCCESS';
export const SET_REPORT_VIS = 'SET_REPORT_VIS';
const REQUEST_COMPARE_DATA_BEGIN = 'REQUEST_COMPARE_DATA_BEGIN';
const REQUEST_COMPARE_DATA_END = 'REQUEST_COMPARE_DATA_END';

export function* getCompareData(action) {
    const {
        dataClassifyId,
        objects,
        periodType,
        periods,
        rangeDateType,
        begin,
        end,
        dataCode,
        type
    } = action.payload;

    if (Utils.empty(objects)) {
        infoBox('请选择对比对象');
        return;
    }

    const orgIds = Object.keys(objects).toString();

    let dts = '';
    let interval = 201;
    if (type === 1) {
        dts = begin.startOf('hour').format('YYYY-MM-DD HH:mm:ss') + '~' + end.startOf('hour').format('YYYY-MM-DD HH:mm:ss');
        if (rangeDateType === dateRangePickerType.day) {
            interval = 301;
            dts = begin.startOf('day').format('YYYY-MM-DD HH:mm:ss') + '~' + end.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
        else if (rangeDateType === dateRangePickerType.month) {
            interval = 501;
            dts = begin.startOf('month').format('YYYY-MM-DD HH:mm:ss') + '~' + end.startOf('month').format('YYYY-MM-DD HH:mm:ss');
        }
    }
    else {
        if (Utils.empty(periods)) {
            infoBox('请选择对比时间');
            return;
        }
        interval = 201;
        if (periodType === datePickerType.year) {
            interval = 501;
        }
        else if (periodType === datePickerType.month || periodType === datePickerType.week) {
            interval = 301;
        }

        let array = [];
        for (const item of periods) {
            if (periodType === datePickerType.day) {
                array.push(item.startOf('day').format('YYYY-MM-DD') + ' 00:00:00');
            }
            else if (periodType === datePickerType.week) {
                array.push(item.startOf('week').format('YYYY-MM-DD HH:mm:ss') + '~' + item.endOf('week').format('YYYY-MM-DD HH:mm:ss'))
            }
            else if (periodType === datePickerType.month) {
                array.push(item.startOf('month').format('YYYY-MM-DD HH:mm:ss'));
            }
            else {
                array.push(item.startOf('year').format('YYYY-MM-DD HH:mm:ss'));
            }

        }
        dts = array.toString();
    }

    yield put({ type: REQUEST_COMPARE_DATA_BEGIN });
    const ret = yield call(
        efficiency.CompareService.getCompareAnalysis,
        dataClassifyId,
        orgIds,
        dts,
        interval,
        dataCode,
        type
    );
    yield put({ type: REQUEST_COMPARE_DATA_END });
    if (ret.code) {
        return;
    }
    const graphData = [];
    const tableData = [];
    for (const key in ret) {
        let graph = {};
        let item = ret[key];
        if (type === 1) {
            if (objects.hasOwnProperty(key)) {
                graph.title = objects[key];
            }
        }
        else {
            //
            let title = key.substr(0, 10);// moment(key).format('YYYY-MM-DD');
            if (periodType === datePickerType.year) {
                title = key.substr(0, 4);//moment(key).format('YYYY');
            }
            else if (periodType === datePickerType.month) {
                title = key.substr(0, 7); //moment(key).format('YYYY-MM');
            }
            else if (periodType === datePickerType.week) {
                title = key.substr(0, 23);
            }
            graph.title = title;
        }
        let tableObject = { title: graph.title, sum: 0, avg: 0, max: Number.MIN_SAFE_INTEGER, min: Number.MAX_SAFE_INTEGER }
        let values = [];
        let date = []; //周期对比用
        let hasValue = false;
        for (const data of item) {
            let array = [];
            //日期处理
            if (type == 1) {
                array.push(moment(data.dt).valueOf());
            }
            else {
                let dt = moment(data.dt).hour() + 1;
                if (periodType === datePickerType.year) {
                    dt = moment(data.dt).month() + 1;
                }
                else if (periodType === datePickerType.month) {
                    dt = data.dt.substr(8, 2);
                }
                else if (periodType === datePickerType.week) {
                    const dayCh = ['一', '二', '三', '四', '五', '六', '日'];
                    dt = '周' + dayCh[moment(data.dt).weekday()];
                }
                date.push(dt);
            }
            //数据处理
            if (data.PP || data.PP === 0) {
                hasValue = true;
                if (data.PP > tableObject.max) {
                    tableObject.max = data.PP;
                }
                if (data.PP < tableObject.min) {
                    tableObject.min = data.PP;
                }
                tableObject.sum += data.PP;
            }
            if (type == 1) {
                if (data.PP || data.PP === 0) {
                    array.push(data.PP);
                }
                values.push(array);
            }
            else {
                values.push((data.PP || data.PP === 0) ? data.PP : null);
            }
        }
        graph.data = values;
        graph.date = date;
        graphData.push(graph);
        if (tableObject.max === Number.MIN_SAFE_INTEGER) {
            tableObject.max = 0;
        }
        if (tableObject.min === Number.MAX_SAFE_INTEGER) {
            tableObject.min = 0;
        }
        if (hasValue) {
            tableObject.max = tableObject.max.toFixed(2);
            tableObject.min = tableObject.min.toFixed(2);
            tableObject.sum = tableObject.sum.toFixed(2);
            tableObject.avg = (tableObject.sum / item.length).toFixed(2);
        }
        else {
            tableObject.max = '-';
            tableObject.min = '-';
            tableObject.sum = '-';
            tableObject.avg = '-';
        }
        tableData.push(tableObject);
    }
    yield put({ type: GET_COMPARE_DATA_SUCCESS, payload: { graphData: graphData, tableData: tableData } });
}

export const compareAnalysisSagas = [
    takeLatest(GET_COMPARE_DATA, getCompareData)
];

export function SDNCompareAnalysisReducer(
    state = {
        loading: false,
        params: {
            dataClassifyId: '',
            objects: {},
            periodType: datePickerType.day,
            periods: [],
            rangeDateType: dateRangePickerType.hour,
            begin: moment().subtract(1, 'days'),
            end: moment(),
            dataCode: 'PP',
            type: 1 //1 对象对比  2 时间对比
        },
        graphData: [],
        tableData: [],
        reportVis: false
    },
    action
) {
    switch (action.type) {
        case RESET_COMPARE_PARAMS: {
            return {
                ...state,
                params: {
                    dataClassifyId: '',
                    objects: {},
                    periodType: datePickerType.day,
                    periods: [],
                    rangeDateType: dateRangePickerType.hour,
                    begin: moment().subtract(1, 'days'),
                    end: moment(),
                    dataCode: 'PP',
                    type: 1
                },
                graphData: [],
                tableData: []
            }
        }

        case EDIT_COMPARE_PARAMS: {
            const params = { ...state.params, ...action.payload };
            // console.log(params);
            return {
                ...state,
                params: params
            }
        }

        case REQUEST_COMPARE_DATA_BEGIN: {
            return { ...state, loading: true };
        }

        case REQUEST_COMPARE_DATA_END: {
            return { ...state, loading: false };
        }

        case GET_COMPARE_DATA_SUCCESS: {
            return {
                ...state,
                ...action.payload
            }
        }

        case SET_REPORT_VIS: {
            return { ...state, reportVis: action.payload };
        }

        default:
            return state;
    }
}
