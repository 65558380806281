import { put, takeLatest, call } from 'redux-saga/effects';
import { efficiency } from "../../../service/API";
import moment from 'moment';

//------------------- 峰值分析start -------------------//
export const GET_CYCLE_DATA = 'GET_CYCLE_DATA';
export const SET_CYCLE_DATA = 'SET_CYCLE_DATA';
export const EXPORT_CYCLE_DATA = 'EXPORT_CYCLE_DATA';
export const SET_CYCLE_INERVAL = 'SET_CYCLE_INERVAL';
export const RESET_CYCLE_PARAMS = 'RESET_CYCLE_PARAMS';
export const EDIT_CYCLE_PARAMS = 'EDIT_CYCLE_PARAMS';

//------------------- 峰值分析end -------------------//
const INIT_PAGE = "INIT_PAGE";

export function* exportCycleeData(action) {
  const { orgId, beginDate, endDate } = action.payload;
  try {
    const href = yield call(
      efficiency.EnergyAnalysisService.exportAnalysisWeek,
      orgId,
      beginDate,
      endDate
    );
    window.location.href = href
  } catch (e) {
    console.log("导出失败")
  }

}

export function* getCycleeData(action) {
  const { orgId, beginDate, endDate } = action.payload;
  const ret = yield call(
    efficiency.EnergyAnalysisService.getAnalysisWeek,
    orgId,
    beginDate,
    endDate
  );
  const statisticData = ret.code ? {} : ret;
  yield put({ type: SET_CYCLE_DATA, payload: statisticData });
}

export const powerCycleAnalysisSagas = [
  takeLatest(GET_CYCLE_DATA, getCycleeData),
  takeLatest(EXPORT_CYCLE_DATA, exportCycleeData),
];

//reducer
export function powerCycleAnalysisReducer(
  state = {
    page: {},
    cycleDataList: [],
    cycleData: {},
    dateInerval: moment().subtract(1, 'month').format("YYYY-MM-DD") + '至' + moment().format("YYYY-MM-DD"),
    paramsObj: {},
  },
  action
) {
  switch (action.type) {
    case INIT_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }

    case SET_CYCLE_DATA: {
      return {
        ...state,
        cycleDataList: action.payload.list || [],
        cycleData: action.payload || {}
      };
    }

    case SET_CYCLE_INERVAL: {
      return {
        ...state,
        dateInerval: action.payload,
      };
    }

    case RESET_CYCLE_PARAMS: {
      return {
        ...state,
        paramsObj: {},
      }
    }

    case EDIT_CYCLE_PARAMS: {
      return {
        ...state,
        paramsObj: action.payload
      }
    }

    default:
      return state;
  }
}