import { archive } from "../../service";
import { put, select, takeLatest, call, all } from 'redux-saga/effects';
import { FETCH_FAILED, FETCH_REQUEST, FETCH_SUCCESS } from "../../config/SDNActionType";
import * as actionType from './SDNEquipmentActionTypes';
import { getEquipment } from "../../config";
import {
  successBox
} from "../../common";
import { EDIT_SHOOTING_SCHEME } from "./SDNEquipmentActionTypes";
import { message } from "antd";

let consumerName = '';

/* 获取企业列表 */
export function* getEnterpriseList(action) {
  try {
    yield put({ type: FETCH_REQUEST });
    let res = yield call(archive.ConsumerService.getGroupList);
    if (!action.payload && res != null) {

      if (res.length > 0) {
        for (let i in res) {
          let item = res[i];
          item.key = item.id
          item.label = item.text
          item.value = item.text
          if (item.children != null) {

            for (let j in item.children) {
              let child = item.children[j];
              child.key = child.id;
              child.label = child.text;
              child.value = child.text;
            }
          }
        }

        yield put({ type: actionType.SELECT_GROUP, payload: res[0].children[0] });
        if (res[0].children[0].id) {
          yield put({ type: actionType.SITE_LIST_BY_ID, payload: res[0].children[0].id });
        } else {
          yield put({ type: actionType.SITE_LIST_BY_ID, payload: '' });
        }
      }

    }
    yield put({ type: actionType.ENTERPRISE_LIST_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取站点列表 */
export function* getSiteListById(action) {
  try {//
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorSiteService.getSiteListByEnterpriseId, action.payload);
    if (res != null) {
      for (let i in res) {
        let item = res[i];
        item.key = item.siteId;
        item.value = item.siteName;
        item.label = item.siteName;
      }
    }
    yield put({ type: actionType.SITE_LIST_BY_ID_SUCCESS, payload: res });
    if (res[0]) {
      yield put({ type: actionType.SELET_SITE, payload: res[0] });
      yield put({ type: actionType.SITE_TREE, payload: res[0].siteId });
    } else {
      yield put({ type: actionType.SELET_SITE, payload: res });
      yield put({ type: actionType.SITE_TREE_SUCCESS, payload: [] });
    }
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取树结构 */
export function* sitemonitorTree(action) {
  try {//
    if (!action.payload) return;
    const res = yield call(archive.MonitorObjectService.getSecondaryDeviceTree, action.payload);
    let red = [{}];
    const getStr = function (list) {
      list.forEach(function (row) {
        if (row.children) {
          row.key = row.id;
          row.title = row.name;
          getStr(row.children)
        } else {
          row.key = row.id;
          row.title = row.name;
        }
      })
      return
    }
    if (res != null) {
      red[0].key = res.id;
      red[0].title = res.name;
      getStr(res.children);
      red[0].children = res.children
    }
    yield put({ type: actionType.SITE_TREE_SUCCESS, payload: red });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: actionType.SITE_TREE_SUCCESS, payload: [] });
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 台账管理基础信息 */
export function* getBaseInfo(action) {//

  const equipment = yield select(getEquipment)
  try {
    let res = ''
    if (action.payload.type || action.payload.type === 0) {
      if (action.payload.type === 0) {
        res = yield call(archive.MonitorSiteService.get, action.payload.orgId);//站点
      } else {
        res = yield call(archive.MonitorObjectService.getBaseInfo, action.payload.orgId);//位置、设备、总加组
      }
    } else {
      res = yield call(archive.ConsumerService.getConsumerBasicInfo, action.payload.orgId);//用户
    }
    yield put({
      type: actionType.BASE_INFO_SUCCESS,
      payload: { res: res, type: action.payload.type, dataType: res.dataType }
    });
    if ((!res.monitorType || res.monitorType != 11) && !action.payload.isConsumer) {
      yield put({
        type: actionType.GET_DEVICE_AMMETER,
        payload: { id: action.payload.orgId, monitorType: res.monitorType }
      });
    }
    //equipment.page.renderBaseInfo(action.payload.type);
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 台账管理扩展信息 */
export function* getExtendInfo(action) {
  try {
    const res = yield call(archive.OrgExtendService.getExtendByMonitorObjectId, action.payload);
    //
    yield put({ type: actionType.EXTEND_INFO_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 台账管理获取个性参数 */
export function* getPersonalityInfo(action) {
  try {
    const res = yield call(archive.OrgExtendService.getPersonalByMonitorObjectId, action.payload);
    //
    yield put({ type: actionType.PERSONALITY_INFO_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 台账管理增加个性参数 */
export function* submitAddPersonality(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.OrgExtendService.add, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.SUBMIT_ADD_PERSONALITY_SUCCESS, payload: res });
      yield put({ type: actionType.PERSONALITY_INFO, payload: action.payload.orgUnit.orgId });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 台账管理修改个性参数 */
export function* submitEditPersonality(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.OrgExtendService.edit, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.SUBMIT_EDIT_PERSONALITY_SUCCESS, payload: res });
      yield put({ type: actionType.PERSONALITY_INFO, payload: action.payload.orgUnit.orgId });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 增加监控图 */
export function* submitAddDiagram(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorDiagramService.add, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.SUBMIT_ADD_DIAGRAM_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 修改监控图 */
export function* submitEditDiagram(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorDiagramService.edit, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.SUBMIT_EDIT_DIAGRAM_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 删除监控图 */
export function* delDiagram(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorDiagramService.del, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.DEL_DIAGRAM_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取添加关联弹框左边的树 */
export function* getRelationTree(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    /* const res = yield call(archive.MonitorObjectService.getSecondaryDeviceTree, action.payload);
    yield put({ type: GET_RELATION_TREE_SUCCESS, payload: res });
    yield put({type: FETCH_SUCCESS}); */
    if (!action.payload) return;
    const res = yield call(archive.MonitorObjectService.getSecondaryDeviceTree, action.payload);
    let red = [{}];
    const getStr = function (list) {
      list.forEach(function (row) {
        if (row.children) {
          row.key = row.id;
          row.title = row.name;
          getStr(row.children)
        } else {
          row.key = row.id;
          row.title = row.name;
        }
      })
      return
    }
    if (res != null) {
      red[0].key = res.id;
      red[0].title = res.name;
      getStr(res.children);
      red[0].children = res.children
    }
    yield put({ type: actionType.GET_RELATION_TREE_SUCCESS, payload: red });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取数据项列表 */
export function* getUnSelectDataItem(action) {
  try {
    const res = yield call(archive.MonitorTypeDataService.getUnSelectDataItemList, action.payload.monitorObjectTypeId, action.payload.ammeterId);
    //
    yield put({ type: actionType.GET_SELECT_DATAITEMLIST_SUCCESS, payload: res });
    //yield put({ type: SELECT_DATAITEM_SUCCESS, payload: res[0] });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}


/* 获取数据项类型 */
export function* getDatatype(action) {//
  try {
    yield put({ type: actionType.GET_DATATYPE_SUCCESS, payload: action.payload });
  } catch (error) {

  }
}

/*获取可以 关联的设备列表*/
export function* getPrimaryBySite(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorObjectService.getPrimaryBySite, action.payload);
    if (res != null) {
      for (let i in res) {
        let item = res[i];
        item.key = item.id;
        item.value = item.name;
        item.label = item.name;
      }
    }
    yield put({ type: actionType.GET_PRIMARY_BY_SITE_SUCCESS, payload: res });
    yield put({ type: actionType.SELECT_PRIMARY, payload: res[0].id });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取选中的关联监控对象 */
export function* selPrimaryItem(action) {
  yield put({ type: actionType.SELECT_PRIMARY, payload: action.payload });
}

/* 查询未绑定监控点的数据项 */
export function* getDataItemByPrimary(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.DataItemService.getUnUseByPrimary, action.payload);
    yield put({ type: actionType.DATAITEM_BY_PRIMARY_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 增加监控点 */
export function* addSite(action) {
  try {//
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorItemService.addMonitorItem, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.ADD_SITE_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 编辑监控点 */
export function* editSite(action) {
  try {//
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorItemService.edit, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.EDIT_SITE_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 删除站点 */
export function* delSite(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorItemService.del, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.DEL_DITE_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 添加关联 */
export function* addRelation(action) {
  try {//
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorObjectItemService.add, action.payload);
    yield put({ type: actionType.ADD_RELATION_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取台账管理左边下拉选项 */
export function* getSelectObjData(action) {
  const equipment = yield select(getEquipment);
  try {
    yield put({ type: FETCH_REQUEST });
    let res = yield call(archive.ConsumerService.getAll);
    if (res != null) {
      if (res.length > 0) {
        let selectedConsumer = res[0];
        for (let i in res) {
          let item = res[i];
          if (action.payload.consumerId && action.payload.consumerId === item.orgId) {
            selectedConsumer = item;
          }
          item.key = item.orgId
          item.label = item.orgName
          item.value = item.orgName
        }
        if (!action.payload.autoDispatch) {
          yield put({ type: actionType.SELECTED_CONSUMER, payload: selectedConsumer });
          yield put({
            type: actionType.CONSUMER_TREE,
            payload: { consumerId: selectedConsumer.orgId, type: true }
          });
        }
      }

    }
    yield put({ type: actionType.SELECT_OBJECT_SUCCESS, payload: res });
    if (action.payload.consumerId) {
      equipment.page.matchConsumerName(action.payload.consumerId);
    }
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取台账管理左边树 */
export function* consumerTree(action) {
  try {
    if (action.payload.consumerId) {
      const res = yield call(archive.MonitorObjectService.getConsumerMonitorObjectTree, action.payload.consumerId);
      let red = [{}];
      const getStr = function (list) {
        list.forEach(function (row) {
          if (row.children) {
            row.key = row.id;
            row.title = row.name;
            getStr(row.children)
          } else {
            row.key = row.id;
            row.title = row.name;
          }
        })
        return
      }
      if (res != null) {
        red[0].key = res.id;
        red[0].title = res.name;
        red[0].isConsumer = res.isConsumer; //用来判断是否是企业
        getStr(res.children);
        red[0].children = res.children
        yield put({ type: actionType.CHANGE_TREE_NODE_SUCCESS, payload: res.id });

        consumerName = res.name;

        if (res.isConsumer === 1 && res.id) {
          yield put({ type: actionType.GET_COM_DEVICE_LIST, payload: { consumerId: res.id } })
        }
      }
      yield put({ type: actionType.CONSUMER_TREE_SUCCESS, payload: red });

      if (action.payload.type == true) {
        yield put({ type: actionType.TITILE_INFO, payload: red[0].key });
        yield put({ type: actionType.BASE_INFO, payload: { orgId: red[0].key, type: '' } });
      }
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取标题的基础信息 */
export function* getTitleInfo(action) {
  try {
    const res = yield call(archive.MonitorObjectService.get, action.payload);
    yield put({ type: actionType.TITILE_INFO_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取图片 */
export function* getImgInfo(action) {
  try {//
    const res = yield call(archive.OrgFileService.getAll, action.payload.fileType, action.payload.orgId);
    yield put({ type: actionType.IMG_INFO_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取文件 */
export function* getFileInfo(action) {
  try {//
    const res = yield call(archive.OrgFileService.getAll, action.payload.fileType, action.payload.orgId);
    yield put({ type: actionType.FILE_INFO_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 选择对象类型表格-多选 */
export function* selectNode(action) {
  try {//
    yield put({ type: actionType.CHOOSE_NODE_SUCCESS, payload: action.payload });
  } catch (error) {

  }
}

/* 添加模板 */
export function* addTemplate(action) {
  try {//
    const ret = yield call(archive.TemplateService.add, action.payload);
    if (ret.code == 200) {
      yield put({ type: actionType.ADD_TEMPLATE_SUCCESS, payload: action.payload });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 删除模板 */
export function* removeTempalte(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.TemplateService.del, action.payload);
    if (ret.code == 200) {
      yield put({ type: actionType.REMOVE_TEMPLATE_SUCCESS, payload: action.payload });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 编辑模板 */
export function* editTemplate(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.TemplateService.edit, action.payload);
    if (ret.code == 200) {
      yield put({ type: actionType.EDIT_TEMPLATE_SUCCESS, payload: action.payload });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取模板下拉选项 */
export function* getTemplateOption(action) {
  try {//
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.TemplateService.getAllForOption);
    yield put({ type: actionType.TEMPLATE_OPTION_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

//数据项-获取监控设备的监控点列表
export function* getDataItemWithTree(action) {
  try {//
    yield put({ type: 'FETCH_REQUEST' });
    const res = yield call(archive.MonitorItemService.getListBySecondaryDeviceDataTable, action.payload);
    yield put({ type: actionType.GET_DATAITEM_WITH_TREE_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

//删除下级
export function* removeSubLevel(action) {
  yield put({ type: FETCH_REQUEST });
  const res = yield call(archive.MonitorObjectService.delSubObject, action.payload);
  const equipment = yield select(getEquipment);
  if (res.code == 200) {
    yield put({ type: actionType.REMOVE_SUB_LEVEL_SUCCESS });
    const { consumerTree } = equipment;
    if (consumerTree && consumerTree.length > 0) {
      yield put({ type: actionType.CONSUMER_TREE, payload: { consumerId: consumerTree[0].key } });
    }
  }

  yield put({ type: FETCH_SUCCESS });
}

//调整上下级序号
export function* upgradeDegradeSubLevel(action) {
  yield put({ type: FETCH_REQUEST });
  const {
    orgId,
    type
  } = action.payload
  const res = yield call(archive.MonitorObjectService.editIx, orgId, type);
  if (res.code == 200) {
    yield put({ type: actionType.UPGRADE_DEGRAD_SUB_LEVEL_SUCCESS });
  }
  yield put({ type: FETCH_SUCCESS });
}

//添加下级站点
export function* addSubNodeStation(action) {
  yield put({ type: FETCH_REQUEST });
  const res = yield call(
    archive.MonitorObjectService.addMonitorSite,
    action.payload
  );

  if (res.code == 200) {
    yield put({ type: actionType.ADD_SUB_LEVEL_SUCCESS });
    yield put({ type: actionType.CONSUMER_TREE, payload: { consumerId: action.payload.consumerId } });
  }
  yield put({ type: FETCH_SUCCESS });
}

//添加下级功能位置
export function* addSubNodeLocation(action) {
  yield put({ type: FETCH_REQUEST });
  const res = yield call(
    archive.MonitorObjectService.addLocationArea,
    action.payload
  );

  if (res.code == 200) {
    yield put({ type: actionType.ADD_SUB_LEVEL_SUCCESS });
    yield put({ type: actionType.CONSUMER_TREE, payload: { consumerId: action.payload.consumerId } });
  }
  yield put({ type: FETCH_SUCCESS });
}

//添加下级设备
export function* addSubNodeDevice(action) {
  yield put({ type: FETCH_REQUEST });
  const res = yield call(
    archive.MonitorObjectService.addDevice,
    action.payload
  );

  if (res.code == 200) {
    yield put({ type: actionType.ADD_SUB_LEVEL_SUCCESS });
    yield put({ type: actionType.CONSUMER_TREE, payload: { consumerId: action.payload.consumerId } });
  }
  yield put({ type: FETCH_SUCCESS });
}

//添加下级总加组
export function* addSubNodeGroup(action) {
  yield put({ type: FETCH_REQUEST });
  const res = yield call(
    archive.MonitorObjectService.addGroupAmmeter,
    action.payload
  );

  if (res.code == 200) {
    yield put({ type: actionType.ADD_SUB_LEVEL_SUCCESS });
    yield put({ type: actionType.CONSUMER_TREE, payload: action.payload });
  }
  yield put({ type: FETCH_SUCCESS });
}

//获取单个预置位属性
export function* getShootingSchemesById(action) {
  const equipment = yield select(getEquipment)
  try {//
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.ShootingSchemeService.getMonitorItemByObject, action.payload.monitorObjectId);
    yield put({ type: actionType.SET_SHOOTING_SCHEMES_BY_ID, payload: res });
    equipment.page.refreshShotColumn();
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

export function* getMonitorItemList(action) {
  try {//
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorItemService.getListMonitorItems, action.payload.orgId, action.payload.monitorType);
    yield put({ type: actionType.SET_MONITOR_ITEM_LIST, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

export function* getShootingScheme(action) {
  try {//
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.ShootingSchemeService.get, action.payload);
    yield put({ type: actionType.SET_SHOOTING_SCHEME, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 修改通讯参数 */
export function* editfieldByType(action) {
  try {
    const equipment = yield select(getEquipment)
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorObjectService.editfieldByType, action.payload.id, action.payload.fieldName, action.payload.fieldValue, action.payload.type);
    if (res.code == 200) {
      equipment.page.editSuccess();
      yield put({
        type: actionType.GET_DEVICE_AMMETER,
        payload: { id: action.payload.orgId, monitorType: action.payload.monitorType }
      });
      yield put({ type: FETCH_SUCCESS });
    } else {
      equipment.page.editError();
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取通讯信息 */
export function* getDeviceAmmeter(action) {
  try {
    const equipment = yield select(getEquipment)
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorObjectService.getDeviceAndAmmeter, action.payload.id, action.payload.monitorType);
    yield put({ type: actionType.GET_DEVICE_AMMETER_SUCCESS, payload: res });

    if (action.payload.monitorType == 1) {
      yield put({
        type: actionType.GET_LIST_BY_MONITOROBJECT,
        payload: { monitorObjectId: action.payload.id, monitorType: 2 }
      });
    } else if (action.payload.monitorType == 2) {
      yield put({ type: actionType.GET_LIST_BY_COMSUMERID, payload: res.dataAmmeterId });
    } else if (action.payload.monitorType == 4 && res.commMode == 1) {
      yield put({
        type: actionType.GET_SJZXAMMETERS,
        payload: { consumerName: consumerName, monitorType: action.payload.monitorType }
      });
      return
    }
    if (!action.payload.isConsumer) equipment.page.renderCommunicateInfo(action.payload.monitorType);
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取管理机列表 */
export function* getListMonitorObject(action) {
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorObjectService.getListByMonitorObject, action.payload.monitorObjectId, action.payload.monitorType);
    yield put({ type: actionType.GET_LIST_BY_MONITOROBJECT_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 绑定设备列表 */
export function* getListConsumerId(action) {
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.AmmeterService.getListByConsumerId, action.payload);
    yield put({ type: actionType.GET_LIST_BY_COMSUMERID_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 保存用户名 */
export function* saveConsumerName(action) {
  consumerName = action.payload;
}

/* 用户名称查询计量名称列表 */
export function* getSjzxAmmeters(action) {
  try {
    const equipment = yield select(getEquipment)
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.AmmeterService.getSjzxAmmeters, action.payload.consumerName);
    yield put({ type: actionType.GET_SJZXAMMETERS_SUCCESS, payload: res });
    equipment.page.renderCommunicateInfo(action.payload.monitorType);
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 添加关联 */
export function* submitAddMulti(action) {
  try {
    yield put({ type: FETCH_REQUEST });
    let arr = [];
    arr.push(action.payload);
    const res = yield call(archive.MonitorObjectItemService.addMulti, JSON.stringify(arr));
    if (res.code == 200) {
      yield put({ type: actionType.SUBMIT_ADD_MULTI_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 解除关联 */
export function* delMonitorRelation(action) {
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorObjectItemService.del, action.payload);
    if (res.code == 200) {
      yield put({ type: actionType.DEL_MONITOR_RELATION_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 编辑拍照方案 */
export function* editShotScheme(action) {
  try {
    yield put({ type: 'FETCH_REQUEST' });
    const ret = yield call(archive.ShootingSchemeService.edit, action.payload);
    yield put({ type: actionType.EDIT_SHOOTING_SCHEME_SUCCESS });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 获取总加组表格 */
export function* getListByPAmmeter(action) {
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.AmmeterRelationService.getListByPAmmeter, action.payload);

    yield put({ type: actionType.GET_LIST_BY_PAMMETER_SUCCESS, payload: res });
    yield put({ type: FETCH_SUCCESS });
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 重新计算 */
export function* editRecalculation(action) {
  try {
    message.loading()
    const res = yield call(archive.AmmeterService.editRecalculation,
      action.payload.dataAmmeterId,
      action.payload.fromDt,
      action.payload.toDt,
      action.payload.type,
      action.payload.interval,
    );
    message.destroy()
    if (res.code == 200) {
      yield put({ type: actionType.EDIT_RECALCULTION_SUCCESS, payload: res });
      if (action.resolve) {
        action.resolve()
      }
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 移除电表 */
export function* removeAmmeterRelation(action) {
  try {
    const equipment = yield select(getEquipment)
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.AmmeterRelationService.del, action.payload);
    if (res.code == 200) {
      //yield put({type: actionType.REMOVE_AMMETER_RELATION_SUCCESS, payload: res});
      equipment.page.removeRealtionSuccess();
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 添加电表 */
export function* addAmmeterRelations(action) {
  try {
    const equipment = yield select(getEquipment)
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.AmmeterRelationService.add, action.payload);
    if (res.code == 200) {
      //yield put({type: actionType.ADD_AMMETER_RELATIONS_SUCCESS, payload: res});
      equipment.page.addSuccess('ammeterRelation');
      yield put({ type: FETCH_SUCCESS });
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 改变选中节点 */
export function* changeTreeNode(action) {
  const equipment = yield select(getEquipment)
  yield put({ type: actionType.CHANGE_TREE_NODE_SUCCESS, payload: action.payload.key });
  if (action.payload.selectobj) equipment.page.selectComsumerTree([action.payload.key], action.payload.selectobj);
  if (action.payload.key) yield put({
    type: actionType.GET_ADDABLE_NODE_TYPE,
    payload: action.payload.key
  });
}

/* 修改标题信息 */
export function* onChangeTiltleInfo(action) {
  try {
    const equipment = yield select(getEquipment)
    yield put({ type: FETCH_REQUEST });
    //const res = yield call(archive.MonitorObjectService.editField,action.payload.orgId,action.payload.field,action.payload.value);
    const res = yield call(archive.MonitorObjectService.updateBaseInfo, action.payload.orgId, action.payload.field, action.payload.value);
    if (res.code == 200) {
      yield put({ type: actionType.CHNANGE_TITLEINFO_SUCCESS, payload: res });
      yield put({ type: FETCH_SUCCESS });
      equipment.page.changeTitleInfoSuccess();
    } else {
      equipment.page.editError();
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 修改其他信息的实时修改 */
export function* onChangeOtherInfo(action) {
  const equipment = yield select(getEquipment);
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorObjectService.updateBaseInfo, action.payload.orgId, action.payload.field, action.payload.value);
    if (res.code == 200) {
      //yield put({type: actionType.CHNANGE_OTHERINFO_SUCCESS, payload: res});
      yield put({ type: FETCH_SUCCESS });
      equipment.page.changeOtherInfoSuccess();
    } else {
      equipment.page.editError();
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}


/* 修改站点基础信息的实时修改 */
export function* onChangeSiteInfo(action) {
  const equipment = yield select(getEquipment);
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorSiteService.editField, action.payload.orgId, action.payload.field, action.payload.value, '');
    if (res.code == 200) {
      //yield put({type: actionType.CHNANGE_OTHERINFO_SUCCESS, payload: res});
      yield put({ type: FETCH_SUCCESS });
      equipment.page.changeSiteInfoSuccess();
    } else {
      equipment.page.editError();
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 修改扩展信息信息的实时修改 */
export function* changeExtendInfo(action) {//orgExtendId, monitorTypeExtendId, orgId, value
  const equipment = yield select(getEquipment);
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorTypeExtendService.editValue, action.payload.orgExtendId, action.payload.monitorTypeExtendId, action.payload.orgId, action.payload.value);
    if (res.code == 200) {
      yield put({ type: FETCH_SUCCESS });
      equipment.page.changeSuccess();
    } else {
      equipment.page.editError();
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

//获取可添加下级类型
export function* getAddableNodeType(action) {
  yield put({ type: FETCH_REQUEST });
  const res = yield call(archive.MonitorObjectTypeService.getChildrenClassifiedType, action.payload);
  if (res instanceof Array) {
    yield put({ type: actionType.GET_ADDABLE_NODE_TYPE_SUCCESS, payload: res });
  }
  yield put({ type: FETCH_SUCCESS });
}

/* 修改计量名称 */
export function* editDataAmmeter(action) {
  const equipment = yield select(getEquipment);
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.AmmeterService.editDataAmmeter, action.payload.ammeter);
    if (res.code == 200) {
      yield put({
        type: actionType.GET_DEVICE_AMMETER,
        payload: { id: action.payload.orgId, monitorType: action.payload.monitorType }
      });
      yield put({ type: FETCH_SUCCESS });
      //equipment.page.renderCommunicateInfo(action.payload.monitorType);
      equipment.page.changeSuccess();
    } else {
      equipment.page.editError();
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

/* 通过id获取节点信息 */
export function* getTreeNodeBaseInfo(action) {
  const equipment = yield select(getEquipment);
  try {
    yield put({ type: FETCH_REQUEST });
    const res = yield call(archive.MonitorObjectService.getTreeNodeBaseInfo, action.payload);
    let data = res;
    let selectobj = {
      selectedNodes: [
        {
          props: {
            orgId: data.orgId,//点击树拿到的id
            isConsumer: false,
            classifiedType: data.classifiedType,//点击树拿到的类型
            monitorType: data.monitorType || '',//是否为总加组
            ammeterId: data.ammeterId || '',
            dataAmmeterId: data.dataAmmeterId || '',
            monitorObjectTypeId: data.monitorObjectTypeId || '',
            siteId: data.siteId,
          }
        }
      ]
    }

    if (data.orgId) {
      yield put({ type: actionType.CHANGE_TREE_NODE_SUCCESS, payload: data.orgId });
      equipment.page.selectComsumerTree([data.orgId], selectobj);
      yield put({ type: FETCH_SUCCESS });
    } else {
      equipment.page.selectComsumerTree('');
    }
  } catch (error) {
    yield put({ type: FETCH_FAILED, error: error.message });
  }
}

export function* batchAddMonitorSites(action) {
  const {
    dataItemIds,
    ammeterId,
    siteId
  } = action.payload;
  yield put({ type: actionType.ADDING_BATCH_MONITOR_SITES, payload: true });
  const response = yield call(
    archive.MonitorItemService.addMonitorItems,
    dataItemIds,
    ammeterId,
    siteId
  );
  if (response.code === 200) {
    successBox('批量添加成功');
    yield put({ type: actionType.SHOW_BATCH_ADD_MONITOR_SITES, payload: false });
    yield put({ type: actionType.BATCH_CHECKED_MONITOR_SITES, payload: [] });
  }
  yield put({ type: actionType.ADDING_BATCH_MONITOR_SITES, payload: false });
  yield put({ type: actionType.GET_SELECT_DATAITEMLIST_SUCCESS, payload: [] });
}

export function* onQueryUala(action) {
  if (action.payload) {
    const response = yield call(archive.MonitorObjectService.queryUaIa, action.payload);
    yield put({ type: actionType.SET_QUERY_UALA, payload: response || {} });
  } else {
    yield put({ type: actionType.SET_QUERY_UALA, payload: {} });
  }

}

export function* onGetComDeviceList(action) {
  const response = yield call(archive.MonitorObjectService.getListByEnterpriseId, action.payload.consumerId, 3);
  yield put({ type: actionType.SET_COM_DEVICE_LIST, payload: response || {} });
}

export function* onGetComDeviceBindDevicesList(action) {
  const response = yield call(archive.AmmeterService.searchAmmeters, action.payload.orgId);
  yield put({ type: actionType.SET_COM_DEVICE_BIND_DEVICES_LIST, payload: response || {} });
}

const equipmentSagas = [
  takeLatest(actionType.SITE_TREE, sitemonitorTree),
  takeLatest(actionType.ENTERPRISE_LIST, getEnterpriseList),
  takeLatest(actionType.SITE_LIST_BY_ID, getSiteListById),
  takeLatest(actionType.BASE_INFO, getBaseInfo),
  takeLatest(actionType.EXTEND_INFO, getExtendInfo),
  takeLatest(actionType.PERSONALITY_INFO, getPersonalityInfo),
  takeLatest(actionType.SUBMIT_ADD_PERSONALITY, submitAddPersonality),
  takeLatest(actionType.SUBMIT_EDIT_PERSONALITY, submitEditPersonality),
  takeLatest(actionType.SELECT_OBJECT, getSelectObjData),
  takeLatest(actionType.CONSUMER_TREE, consumerTree),
  takeLatest(actionType.TITILE_INFO, getTitleInfo),
  takeLatest(actionType.CHOOSE_NODE, selectNode),
  takeLatest(actionType.GET_SELECT_DATAITEMLIST, getUnSelectDataItem),
  takeLatest(actionType.ADD_TEMPLATE, addTemplate),
  takeLatest(actionType.REMOVE_TEMPLATE, removeTempalte),
  takeLatest(actionType.EDIT_TEMPLATE, editTemplate),
  takeLatest(actionType.GET_DATATYPE, getDatatype),
  takeLatest(actionType.GET_PRIMARY_BY_SITE, getPrimaryBySite),
  takeLatest(actionType.SEL_PRIMARYITEM, selPrimaryItem),
  takeLatest(actionType.DATAITEM_BY_PRIMARY, getDataItemByPrimary),
  takeLatest(actionType.ADD_RELATION, addRelation),
  takeLatest(actionType.DEL_DITE, delSite),
  takeLatest(actionType.ADD_SITE, addSite),
  takeLatest(actionType.EDIT_SITE, editSite),
  takeLatest(actionType.IMG_INFO, getImgInfo),
  takeLatest(actionType.FILE_INFO, getFileInfo),
  takeLatest(actionType.SUBMIT_ADD_DIAGRAM, submitAddDiagram),
  takeLatest(actionType.DEL_DIAGRAM, delDiagram),
  takeLatest(actionType.SUBMIT_EDIT_DIAGRAM, submitEditDiagram),
  takeLatest(actionType.TEMPLATE_OPTION, getTemplateOption),
  takeLatest(actionType.GET_RELATION_TREE, getRelationTree),
  takeLatest(actionType.GET_DATAITEM_WITH_TREE, getDataItemWithTree),
  takeLatest(actionType.EDIT_FIELD_BY_TYPE, editfieldByType),
  takeLatest(actionType.GET_DEVICE_AMMETER, getDeviceAmmeter),
  takeLatest(actionType.GET_LIST_BY_MONITOROBJECT, getListMonitorObject),
  takeLatest(actionType.GET_LIST_BY_COMSUMERID, getListConsumerId),
  takeLatest(actionType.SAVE_CONSUMERNAME, saveConsumerName),
  takeLatest(actionType.GET_SJZXAMMETERS, getSjzxAmmeters),
  takeLatest(actionType.SUBMIT_ADD_MULTI, submitAddMulti),
  takeLatest(actionType.DEL_MONITOR_RELATION, delMonitorRelation),
  takeLatest(actionType.GET_LIST_BY_PAMMETER, getListByPAmmeter),
  takeLatest(actionType.EDIT_RECALCULTION, editRecalculation),
  takeLatest(actionType.REMOVE_AMMETER_RELATION, removeAmmeterRelation),
  takeLatest(actionType.ADD_AMMETER_RELATIONS, addAmmeterRelations),
  takeLatest(actionType.CHANGE_TREE_NODE, changeTreeNode),
  takeLatest(actionType.CHNANGE_TITLEINFO, onChangeTiltleInfo),
  takeLatest(actionType.CHNANGE_OTHERINFO, onChangeOtherInfo),
  takeLatest(actionType.CHNANGE_SITEINFO, onChangeSiteInfo),
  takeLatest(actionType.CHNANGE_EXTENDINFO, changeExtendInfo),
  takeLatest(actionType.EDIT_DATA_AMMTER, editDataAmmeter),
  takeLatest(actionType.GET_TREE_NODE_BASEINFO, getTreeNodeBaseInfo),
  //层级关系
  takeLatest(actionType.REMOVE_SUB_LEVEL, removeSubLevel),
  takeLatest(actionType.UPGRADE_DEGRADE_SUB_LEVEL, upgradeDegradeSubLevel),
  takeLatest(actionType.ADD_SUB_LEVEL_STATION, addSubNodeStation),
  takeLatest(actionType.ADD_SUB_LEVEL_LOCATION, addSubNodeLocation),
  takeLatest(actionType.ADD_SUB_LEVEL_DEVICE, addSubNodeDevice),
  takeLatest(actionType.ADD_SUB_LEVEL_GROUP, addSubNodeGroup),
  takeLatest(actionType.GET_ADDABLE_NODE_TYPE, getAddableNodeType),
  //拍摄方案
  takeLatest(actionType.GET_SHOOTING_SCHEMES_BY_ID, getShootingSchemesById),
  takeLatest(actionType.GET_MONITOR_ITEM_LIST, getMonitorItemList),
  takeLatest(actionType.GET_SHOOTING_SCHEME, getShootingScheme),
  takeLatest(actionType.EDIT_SHOOTING_SCHEME, editShotScheme),

  takeLatest(actionType.BATCH_ADD_MONITOR_SITES, batchAddMonitorSites),
  takeLatest(actionType.GET_QUERY_UALA, onQueryUala),
  takeLatest(actionType.GET_COM_DEVICE_LIST, onGetComDeviceList),
  takeLatest(actionType.GET_COM_DEVICE_BIND_DEVICES_LIST, onGetComDeviceBindDevicesList)
];

export default equipmentSagas;
