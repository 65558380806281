import { put, takeLatest, call } from 'redux-saga/effects';
import { efficiency } from "../../../service/API";
import moment from 'moment';

//------------------- 峰值分析start -------------------//
export const GET_TIME_SHARE_DATA = 'GET_TIME_SHARE_DATA';
export const SET_TIME_SHARE_DATA = 'SET_TIME_SHARE_DATA';
export const EXPORT_TIME_SHARE_DATA = 'EXPORT_TIME_SHARE_DATA';
export const SET_TIME_SHARE_INERVAL = 'SET_TIME_SHARE_INERVAL';
export const RESET_TIME_SHARE_PARAMS = 'RESET_TIME_SHARE_PARAMS';
export const EDIT_TIME_SHARE_PARAMS = 'EDIT_TIME_SHARE_PARAMS';

//------------------- 峰值分析end -------------------//
const INIT_PAGE = "INIT_PAGE";

export function* exportTimeShareData(action) {
  const { orgId, beginDate, endDate } = action.payload;
  try {
    const href = yield call(
      efficiency.EnergyAnalysisService.exportAnalysisHour,
      orgId,
      beginDate,
      endDate
    );
    window.location.href = href
  } catch (e) {
    console.log("导出失败")
  }

}

export function* getTimeShareData(action) {
  const { orgId, beginDate, endDate } = action.payload;
  const ret = yield call(
    efficiency.EnergyAnalysisService.getAnalysisHour,
    orgId,
    beginDate,
    endDate
  );
  const statisticData = ret.code ? {} : ret;
  yield put({ type: SET_TIME_SHARE_DATA, payload: statisticData });
}

export const powerTimeShareAnalysisSagas = [
  takeLatest(GET_TIME_SHARE_DATA, getTimeShareData),
  takeLatest(EXPORT_TIME_SHARE_DATA, exportTimeShareData),
];

//reducer
export function powerTimeShareAnalysisReducer(
  state = {
    page: {},
    timeShareList: [],
    timeShareTotal: 0,
    dateInerval: moment().subtract(1, 'month').format("YYYY-MM-DD") + '至' + moment().format("YYYY-MM-DD"),
    paramsObj: {},
  },
  action
) {
  switch (action.type) {
    case INIT_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }

    case SET_TIME_SHARE_DATA: {
      return {
        ...state,
        timeShareList: action.payload.list || [],
        timeShareTotal: action.payload.total || 0
      };
    }

    case SET_TIME_SHARE_INERVAL: {
      return {
        ...state,
        dateInerval: action.payload,
      };
    }

    case RESET_TIME_SHARE_PARAMS: {
      return {
        ...state,
        paramsObj: {},
      }
    }

    case EDIT_TIME_SHARE_PARAMS: {
      return {
        ...state,
        paramsObj: action.payload
      }
    }

    default:
      return state;
  }
}
