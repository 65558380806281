import React from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import { serverUrl } from "../../service";
import { accountType } from "../../config";
import Flex, { FlexItem } from "styled-flex-component";
import { GET_FIRM_SUB_TREE } from "../../common/system";
import SDNFirmSelect from "../../common/ui/components/SDNFirmSelect";
import { GET_SITE_LIST_BY_ID } from "../../views/SDNEventAlert/SDNEventAlertAcitonType";

const { Header } = Layout;
const defaultTitle = "慧效云综合能源管理平台";
const defaultImage = "/assets/img/hxy/logo.png";

class SDNHeader extends React.Component {
  state = {
    orgIcon: "",
    orgName: "",
  };

  componentWillReceiveProps({ firmList }) {
    if (firmList && firmList[0] && firmList[0].acIcon) {
      const { acIcon: orgIcon, orgName: orgName } = firmList[0];
      this.setState({ orgIcon, orgName });
    }
  }

  imgOnError = e => {
    this.setState({ orgIcon: defaultImage, orgName: defaultTitle });
  };

  handleSelectedCompany = firm => {
    const { acIcon: orgIcon, orgName } = firm;
    this.setState({ orgIcon, orgName });
    this.props.getFirmSubTree(firm.orgId);
    this.props.getAlertSiteListById(firm.orgId);
  };

  renderCompanySelector = () => {
    const { user, firmList } = this.props;
    if (!firmList || !firmList[0]) return null;
    if (user.accountType === 0 || user.accountType === accountType.normal) {
      return (
        <FlexItem style={{ marginLeft: 15 }}>
          <SDNFirmSelect width={260} onSelectedFirm={firm => this.handleSelectedCompany(firm)} />
        </FlexItem>
      );
    }
    return null;
  };

  renderLogoTitle = () => {
    let alt = defaultTitle;
    let src = defaultImage;
    let { orgIcon, orgName } = this.state;
    if (orgIcon && orgName) {
      alt = orgName;
      src = orgIcon;
    }
    src = src === defaultImage ? src : serverUrl + src;
    return (
      <FlexItem style={{ textAlign: "center", minWidth: 220, marginRight: 15 }}>
        <img alt={alt} src={src} title={alt} onError={e => this.imgOnError(e)} style={{ width: "auto", maxHeight: 40 }} />
      </FlexItem>
    );
  };

  render() {
    return (
      <Header style={{ background: "#FFF", padding: "0 15px", height: 64 }}>
        <Flex full alignCenter>
          {this.renderLogoTitle()}
          {this.renderCompanySelector()}
        </Flex>
      </Header>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.system.user,
    firmList: state.system.firmList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFirmSubTree: objectId => dispatch({ type: GET_FIRM_SUB_TREE, payload: objectId }),
    getAlertSiteListById: enterpriseId => dispatch({ type: GET_SITE_LIST_BY_ID, payload: enterpriseId }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SDNHeader);
