/**
 * version  0.1
 * date     2019/12/16 14:14
 * author   caojiaxu
 * desc
 */
import {put, select, takeLatest, call, all} from 'redux-saga/effects';
import {FETCH_FAILED, FETCH_REQUEST, FETCH_SUCCESS, INIT_PAGE} from "../../../config/SDNActionType";
import {archive, alarm} from "../../../service";
import user from "../../../service/API/user";
import {getEquipment} from "../../../config";
import * as actionType from "../SDNEquipmentActionTypes";
import {GET_FIRM_SUB_TREE_SUCCESS} from "../../../common/system";

export const GET_MONITOR_DATA_ITEMS = 'GET_MONITOR_DATA_ITEMS';
export const GET_MONITOR_DATA2_ITEMS = 'GET_MONITOR_DATA2_ITEMS';
export const SET_MONITOR_DATA_ITEMS = 'SET_MONITOR_DATA_ITEMS';
export const GET_MONITOR_HISTORY = 'GET_MONITOR_HISTORY';
export const SET_MONITOR_HISTORY = 'SET_MONITOR_HISTORY';
export const SHOW_MONITOR_MODAL = 'SHOW_MONITOR_MODAL';
export const SET_MONITOR_ITEM = 'SET_MONITOR_ITEM';
export const GET_ALERT_TABLE = 'GET_ALERT_TABLE';
export const SET_ALERT_TABLE = 'SET_ALERT_TABLE';
export const SET_ALERT_TABLE_PAGINATION = 'SET_ALERT_TABLE_PAGINATION';
export const EXPORT_EQUIPMENT = 'EXPORT_EQUIPMENT';
export const GET_SEL_TREE_NODE_INFO = 'GET_SEL_TREE_NODE_INFO';
export const SET_SEL_NODE = 'SET_SEL_NODE';

/**
 * @desc 监测数据历史数据
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMonitorHistorys(action) {
  const {monitorItemId, dataType, startTime, endTime, interval} = action.payload;
  if (action.payload) {
    let res = yield call(user.UserMonitorItemService.getHistoryDatas, monitorItemId, dataType, startTime, endTime, interval);
    if (!res.code) {
      yield put({type: SET_MONITOR_HISTORY, payload: res.dataList ? res.dataList : []});
    } else {

      yield put({type: SET_MONITOR_HISTORY, payload: []});
    }
  }
}

/**
 * @desc 监测数据列表
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMonitorDataItems(action) {
  if (action.payload) {
    let res = yield call(archive.MonitorItemService.getMonitorDataItemsByObject, action.payload);
    yield put({type: SET_MONITOR_DATA_ITEMS, payload: res.code ? [] : res});
  }
}

/**
 * @desc 监测数据列表
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getMonitorData2Items(action) {
  if (action.payload) {
    let res = yield call(archive.MonitorItemService.getMonitorDataItemsBySecondaryDevice, action.payload);
    yield put({type: SET_MONITOR_DATA_ITEMS, payload: res.code ? [] : res});
  }
}

/**
 * @desc 告警记录
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* getAlertTable(action) {
  if (action.payload) {
    const {startTime, endTime, enterpriseId, siteId, monitorId, current, pageSize, sorter,  ascend} = action.payload;
    let res = yield call(alarm.RecordService.getDataTablePageAnt,'', '', '', siteId, monitorId, '', current, pageSize, sorter,  ascend);
    yield put({type: SET_ALERT_TABLE, payload: res.list ? res.list :[]});
    yield put({type: SET_ALERT_TABLE_PAGINATION, payload: res.pagination});
  }
}
/**
 * @desc 导出台账
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* exportEquipment(action) {
  if (action.payload) {
    let href = yield call(archive.MonitorObjectService.exportDevice,action.payload);
    window.location.href = href;
  }
}

/* 通过id获取节点信息 */
export function* getTreeNodeBaseInfo(action) {
  const equipment = yield select(getEquipment);
  try {
    yield put({type: FETCH_REQUEST});
    let data = yield call(archive.MonitorObjectService.getTreeNodeBaseInfo, action.payload);
    let selectObj = {
            orgId: data.orgId,//点击树拿到的id
            isConsumer: false,
            classifiedType: data.classifiedType,//点击树拿到的类型
            monitorType: data.monitorType || '',//是否为总加组
            ammeterId: data.ammeterId || '',
            dataAmmeterId: data.dataAmmeterId || '',
            monitorObjectTypeId: data.monitorObjectTypeId || '',
            siteId: data.siteId,
    }


    yield put({type: SET_SEL_NODE, payload: selectObj});

  } catch (error) {
    yield put({type: FETCH_FAILED, error: error.message});
  }
}

export const ledgerDetailSagas = [
  takeLatest(GET_MONITOR_DATA_ITEMS, getMonitorDataItems),
  takeLatest(GET_MONITOR_DATA2_ITEMS, getMonitorData2Items),
  takeLatest(GET_MONITOR_HISTORY, getMonitorHistorys),
  takeLatest(GET_ALERT_TABLE, getAlertTable),
  takeLatest(EXPORT_EQUIPMENT, exportEquipment),
  takeLatest(GET_SEL_TREE_NODE_INFO, getTreeNodeBaseInfo),
];

//reducer
export function SDNLedgerDetailReducer(
  state = {
    page: {},
    selNode: null,
    orgId: "",
    loading: true,
    showMonitorModal: false,
    monitorItems: [],
    monitorItem: {},
    monitorHistoryList: [],
    tableLoading: false,
    alertPagination: {
      pageSize: 10,
      current: 1,
      total: 0,
      showSizeChanger: true,
      defaultCurrent: 1,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100']
    },
    alertList: [],
  },
  action
) {
  switch (action.type) {
    case INIT_PAGE: {
      return {
        ...state,
        page: action.payload
      };
    }
    case SET_MONITOR_DATA_ITEMS: {
      return {
        ...state,
        monitorItems: action.payload
      };
    }
    case SET_MONITOR_HISTORY: {
      return {
        ...state,
        monitorHistoryList: action.payload
      };
    }
    case SET_MONITOR_ITEM: {
      return {
        ...state,
        monitorItem: action.payload
      };
    }
    case SHOW_MONITOR_MODAL: {
      return {
        ...state,
        showMonitorModal: action.payload
      };
    }
    case SET_ALERT_TABLE: {
      return {
        ...state,
        alertList: action.payload
      };
    }

    case SET_ALERT_TABLE_PAGINATION: {
      const pagination = { ...state.alertPagination, ...action.payload };
      return {
        ...state,
        alertPagination: pagination
      };
    }
    default:
      return state;
  }
}
