// @create-index

import { postRequestAsyncCall } from "../../../service/SDNRequest";
import getJSON from "./getJSON";
import newid from "./newid";
import { errorBox } from "./message";
import { hgStorage as Storage } from "./myStorage";

export * from "./message";
export * from "./rootContainers";
export * from "./dataProcess";
export * from "./chartConfig";
export * from "./myStorage";

export { getJSON, newid };

export const Utils = {
  //获取电压等级
  getVoltageLevelUnit: function(value) {
    let text = "其他";
    if (value === 1) {
      text = "220V";
    } else if (value === 2) {
      text = "380V";
    } else if (value === 3) {
      text = "6kV";
    } else if (value === 4) {
      text = "10kV";
    } else if (value === 5) {
      text = "35kV及以上";
    }
    return text;
  },

  // 数组中查询值
  getValue: function(arrs, init, key, value, defaultVal) {
    if (defaultVal == undefined || defaultVal == null) defaultVal = "";
    if (key == undefined || key == null || typeof key != "string") key = "key";
    if (value == undefined || value == null || typeof value != "string") value = "value";
    if (init == undefined || init == null) return defaultVal;
    if (arrs == undefined || arrs == null || !Array.isArray(arrs)) return defaultVal;
    var result = defaultVal;
    for (var i = 0; i < arrs.length; i++) {
      if (typeof arrs[i] != "object") break;
      if (arrs[i][key] == init) {
        result = arrs[i][value];
        break;
      }
    }
    return result;
  },
  // datatable处理
  dataTableType: function(data, type, full) {
    if (!(type === "display")) return "";
    else return data;
  },
  // 配置拷贝
  copyData: function(srcData, copyData, isReplace) {
    if (!copyData || typeof copyData != "object") return;
    if (!srcData || typeof srcData != "object") return;
    for (var x in copyData) {
      if (null != copyData[x] && undefined != copyData[x]) {
        srcData[x] = copyData[x];
      } else if (isReplace && typeof isReplace == "boolean" && isReplace) {
        srcData[x] = undefined;
      }
    }
  },
  toJson: function(data) {
    data = Utils.getEmptyDefault(data, {}, "object");
    return Utils.replace(JSON.stringify(data), '"', "'");
  },
  // 获取默认值
  getEmptyDefault: function(empty, defaultVal, type, suffix) {
    if (defaultVal == undefined || defaultVal == null) defaultVal = "";
    if (empty == undefined || empty == null || (typeof empty == "string" && empty == "")) return defaultVal;
    if (Utils.empty(type) || typeof type != "string") {
      if (Utils.notEmptyType(suffix, "string") && Utils.notEmptyType(empty, "string")) return empty + suffix;
      else return empty;
    }
    if (typeof empty == type) {
      if (Utils.notEmptyType(suffix, "string") && Utils.notEmptyType(empty, "string")) return empty + suffix;
      else return empty;
    }
    return defaultVal;
  },
  // 移除对象中的属性
  removeProperty: function(data, property) {
    var removeData = {};
    data = Utils.getEmptyDefault(data, {}, "object");
    property = Utils.getEmptyDefault(property, {}, "object");
    for (var x in data) {
      if (!property.hasOwnProperty(x)) removeData[x] = data[x];
    }
    return removeData;
  },
  // 将json字符串转化为对象
  getObjectByJsonStr: function(jsonStr) {
    if (jsonStr == undefined || null == jsonStr) return {};
    if (typeof jsonStr == "object") return jsonStr;
    if (typeof jsonStr != "string") return {};
    return eval("(" + jsonStr + ")");
  },
  // 时间格式化
  dateFormat: function(time, format) {
    if (time == undefined || time == null || typeof time != "string") return "";
    if (format == null || format == undefined || typeof format != "string") format = "yyyy-MM-dd";
    return new Date(time).Format(format);
  },
  //为空判定
  empty: function(empty) {
    if (empty == undefined || empty == null) return true;
    if (typeof empty == "string" && empty == "") return true;
    if (empty instanceof Object && JSON.stringify(empty) == "{}") return true;
    if (empty instanceof Array && empty.length === 0) return true;
    return false;
  },
  // 不为空判定
  notEmpty: function(empty) {
    return !Utils.empty(empty);
  },
  // 不为空并且是某种类型
  notEmptyType: function(empty, type) {
    if (Utils.empty(empty)) return false;
    if (Utils.empty(type) || typeof empty != "string") return true;
    if (typeof empty == type) return true;
    return false;
  },
  notEmptyAndArray: function(array) {
    if (Utils.notEmptyType(array, "object") && Array.isArray(array) && array.length > 0) return true;
    return false;
  },
  replace: function(replace, oldStr, newStr, rule) {
    if (!Utils.notEmptyType(replace, "string")) return replace;
    if (Utils.empty(oldStr)) return replace;
    newStr = Utils.getEmptyDefault(newStr, "", "string");
    rule = Utils.getEmptyDefault(rule, "g", "string");
    var reg = new RegExp(oldStr, rule);
    return replace.replace(reg, newStr);
  },
  getWeek: function(date) {
    var weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    return weeks[date.getDay()];
  },
  /**
   * 精确到小数第几位
   * @param num		需要精确的数字
   * @param digit		精确的位数
   */
  exactDecimal: function(num, digit) {
    if (num == null || num == undefined) {
      return 0;
    }

    if (digit == null || digit == undefined) {
      return num;
    }

    var digitV = Math.pow(10, digit);

    return Math.round(num * digitV) / digitV;
  },
  /**
   * JS 精确乘法运算
   * @param arg1
   * @param arg2
   */
  accMul: function(arg1, arg2) {
    var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();
    try {
      m += s1.split(".")[1].length;
    } catch (e) {}
    try {
      m += s2.split(".")[1].length;
    } catch (e) {}
    return (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m);
  },
  /**
   * JS精确的加法运算
   * @param arg1
   * @param arg2
   */
  accAdd: function(arg1, arg2) {
    var r1, r2, m;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (arg1 * m + arg2 * m) / m;
  },
  /**
   * JS精确的除法运算
   * @param arg1
   * @param arg2
   */
  accDiv: function(arg1, arg2) {
    if (arg1 == undefined) {
      return 0;
    }
    var t1 = 0,
      t2 = 0,
      r1,
      r2;
    try {
      t1 = arg1.toString().split(".")[1].length;
    } catch (e) {}
    try {
      t2 = arg2.toString().split(".")[1].length;
    } catch (e) {}

    r1 = Number(arg1.toString().replace(".", ""));
    r2 = Number(arg2.toString().replace(".", ""));
    return (r1 / r2) * Math.pow(10, t2 - t1);
  },
  /**
   * 查询 html
   * 当 txt为空时返回不可查询的字段
   * @param txt 描述内容
   * @param type 返回的查询类型
   * date 日期类型； text 输入文字类型；
   */
  queryHtml: function(txt, type) {
    if (Utils.empty(txt)) {
      return '<th class="hasinput"></th>';
    }

    var queryHtml = "";
    if ("date" == type) {
      queryHtml = '<th class="hasinput" filter-type="date-range">';
      queryHtml += '<div class="row"><div class="col-sm-6">';
      queryHtml += '<input class="dateselect-filter-from form-control datepicker" ';
      queryHtml += 'type="text" placeholder="From" data-dateformat="yy-mm-dd" readonly>';
      queryHtml += '</div><div class="col-sm-6">';
      queryHtml += '<input class="dateselect-filter-to form-control datepicker" ';
      queryHtml += 'type="text" placeholder="To" data-dateformat="yy-mm-dd" readonly>';
      queryHtml += "</div></div></th>";
      return queryHtml;
    }

    if ("text" == type) {
      queryHtml = '<th class="hasinput" filter-type="text">';
      queryHtml += '<input type="text" class="form-control" placeholder="';
      queryHtml += txt;
      queryHtml += '"/></th>';
      return queryHtml;
    }
  },
  /**
   * 列名html
   * @param columnName    列名
   */
  columnHtml: function(columnName) {
    if (Utils.empty(columnName)) return "";
    var columnHtml = "<th>";
    columnHtml += columnName;
    columnHtml += "</th>";
    return columnHtml;
  },
  /**
   * 列对应的JS
   * @param columnCode    列英文名称
   * @parm type           列类型
   * @parm hide           是否隐藏， true ： 是； false : 否；
   */
  columnJs: function(columnCode, type, hide) {
    if (Utils.empty(columnCode)) return "";
    var columnJs = {
      name: columnCode,
    };

    if (Utils.notEmpty(type)) {
      columnJs.type = type;
    }

    if (Utils.notEmpty(hide)) {
      columnJs.hide = hide;
    }
    return columnJs;
  },
  /**
   * 判断是否是一个数字
   * 包括 正整数、负整数、正浮点数、负浮点数
   * @param num              需要判断的数字或字符串
   * @param digit            限制小数的位数
   * @returns {boolean}    true ： 是数字； false : 不是数字
   */
  isNumber: function(num, digit) {
    var regP = new RegExp("^[+-]?\\d+(?:\\.\\d{1," + digit + "})?$");
    if (regP.test(num)) {
      return true;
    } else {
      return false;
    }
  },
  /**
   * 清空数组
   * @param arrayDatas 将要清空的数组
   */
  clearArray: function(arrayDatas) {
    if (Utils.notEmpty(arrayDatas) && arrayDatas.length > 0) {
      arrayDatas.splice(0, arrayDatas.length);
    }
  },
  // 空对象的判定
  isEmptyObj: function(obj) {
    for (var key in obj) {
      return false;
    }

    return true;
  },
  // 不为空对象的判定
  isNotEmptyObj: function(obj) {
    return !Utils.isEmptyObj(obj);
  },
  /**
   * 判断数组为空
   * @param arrayDatas    要判断的数组
   * @returns {boolean}   true : 为空； false : 不为空
   */
  arrayEmpty: function(arrayDatas) {
    if (Utils.notEmpty(arrayDatas) && arrayDatas.length > 0) return false;
    return true;
  },
  /**
   * 判断数组不为空
   * @param arrayDatas
   * @returns {boolean}   true : 不为空； false ：为空
   */
  arrayNotEmpty: function(arrayDatas) {
    return !Utils.arrayEmpty(arrayDatas);
  },
  /** 数值小数位格式化 type: "round"、"floor"、"ceil" */
  NFixed: function(val, len = 2, strict = false, type = "round") {
    const fill0 = N =>
      Array(N)
        .fill(0)
        .join("");
    const valString = String(val);
    // 值为普通数值格式，不包括非二进制数、科学计数
    if (!/^[+-]?\d+([.]{1}\d+)?$/.test(valString)) return "";

    const n = valString.indexOf(".");
    // 无小数点的情况：补0 或 直接返回
    if (n < 0) return strict ? valString + "." + fill0(len) : valString;

    // 小数点在中间
    const [left, right] = valString.split(".");
    // 小数位长度不足的情况：补0 或 直接返回
    if (right.length < len) return strict ? valString + fill0(len - right.length) : valString;
    // 小数位长度刚好的情况：直接返回
    if (right.length === len) return valString;
    // 小数位长度过多的情况：按参数截断多余小数位
    let newRight = String(Math[type](right.slice(0, len) + "." + right.slice(len)));
    // 可能出现小数位长度不足的情况：补0
    newRight = newRight.length < len ? fill0(len - newRight.length) + newRight : newRight;
    // 返回处理后的字符串值
    return left + "." + newRight;
  },
};

export const Timers = {
  intervals: {},
  timeouts: {},
  systemTime: {},
  verify: function(timeName, timeFun, timeLong) {
    if (!Utils.notEmptyType(timeName, "string")) return false;
    if (!Utils.notEmptyType(timeFun, "function")) return false;
    if (!Utils.notEmptyType(timeLong, "number")) return false;
    return true;
  },
  addInterval: function(timeName, timeFun, timeLong, callback) {
    if (!Timers.verify(timeName, timeFun, timeLong, callback)) return;
    Timers.intervals[timeName] = window.setInterval(function() {
      timeFun();
    }, timeLong);
    return Timers.intervals[timeName];
  },
  addTimeout: function(timeName, timeFun, timeLong, callback) {
    if (!Timers.verify(timeName, timeFun, timeLong, callback)) return;
    Timers.timeouts[timeName] = window.setTimeout(function() {
      timeFun();
    }, timeLong);
    return Timers.timeouts[timeName];
  },
  addSystemTime: function(timeName, timeFun, timeLong, isTimeout) {
    if (!Timers.verify(timeName, timeFun, timeLong)) return;
    if (Utils.notEmpty(Timers.systemTime[timeName])) return;
    isTimeout = Utils.getEmptyDefault(isTimeout, false, "boolean");
    if (isTimeout) {
      Timers.systemTime[timeName] = window.setTimeout(function() {
        timeFun();
      }, timeLong);
    } else {
      Timers.systemTime[timeName] = window.setInterval(function() {
        timeFun();
      }, timeLong);
    }
    return Timers.systemTime[timeName];
  },
  clearInterval: function(timeName) {
    if (Utils.empty(timeName)) return;
    if (typeof timeName != "string") return;
    for (var x in Timers.intervals) {
      if (!Utils.empty(x) && x == timeName) {
        window.clearInterval(Timers.intervals[x]);
        break;
      }
    }
  },
  clearTimeout: function(timeName) {
    if (Utils.empty(timeName)) return;
    if (typeof timeName != "string") return;
    for (var x in Timers.timeouts) {
      if (!Utils.empty(x) && x == timeName) {
        window.clearInterval(Timers.timeouts[x]);
        break;
      }
    }
  },
  clear: function() {
    for (var x in Timers.intervals) {
      if (!Utils.empty(x)) {
        window.clearInterval(Timers.intervals[x]);
      }
    }
    Timers.intervals = {};
    for (var x in Timers.timeouts) {
      if (!Utils.empty(x)) {
        window.clearInterval(Timers.timeouts[x]);
      }
    }
    Timers.timeouts = {};
  },
};

const isHttps = window.location.protocol === "https:";
export const mqttService = {
  //以下四项配置来自接口：/hxy/api/sys/mqtt/getConfig
  host: null, //接入点域名，在控制台查看
  topic: null, //父级topic，在控制台查看
  accessKey: null, //账号AccessKey，在控制台查看
  instanceId: null, //实例ID，在控制台查看

  port: isHttps ? 443 : 8083, //WebSocket协议服务端口
  useTLS: isHttps ? true : false, //是否加密 HTTPS
  cleansession: true, //断开后是否终止session
  reconnectTimeout: 2000, //断线重新获取间隔时间
  MQTTconnect: function(callback) {
    if (!mqttService.host || !mqttService.topic) {
      errorBox("Mqtt 获取服务器参数失败");
      console.log("Mqtt 获取服务器参数失败");
      return;
    }
    if (!mqttService.token || !mqttService.topics || !mqttService.topics[0]) {
      console.log("Mqtt token 或 topics 参数为空");
      return;
    }

    //参考文档 https://help.aliyun.com/document_detail/48271.html?spm=a2c4g.11186623.6.553.217831c3BSFry7
    const clientId = new Date().getTime().toString() + "@@@00001";
    mqttService.mqtt = new window.Paho.MQTT.Client(mqttService.host, mqttService.port, clientId);
    mqttService.mqtt.onConnectionLost = message => {
      console.error("onConnectionLost:", message);
      if (callback) setTimeout(() => mqttService.MQTTconnect(callback), mqttService.reconnectTimeout);
    };
    mqttService.mqtt.onMessageArrived = message => {
      try {
        if (callback) callback(message.payloadString, 1);
      } catch (err) {
        console.error(err);
      }
    };

    const options = {
      timeout: 3,
      mqttVersion: 4,
      useSSL: mqttService.useTLS,
      password: mqttService.token,
      userName: "Token|" + mqttService.accessKey + "|" + mqttService.instanceId,
      onSuccess: () => {
        mqttService.topics.forEach(topic => mqttService.mqtt.subscribe(topic, { qos: 0 }));
        try {
          if (callback) callback(null, 0);
        } catch (err) {
          console.error(err);
        }
      },
      onFailure: message => {
        console.error("onFailure:", message);
        if (callback) setTimeout(() => mqttService.MQTTconnect(callback), mqttService.reconnectTimeout);
      },
    };
    mqttService.mqtt.connect(options);
  },
};

export const UserInfo = {
  storage: "localuserinfostorage",
  get() {
    try {
      return JSON.parse(decodeURIComponent(atob(Storage.get(UserInfo.storage) || "")));
    } catch (error) {
      return {};
    }
  },
  set({ username, password, remember }) {
    let info = {};
    try {
      if (remember === true && username && password) info = { username, password, remember };
      Storage.set(UserInfo.storage, btoa(encodeURIComponent(JSON.stringify(info))));
    } catch (error) {
      Storage.set(UserInfo.storage, btoa(encodeURIComponent("{}")));
    }
  },
};
