import { BaseService } from '../SDNRequest';

const eprice = {
	    //用户峰谷电价	    
        OrgPeakValleyService :{
			
			//增加用户峰谷电价对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/orgPeakValley/add',mapParams);
			},
			
			//删除用户峰谷电价对象
			del : function(id) {
				return BaseService.post('/api/eprice/orgPeakValley/del',{
						'id':id
					});
			},
			
			//修改用户峰谷电价对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/orgPeakValley/edit',mapParams);
			},
			
			//修改用户峰谷电价对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/orgPeakValley/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出用户峰谷电价对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/orgPeakValley/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取用户峰谷电价对象
			get : function(id) {
				return BaseService.get('/api/eprice/orgPeakValley/get',{
						'id':id
					});
			},
			
			//查询所有用户峰谷电价对象
			getAll : function() {
				return BaseService.get('/api/eprice/orgPeakValley/getall');
			},
			
			//DataTable查询用户峰谷电价对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/orgPeakValley/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示用户峰谷电价对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/orgPeakValley/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //广东执行尖峰日期	    
        GdPeaktimeService :{
			
			//增加广东执行尖峰日期对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/gdPeaktime/add',mapParams);
			},
			
			//删除广东执行尖峰日期对象
			del : function(id) {
				return BaseService.post('/api/eprice/gdPeaktime/del',{
						'id':id
					});
			},
			
			//修改广东执行尖峰日期对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/gdPeaktime/edit',mapParams);
			},
			
			//修改广东执行尖峰日期对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/gdPeaktime/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出广东执行尖峰日期对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/gdPeaktime/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取广东执行尖峰日期对象
			get : function(id) {
				return BaseService.get('/api/eprice/gdPeaktime/get',{
						'id':id
					});
			},
			
			//查询所有广东执行尖峰日期对象
			getAll : function() {
				return BaseService.get('/api/eprice/gdPeaktime/getall');
			},
			
			//DataTable查询广东执行尖峰日期对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/gdPeaktime/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示广东执行尖峰日期对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/gdPeaktime/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //收益成本	    
        RevenueCostService :{
			
			//增加收益成本对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/revenueCost/add',mapParams);
			},
			
			//删除收益成本对象
			del : function(id) {
				return BaseService.post('/api/eprice/revenueCost/del',{
						'id':id
					});
			},
			
			//修改收益成本对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/revenueCost/edit',mapParams);
			},
			
			//导出数据
			exportDatas : function(dataTable, consumerId, siteId, fromDt, toDt) {
				return BaseService.download('/api/eprice/revenueCost/exportDatas',{
						'dataTable':dataTable, 'consumerId':consumerId, 'siteId':siteId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//导出模板
			exportTemplate : function() {
				return BaseService.download('/api/eprice/revenueCost/exportTemplate');
			},
			
			//根据ID获取收益成本对象
			get : function(id) {
				return BaseService.get('/api/eprice/revenueCost/get',{
						'id':id
					});
			},
			
			//DataTable查询收益成本对象
			getDataTable : function(dataTable, consumerId, siteId, fromDt, toDt) {
				return BaseService.get('/api/eprice/revenueCost/getdatatable',{
						'dataTable':dataTable, 'consumerId':consumerId, 'siteId':siteId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//收益成本可视化数据（饼图）
			getRevenueCostCharData : function(siteId, fromDt, toDt) {
				return BaseService.get('/api/eprice/revenueCost/getRevenueCostCharData',{
						'siteId':siteId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//事件记录（柱状图）
			getRevenueCostEventCharData : function(siteId, fromDt, toDt) {
				return BaseService.get('/api/eprice/revenueCost/getRevenueCostEventCharData',{
						'siteId':siteId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//获取类别和事项类型
			getRevenueCostType : function() {
				return BaseService.get('/api/eprice/revenueCost/getRevenueCostType');
			},
			
			//导入文件
			importFile : function(upFile) {
				return BaseService.upload('/api/eprice/revenueCost/importFile',{
						'upFile':upFile
					});
			},
		},
	    //时段定义	    
        TimeDefService :{
			
			//导出
			export : function(timePlanId) {
				return BaseService.download('/api/eprice/timeDef/export',{
						'timePlanId':timePlanId
					});
			},
			
			//导出模板
			exportTemplate : function() {
				return BaseService.download('/api/eprice/timeDef/exportTemplate');
			},
			
			//根据ID获取时段定义对象
			getTimeDefsByTimePlanId : function(timePlanId) {
				return BaseService.get('/api/eprice/timeDef/getTimeDefsByTimePlanId',{
						'timePlanId':timePlanId
					});
			},
			
			//导入文件
			importFile : function(upFile, timePlanId) {
				return BaseService.upload('/api/eprice/timeDef/importFile',{
						'upFile':upFile, 'timePlanId':timePlanId
					});
			},
		},
	    //时段方案	    
        TimePlanService :{
			
			//增加时段方案对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/timePlan/add',mapParams);
			},
			
			//删除时段方案对象
			del : function(id) {
				return BaseService.post('/api/eprice/timePlan/del',{
						'id':id
					});
			},
			
			//修改时段方案对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/timePlan/edit',mapParams);
			},
			
			//根据ID获取时段方案对象
			get : function(id) {
				return BaseService.get('/api/eprice/timePlan/get',{
						'id':id
					});
			},
			
			//查询所有时段方案对象
			getAll : function() {
				return BaseService.get('/api/eprice/timePlan/getall');
			},
			
			//DataTable查询时段方案对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/timePlan/getdatatable',{
						'dataTable':dataTable
					});
			},
		},
	    //广东售电交易参数	    
        GdsdTransparamsService :{
			
			//增加广东售电交易参数对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/gdsdTransparams/add',mapParams);
			},
			
			//删除广东售电交易参数对象
			del : function(id) {
				return BaseService.post('/api/eprice/gdsdTransparams/del',{
						'id':id
					});
			},
			
			//修改广东售电交易参数对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/gdsdTransparams/edit',mapParams);
			},
			
			//根据ID获取广东售电交易参数对象
			get : function(id) {
				return BaseService.get('/api/eprice/gdsdTransparams/get',{
						'id':id
					});
			},
			
			//DataTable查询广东售电交易参数对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/gdsdTransparams/getdatatable',{
						'dataTable':dataTable
					});
			},
		},
	    //用户电价明细	    
        OrgEpriceDetailService :{
			
			//增加用户电价明细对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/orgEpriceDetail/add',mapParams);
			},
			
			//删除用户电价明细对象
			del : function(id) {
				return BaseService.post('/api/eprice/orgEpriceDetail/del',{
						'id':id
					});
			},
			
			//修改用户电价明细对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/orgEpriceDetail/edit',mapParams);
			},
			
			//修改用户电价明细对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/orgEpriceDetail/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出用户电价明细对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/orgEpriceDetail/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取用户电价明细对象
			get : function(id) {
				return BaseService.get('/api/eprice/orgEpriceDetail/get',{
						'id':id
					});
			},
			
			//查询所有用户电价明细对象
			getAll : function() {
				return BaseService.get('/api/eprice/orgEpriceDetail/getall');
			},
			
			//DataTable查询用户电价明细对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/orgEpriceDetail/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示用户电价明细对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/orgEpriceDetail/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //广东售电合同参数	    
        GdsdContractparamsService :{
			
			//增加广东售电合同参数对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/gdsdContractparams/add',mapParams);
			},
			
			//删除广东售电合同参数对象
			del : function(id) {
				return BaseService.post('/api/eprice/gdsdContractparams/del',{
						'id':id
					});
			},
			
			//修改广东售电合同参数对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/gdsdContractparams/edit',mapParams);
			},
			
			//修改广东售电合同参数对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/gdsdContractparams/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出广东售电合同参数对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/gdsdContractparams/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取广东售电合同参数对象
			get : function(id) {
				return BaseService.get('/api/eprice/gdsdContractparams/get',{
						'id':id
					});
			},
			
			//查询所有广东售电合同参数对象
			getAll : function() {
				return BaseService.get('/api/eprice/gdsdContractparams/getall');
			},
			
			//DataTable查询广东售电合同参数对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/gdsdContractparams/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示广东售电合同参数对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/gdsdContractparams/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //用户电价	    
        OrgEpriceService :{
			
			//增加用户电价对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/orgEprice/add',mapParams);
			},
			
			//删除用户电价对象
			del : function(id) {
				return BaseService.post('/api/eprice/orgEprice/del',{
						'id':id
					});
			},
			
			//修改用户电价对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/orgEprice/edit',mapParams);
			},
			
			//修改用户电价对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/orgEprice/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//重新计算
			editRecalculation : function(orgId, orgEpriceIds) {
				return BaseService.get('/api/eprice/orgEprice/editRecalculation',{
						'orgId':orgId, 'orgEpriceIds':orgEpriceIds
					});
			},
			
			//通过DataTable导出用户电价对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/orgEprice/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//导出电价曲线模板
			exportEpriceTemplate : function() {
				return BaseService.download('/api/eprice/orgEprice/exportEpriceTemplate');
			},
			
			//根据ID获取用户电价对象
			get : function(id) {
				return BaseService.get('/api/eprice/orgEprice/get',{
						'id':id
					});
			},
			
			//查询所有用户电价对象
			getAll : function() {
				return BaseService.get('/api/eprice/orgEprice/getall');
			},
			
			//DataTable查询用户电价对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/orgEprice/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//一天电价曲线
			getOneDayPrice : function(orgEpriceIds) {
				return BaseService.get('/api/eprice/orgEprice/getOneDayPrice',{
						'orgEpriceIds':orgEpriceIds
					});
			},
			
			//分页显示用户电价对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/orgEprice/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入电价曲线
			importOrgEprice : function(upFile, orgContractId) {
				return BaseService.upload('/api/eprice/orgEprice/importOrgEprice',{
						'upFile':upFile, 'orgContractId':orgContractId
					});
			},
		},
	    //电价方案配置	    
        PlanService :{
			
			//增加电价方案配置对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/plan/add',mapParams);
			},
			
			//删除电价方案配置对象
			del : function(id) {
				return BaseService.post('/api/eprice/plan/del',{
						'id':id
					});
			},
			
			//修改电价方案配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/plan/edit',mapParams);
			},
			
			//修改电价方案配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/plan/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出电价方案配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/plan/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取电价方案配置对象
			get : function(id) {
				return BaseService.get('/api/eprice/plan/get',{
						'id':id
					});
			},
			
			//查询所有电价方案配置对象
			getAll : function() {
				return BaseService.get('/api/eprice/plan/getall');
			},
			
			//DataTable查询电价方案配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/plan/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示电价方案配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/plan/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //辅助服务费用和市场分摊费用	    
        AuxiFeeService :{
			
			//增加辅助服务费用和市场分摊费用对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/auxiFee/add',mapParams);
			},
			
			//删除辅助服务费用和市场分摊费用对象
			del : function(id) {
				return BaseService.post('/api/eprice/auxiFee/del',{
						'id':id
					});
			},
			
			//修改辅助服务费用和市场分摊费用对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/auxiFee/edit',mapParams);
			},
			
			//修改辅助服务费用和市场分摊费用对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/auxiFee/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出辅助服务费用和市场分摊费用对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/auxiFee/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取辅助服务费用和市场分摊费用对象
			get : function(id) {
				return BaseService.get('/api/eprice/auxiFee/get',{
						'id':id
					});
			},
			
			//查询所有辅助服务费用和市场分摊费用对象
			getAll : function() {
				return BaseService.get('/api/eprice/auxiFee/getall');
			},
			
			//DataTable查询辅助服务费用和市场分摊费用对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/auxiFee/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示辅助服务费用和市场分摊费用对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/auxiFee/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //峰谷电价	    
        PeakValleyService :{
			
			//增加峰谷电价对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/peakValley/add',mapParams);
			},
			
			//删除峰谷电价对象
			del : function(id) {
				return BaseService.post('/api/eprice/peakValley/del',{
						'id':id
					});
			},
			
			//修改峰谷电价对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/peakValley/edit',mapParams);
			},
			
			//修改峰谷电价对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/peakValley/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出峰谷电价对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/peakValley/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取峰谷电价对象
			get : function(id) {
				return BaseService.get('/api/eprice/peakValley/get',{
						'id':id
					});
			},
			
			//查询所有峰谷电价对象
			getAll : function() {
				return BaseService.get('/api/eprice/peakValley/getall');
			},
			
			//DataTable查询峰谷电价对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/peakValley/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示峰谷电价对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/peakValley/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //其它费用	    
        ExtraFeeService :{
			
			//增加其它费用对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/extraFee/add',mapParams);
			},
			
			//删除其它费用对象
			del : function(id) {
				return BaseService.post('/api/eprice/extraFee/del',{
						'id':id
					});
			},
			
			//修改其它费用对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/extraFee/edit',mapParams);
			},
			
			//修改其它费用对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/extraFee/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出其它费用对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/extraFee/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取其它费用对象
			get : function(id) {
				return BaseService.get('/api/eprice/extraFee/get',{
						'id':id
					});
			},
			
			//查询所有其它费用对象
			getAll : function() {
				return BaseService.get('/api/eprice/extraFee/getall');
			},
			
			//DataTable查询其它费用对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/extraFee/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示其它费用对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/extraFee/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //用户结算协议	    
        OrgContractService :{
			
			//增加用户结算协议对象
			add : function(mapParams) {
						return BaseService.post('/api/eprice/orgContract/add',mapParams);
			},
			
			//增加用户结算协议对象
			addOrgContract : function(orgId, planId, planType, contractCode, measureAccount, beginDt, endDt, rem) {
				return BaseService.post('/api/eprice/orgContract/addOrgContract',{
						'orgId':orgId, 'planId':planId, 'planType':planType, 'contractCode':contractCode, 'measureAccount':measureAccount, 'beginDt':beginDt, 'endDt':endDt, 'rem':rem
					});
			},
			
			//删除用户结算协议对象
			del : function(id) {
				return BaseService.post('/api/eprice/orgContract/del',{
						'id':id
					});
			},
			
			//修改用户结算协议对象
			edit : function(mapParams) {
						return BaseService.post('/api/eprice/orgContract/edit',mapParams);
			},
			
			//修改用户结算协议对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/eprice/orgContract/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//增加用户结算协议对象
			editOrgContract : function(orgContractId, orgId, planId, planType, contractCode, measureAccount, beginDt, endDt, rem) {
				return BaseService.post('/api/eprice/orgContract/editOrgContract',{
						'orgContractId':orgContractId, 'orgId':orgId, 'planId':planId, 'planType':planType, 'contractCode':contractCode, 'measureAccount':measureAccount, 'beginDt':beginDt, 'endDt':endDt, 'rem':rem
					});
			},
			
			//通过DataTable导出用户结算协议对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/eprice/orgContract/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取用户结算协议对象
			get : function(id) {
				return BaseService.get('/api/eprice/orgContract/get',{
						'id':id
					});
			},
			
			//查询所有用户结算协议对象
			getAll : function() {
				return BaseService.get('/api/eprice/orgContract/getall');
			},
			
			//DataTable查询用户结算协议对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/eprice/orgContract/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示用户结算协议对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/eprice/orgContract/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
 
}

export default eprice;