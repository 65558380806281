import { BaseService } from '../SDNRequest';

const yunshui = {
	    //yunshui_energy_manager	    
        EnergyManagerService :{
			
			//增加yunshui_energy_manager对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/energyManager/add',mapParams);
			},
			
			//删除yunshui_energy_manager对象
			del : function(id) {
				return BaseService.post('/api/yunshui/energyManager/del',{
						'id':id
					});
			},
			
			//修改yunshui_energy_manager对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/energyManager/edit',mapParams);
			},
			
			//修改yunshui_energy_manager对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/energyManager/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_energy_manager对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/energyManager/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_energy_manager对象
			get : function(id) {
				return BaseService.get('/api/yunshui/energyManager/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_energy_manager对象
			getAll : function() {
				return BaseService.get('/api/yunshui/energyManager/getall');
			},
			
			//DataTable查询yunshui_energy_manager对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/energyManager/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_energy_manager对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/energyManager/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/energyManager/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_data_collect_configure	    
        DataCollectConfigureService :{
			
			//增加yunshui_data_collect_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/dataCollectConfigure/add',mapParams);
			},
			
			//删除yunshui_data_collect_configure对象
			del : function(id) {
				return BaseService.post('/api/yunshui/dataCollectConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_data_collect_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/dataCollectConfigure/edit',mapParams);
			},
			
			//修改yunshui_data_collect_configure对象的字段
			editConvertRation : function(id, value) {
				return BaseService.post('/api/yunshui/dataCollectConfigure/editConvertRation',{
						'id':id, 'value':value
					});
			},
			
			//修改yunshui_data_collect_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/dataCollectConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_data_collect_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/dataCollectConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_data_collect_configure对象
			get : function(id) {
				return BaseService.get('/api/yunshui/dataCollectConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_data_collect_configure对象
			getAll : function() {
				return BaseService.get('/api/yunshui/dataCollectConfigure/getall');
			},
			
			//获取上传数据项信息
			getDataCollectList : function(orgId, statType) {
				return BaseService.get('/api/yunshui/dataCollectConfigure/getDataCollectList',{
						'orgId':orgId, 'statType':statType
					});
			},
			
			//DataTable查询yunshui_data_collect_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/dataCollectConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_data_collect_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/dataCollectConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/dataCollectConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_energy_monitor	    
        EnergyMonitorService :{
			
			//增加yunshui_energy_monitor对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/energyMonitor/add',mapParams);
			},
			
			//删除yunshui_energy_monitor对象
			del : function(id) {
				return BaseService.post('/api/yunshui/energyMonitor/del',{
						'id':id
					});
			},
			
			//修改yunshui_energy_monitor对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/energyMonitor/edit',mapParams);
			},
			
			//修改yunshui_energy_monitor对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/energyMonitor/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_energy_monitor对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/energyMonitor/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_energy_monitor对象
			get : function(id) {
				return BaseService.get('/api/yunshui/energyMonitor/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_energy_monitor对象
			getAll : function() {
				return BaseService.get('/api/yunshui/energyMonitor/getall');
			},
			
			//DataTable查询yunshui_energy_monitor对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/energyMonitor/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_energy_monitor对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/energyMonitor/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/energyMonitor/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_gauge_configure	    
        GaugeConfigureService :{
			
			//增加yunshui_gauge_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/gaugeConfigure/add',mapParams);
			},
			
			//删除yunshui_gauge_configure对象
			del : function(id) {
				return BaseService.post('/api/yunshui/gaugeConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_gauge_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/gaugeConfigure/edit',mapParams);
			},
			
			//修改yunshui_gauge_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/gaugeConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_gauge_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/gaugeConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_gauge_configure对象
			get : function(id) {
				return BaseService.get('/api/yunshui/gaugeConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_gauge_configure对象
			getAll : function() {
				return BaseService.get('/api/yunshui/gaugeConfigure/getall');
			},
			
			//DataTable查询yunshui_gauge_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/gaugeConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_gauge_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/gaugeConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/gaugeConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_gauge_verify_configure	    
        GaugeVerifyConfigureService :{
			
			//增加yunshui_gauge_verify_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/gaugeVerifyConfigure/add',mapParams);
			},
			
			//删除yunshui_gauge_verify_configure对象
			del : function(id) {
				return BaseService.post('/api/yunshui/gaugeVerifyConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_gauge_verify_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/gaugeVerifyConfigure/edit',mapParams);
			},
			
			//修改yunshui_gauge_verify_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/gaugeVerifyConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_gauge_verify_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/gaugeVerifyConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_gauge_verify_configure对象
			get : function(id) {
				return BaseService.get('/api/yunshui/gaugeVerifyConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_gauge_verify_configure对象
			getAll : function() {
				return BaseService.get('/api/yunshui/gaugeVerifyConfigure/getall');
			},
			
			//DataTable查询yunshui_gauge_verify_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/gaugeVerifyConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_gauge_verify_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/gaugeVerifyConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/gaugeVerifyConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_process_configure	    
        ProcessConfigureService :{
			
			//增加yunshui_process_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/processConfigure/add',mapParams);
			},
			
			//删除yunshui_process_configure对象
			del : function(id) {
				return BaseService.post('/api/yunshui/processConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_process_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/processConfigure/edit',mapParams);
			},
			
			//修改yunshui_process_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/processConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_process_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/processConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_process_configure对象
			get : function(id) {
				return BaseService.get('/api/yunshui/processConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_process_configure对象
			getAll : function() {
				return BaseService.get('/api/yunshui/processConfigure/getall');
			},
			
			//DataTable查询yunshui_process_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/processConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_process_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/processConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/processConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_contacter	    
        ContacterService :{
			
			//增加yunshui_contacter对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/contacter/add',mapParams);
			},
			
			//删除yunshui_contacter对象
			del : function(id) {
				return BaseService.post('/api/yunshui/contacter/del',{
						'id':id
					});
			},
			
			//修改yunshui_contacter对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/contacter/edit',mapParams);
			},
			
			//修改yunshui_contacter对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/contacter/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_contacter对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/contacter/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_contacter对象
			get : function(id) {
				return BaseService.get('/api/yunshui/contacter/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_contacter对象
			getAll : function() {
				return BaseService.get('/api/yunshui/contacter/getall');
			},
			
			//DataTable查询yunshui_contacter对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/contacter/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_contacter对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/contacter/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/contacter/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_device_configure	    
        DeviceConfigureService :{
			
			//增加yunshui_device_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/deviceConfigure/add',mapParams);
			},
			
			//删除yunshui_device_configure对象
			del : function(id) {
				return BaseService.post('/api/yunshui/deviceConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_device_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/deviceConfigure/edit',mapParams);
			},
			
			//修改yunshui_device_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/deviceConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_device_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/deviceConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_device_configure对象
			get : function(id) {
				return BaseService.get('/api/yunshui/deviceConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_device_configure对象
			getAll : function() {
				return BaseService.get('/api/yunshui/deviceConfigure/getall');
			},
			
			//DataTable查询yunshui_device_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/deviceConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_device_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/deviceConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/deviceConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_energy_account	    
        EnergyAccountService :{
			
			//增加yunshui_energy_account对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/energyAccount/add',mapParams);
			},
			
			//删除yunshui_energy_account对象
			del : function(id) {
				return BaseService.post('/api/yunshui/energyAccount/del',{
						'id':id
					});
			},
			
			//修改yunshui_energy_account对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/energyAccount/edit',mapParams);
			},
			
			//修改yunshui_energy_account对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/energyAccount/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_energy_account对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/energyAccount/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_energy_account对象
			get : function(id) {
				return BaseService.get('/api/yunshui/energyAccount/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_energy_account对象
			getAll : function() {
				return BaseService.get('/api/yunshui/energyAccount/getall');
			},
			
			//DataTable查询yunshui_energy_account对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/energyAccount/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_energy_account对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/energyAccount/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/energyAccount/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_upload_record	    
        UploadRecordService :{
			
			//增加yunshui_upload_record对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/uploadRecord/add',mapParams);
			},
			
			//数据提交
			dataUpdate : function(orgId, dataCollectConfigureIds, year, month) {
				return BaseService.post('/api/yunshui/uploadRecord/dataUpdate',{
						'orgId':orgId, 'dataCollectConfigureIds':dataCollectConfigureIds, 'year':year, 'month':month
					});
			},
			
			//查看数据上传进度
			dataUpdateProcess : function(key) {
				return BaseService.get('/api/yunshui/uploadRecord/dataUpdateProcess',{
						'key':key
					});
			},
			
			//停止数据上传
			dataUpdateStop : function(key) {
				return BaseService.get('/api/yunshui/uploadRecord/dataUpdateStop',{
						'key':key
					});
			},
			
			//删除yunshui_upload_record对象
			del : function(id) {
				return BaseService.post('/api/yunshui/uploadRecord/del',{
						'id':id
					});
			},
			
			//修改yunshui_upload_record对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/uploadRecord/edit',mapParams);
			},
			
			//修改yunshui_upload_record对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/uploadRecord/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_upload_record对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/uploadRecord/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_upload_record对象
			get : function(id) {
				return BaseService.get('/api/yunshui/uploadRecord/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_upload_record对象
			getAll : function() {
				return BaseService.get('/api/yunshui/uploadRecord/getall');
			},
			
			//获取折标系数
			getConvertRation : function(orgId, date) {
				return BaseService.get('/api/yunshui/uploadRecord/getConvertRation',{
						'orgId':orgId, 'date':date
					});
			},
			
			//DataTable查询yunshui_upload_record对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/uploadRecord/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_upload_record对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/uploadRecord/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/uploadRecord/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
			
			//手动删除历史上传记录
			manualDelHisRec : function(dt1, dt2) {
				return BaseService.get('/api/yunshui/uploadRecord/manualDelHisRec',{
						'dt1':dt1, 'dt2':dt2
					});
			},
		},
	    //yunshui_company_planinfo	    
        CompanyPlaninfoService :{
			
			//增加yunshui_company_planinfo对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/companyPlaninfo/add',mapParams);
			},
			
			//提交审查信息
			commitcheckitems : function(checkPlanId, checkItems, startTime, endTime) {
				return BaseService.post('/api/yunshui/companyPlaninfo/commitcheckitems',{
						'checkPlanId':checkPlanId, 'checkItems':checkItems, 'startTime':startTime, 'endTime':endTime
					});
			},
			
			//删除yunshui_company_planinfo对象
			del : function(id) {
				return BaseService.post('/api/yunshui/companyPlaninfo/del',{
						'id':id
					});
			},
			
			//修改yunshui_company_planinfo对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/companyPlaninfo/edit',mapParams);
			},
			
			//修改yunshui_company_planinfo对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/companyPlaninfo/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取yunshui_company_planinfo对象
			get : function(id) {
				return BaseService.get('/api/yunshui/companyPlaninfo/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_company_planinfo对象
			getAll : function() {
				return BaseService.get('/api/yunshui/companyPlaninfo/getall');
			},
			
			//通过orgId查询本地审查计划列表
			getCompanyPlaninfosByOrgId : function(orgId) {
				return BaseService.get('/api/yunshui/companyPlaninfo/getlistbyorgid',{
						'orgId':orgId
					});
			},
			
			//DataTable查询yunshui_company_planinfo对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/companyPlaninfo/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_company_planinfo对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/companyPlaninfo/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据orgId获取政府平台审查计划
			getPlatformPlaninfo : function(orgId) {
				return BaseService.get('/api/yunshui/companyPlaninfo/getplatformplaninfo',{
						'orgId':orgId
					});
			},
			
			//获取政府平台审查计划
			getPlatformPlansTest : function(orgId) {
				return BaseService.get('/api/yunshui/companyPlaninfo/get-platform-plan-test',{
						'orgId':orgId
					});
			},
		},
	    //yunshui_product_structure	    
        ProductStructureService :{
			
			//增加yunshui_product_structure对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/productStructure/add',mapParams);
			},
			
			//删除yunshui_product_structure对象
			del : function(id) {
				return BaseService.post('/api/yunshui/productStructure/del',{
						'id':id
					});
			},
			
			//修改yunshui_product_structure对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/productStructure/edit',mapParams);
			},
			
			//修改yunshui_product_structure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/productStructure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_product_structure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/productStructure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_product_structure对象
			get : function(id) {
				return BaseService.get('/api/yunshui/productStructure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_product_structure对象
			getAll : function() {
				return BaseService.get('/api/yunshui/productStructure/getall');
			},
			
			//DataTable查询yunshui_product_structure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/productStructure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_product_structure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/productStructure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/productStructure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_convert_ration_config	    
        ConvertRationConfigService :{
			
			//增加yunshui_convert_ration_config对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/convertRationConfig/add',mapParams);
			},
			
			//删除yunshui_convert_ration_config对象
			del : function(id) {
				return BaseService.post('/api/yunshui/convertRationConfig/del',{
						'id':id
					});
			},
			
			//修改yunshui_convert_ration_config对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/convertRationConfig/edit',mapParams);
			},
			
			//修改yunshui_convert_ration_config对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/convertRationConfig/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_convert_ration_config对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/convertRationConfig/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_convert_ration_config对象
			get : function(id) {
				return BaseService.get('/api/yunshui/convertRationConfig/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_convert_ration_config对象
			getAll : function() {
				return BaseService.get('/api/yunshui/convertRationConfig/getall');
			},
			
			//DataTable查询yunshui_convert_ration_config对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/convertRationConfig/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_convert_ration_config对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/convertRationConfig/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/convertRationConfig/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_gauge_configure	    
        CompanyDevService :{
			
			//DataTable查询审查-主要用能设备一览表
			getDataTable : function(dataTable) {
				return BaseService.post('/api/yunshui/companyDev/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//测试审查实体转换接口
			testFind : function(orgId) {
				return BaseService.post('/api/yunshui/companyDev/testFind',{
						'orgId':orgId
					});
			},
		},
	    //审查计划提交明细	    
        CheckplanUploadDetailService :{
			
			//增加审查计划提交明细对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/checkplanUploadDetail/add',mapParams);
			},
			
			//删除审查计划提交明细对象
			del : function(id) {
				return BaseService.post('/api/yunshui/checkplanUploadDetail/del',{
						'id':id
					});
			},
			
			//修改审查计划提交明细对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/checkplanUploadDetail/edit',mapParams);
			},
			
			//修改审查计划提交明细对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/checkplanUploadDetail/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出审查计划提交明细对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/checkplanUploadDetail/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取审查计划提交明细对象
			get : function(id) {
				return BaseService.get('/api/yunshui/checkplanUploadDetail/get',{
						'id':id
					});
			},
			
			//查询所有审查计划提交明细对象
			getAll : function() {
				return BaseService.get('/api/yunshui/checkplanUploadDetail/getall');
			},
			
			//DataTable查询审查计划提交明细对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/checkplanUploadDetail/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示审查计划提交明细对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/checkplanUploadDetail/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/checkplanUploadDetail/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_gauge_replace_configure	    
        GaugeReplaceConfigureService :{
			
			//增加yunshui_gauge_replace_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/gaugeReplaceConfigure/add',mapParams);
			},
			
			//删除yunshui_gauge_replace_configure对象
			del : function(id) {
				return BaseService.post('/api/yunshui/gaugeReplaceConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_gauge_replace_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/gaugeReplaceConfigure/edit',mapParams);
			},
			
			//修改yunshui_gauge_replace_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/gaugeReplaceConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_gauge_replace_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/gaugeReplaceConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_gauge_replace_configure对象
			get : function(id) {
				return BaseService.get('/api/yunshui/gaugeReplaceConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_gauge_replace_configure对象
			getAll : function() {
				return BaseService.get('/api/yunshui/gaugeReplaceConfigure/getall');
			},
			
			//DataTable查询yunshui_gauge_replace_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/gaugeReplaceConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_gauge_replace_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/gaugeReplaceConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/gaugeReplaceConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //checkplanCheckfileController	    
        CheckplanCheckfileService :{
			
			//增加审查计划-上传文件相关对象
			add : function(mapParams) {
						return BaseService.upload('/api/yunshui/checkplanCheckfile/add',mapParams);
			},
			
			//删除审查计划-上传文件相关对象
			del : function(id) {
				return BaseService.post('/api/yunshui/checkplanCheckfile/del',{
						'id':id
					});
			},
			
			//修改审查计划-上传文件相关对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/checkplanCheckfile/edit',mapParams);
			},
			
			//修改审查计划-上传文件相关对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/checkplanCheckfile/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出审查计划-上传文件相关对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/checkplanCheckfile/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取审查计划-上传文件相关对象
			get : function(id) {
				return BaseService.get('/api/yunshui/checkplanCheckfile/get',{
						'id':id
					});
			},
			
			//查询所有审查计划-上传文件相关对象
			getAll : function() {
				return BaseService.get('/api/yunshui/checkplanCheckfile/getall');
			},
			
			//DataTable查询审查计划-上传文件相关对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/checkplanCheckfile/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示审查计划-上传文件相关对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/checkplanCheckfile/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/checkplanCheckfile/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_output_value	    
        OutputValueService :{
			
			//增加yunshui_output_value对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/outputValue/add',mapParams);
			},
			
			//删除yunshui_output_value对象
			del : function(id) {
				return BaseService.post('/api/yunshui/outputValue/del',{
						'id':id
					});
			},
			
			//修改yunshui_output_value对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/outputValue/edit',mapParams);
			},
			
			//修改yunshui_output_value对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/outputValue/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_output_value对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/outputValue/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_output_value对象
			get : function(id) {
				return BaseService.get('/api/yunshui/outputValue/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_output_value对象
			getAll : function() {
				return BaseService.get('/api/yunshui/outputValue/getall');
			},
			
			//DataTable查询yunshui_output_value对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/outputValue/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_output_value对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/outputValue/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/outputValue/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_energy_certification	    
        EnergyCertificationService :{
			
			//增加yunshui_energy_certification对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/energyCertification/add',mapParams);
			},
			
			//删除yunshui_energy_certification对象
			del : function(id) {
				return BaseService.post('/api/yunshui/energyCertification/del',{
						'id':id
					});
			},
			
			//修改yunshui_energy_certification对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/energyCertification/edit',mapParams);
			},
			
			//修改yunshui_energy_certification对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/energyCertification/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_energy_certification对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/energyCertification/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_energy_certification对象
			get : function(id) {
				return BaseService.get('/api/yunshui/energyCertification/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_energy_certification对象
			getAll : function() {
				return BaseService.get('/api/yunshui/energyCertification/getall');
			},
			
			//DataTable查询yunshui_energy_certification对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/energyCertification/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_energy_certification对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/energyCertification/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/energyCertification/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_enterprise_info	    
        EnterpriseInfoService :{
			
			//增加yunshui_enterprise_info对象
			add : function(mapParams) {
						return BaseService.upload('/api/yunshui/enterpriseInfo/add',mapParams);
			},
			
			//删除yunshui_enterprise_info对象
			del : function(id) {
				return BaseService.post('/api/yunshui/enterpriseInfo/del',{
						'id':id
					});
			},
			
			//修改yunshui_enterprise_info对象
			edit : function(mapParams) {
						return BaseService.upload('/api/yunshui/enterpriseInfo/edit',mapParams);
			},
			
			//修改yunshui_enterprise_info对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/enterpriseInfo/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_enterprise_info对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/enterpriseInfo/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_enterprise_info对象
			get : function(id) {
				return BaseService.get('/api/yunshui/enterpriseInfo/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_enterprise_info对象
			getAll : function() {
				return BaseService.get('/api/yunshui/enterpriseInfo/getall');
			},
			
			//根据ID获取yunshui_enterprise_info对象
			getDataDownload : function() {
				return BaseService.get('/api/yunshui/enterpriseInfo/getDataDownload');
			},
			
			//DataTable查询yunshui_enterprise_info对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/enterpriseInfo/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_enterprise_info对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/enterpriseInfo/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/enterpriseInfo/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
			
			//立刻上传采集数据
			uploadNow : function(orgId, dateStr, type) {
				return BaseService.get('/api/yunshui/enterpriseInfo/uploadNow',{
						'orgId':orgId, 'dateStr':dateStr, 'type':type
					});
			},
		},
	    //yunshui_materiel_product	    
        MaterielProductService :{
			
			//增加yunshui_materiel_product对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/materielProduct/add',mapParams);
			},
			
			//删除yunshui_materiel_product对象
			del : function(id) {
				return BaseService.post('/api/yunshui/materielProduct/del',{
						'id':id
					});
			},
			
			//修改yunshui_materiel_product对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/materielProduct/edit',mapParams);
			},
			
			//修改yunshui_materiel_product对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/materielProduct/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_materiel_product对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/materielProduct/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_materiel_product对象
			get : function(id) {
				return BaseService.get('/api/yunshui/materielProduct/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_materiel_product对象
			getAll : function() {
				return BaseService.get('/api/yunshui/materielProduct/getall');
			},
			
			//DataTable查询yunshui_materiel_product对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/materielProduct/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_materiel_product对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/materielProduct/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/materielProduct/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //com.sess.hxy.yunshui.controller.TestController	    
        TestService :{
			
			//add
			companyCalculaterAdd : function() {
				return BaseService.post('/api/yunshui/test//YNEnergy/companyCalculater/add');
			},
			
			//delete
			companyCalculaterDelete : function() {
				return BaseService.post('/api/yunshui/test//YNEnergy/companyCalculater/delete');
			},
			
			//update
			companyCalculaterUpdate : function() {
				return BaseService.post('/api/yunshui/test//YNEnergy/companyCalculater/update');
			},
			
			//getSvgData
			getSvgData : function(svgDataList, startTime, endTime, fieldName) {
				return BaseService.post('/api/yunshui/test/getSvgData',{
						'svgDataList':svgDataList, 'startTime':startTime, 'endTime':endTime, 'fieldName':fieldName
					});
			},
			
			//模拟政府平台接收方法
			post : function() {
				return BaseService.post('/api/yunshui/test/post');
			},
			
			//testTask
			testEval : function() {
				return BaseService.post('/api/yunshui/test/testTask');
			},
			
			//测试上报
			testPost : function(mapParams) {
						return BaseService.post('/api/yunshui/test/testPost',mapParams);
			},
		},
	    //yunshui_review_data	    
        ReviewDataService :{
			
			//批量删除yunshui_review_data对象
			deleteinbatches : function(ids) {
				return BaseService.post('/api/yunshui/reviewData/deleteinbatches',{
						'ids':ids
					});
			},
			
			//审查数据导入模板
			downloadTemplate : function() {
				return BaseService.download('/api/yunshui/reviewData/downloadTemplate');
			},
			
			//通过DataTable导出yunshui_review_data对象到文件
			exportDataTable : function(dataTable) {
				return BaseService.download('/api/yunshui/reviewData/exportdatatable',{
						'dataTable':dataTable
					});
			},
			
			//DataTable查询yunshui_review_data对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/reviewData/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//导入文件
			importFile : function(enterpriseId, upFile) {
				return BaseService.upload('/api/yunshui/reviewData/importFile',{
						'enterpriseId':enterpriseId, 'upFile':upFile
					});
			},
			
			//审查数据报表导出
			reportExportData : function(enterpriseId, reportType, startMonth, endMonth) {
				return BaseService.download('/api/yunshui/reviewData/reportexportdata',{
						'enterpriseId':enterpriseId, 'reportType':reportType, 'startMonth':startMonth, 'endMonth':endMonth
					});
			},
			
			//审查数据报表查询数据
			reportQueryData : function(enterpriseId, reportType, startMonth, endMonth) {
				return BaseService.post('/api/yunshui/reviewData/reportquerydata',{
						'enterpriseId':enterpriseId, 'reportType':reportType, 'startMonth':startMonth, 'endMonth':endMonth
					});
			},
			
			//审查数据报表查询数据
			reportQueryDataMap : function(enterpriseId, reportType, startMonth, endMonth) {
				return BaseService.post('/api/yunshui/reviewData/reportquerydatamap',{
						'enterpriseId':enterpriseId, 'reportType':reportType, 'startMonth':startMonth, 'endMonth':endMonth
					});
			},
		},
	    //yunshui_platform_info	    
        PlatformInfoService :{
			
			//增加yunshui_platform_info对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/platformInfo/add',mapParams);
			},
			
			//删除yunshui_platform_info对象
			del : function(id) {
				return BaseService.post('/api/yunshui/platformInfo/del',{
						'id':id
					});
			},
			
			//修改yunshui_platform_info对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/platformInfo/edit',mapParams);
			},
			
			//修改yunshui_platform_info对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/platformInfo/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_platform_info对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/platformInfo/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_platform_info对象
			get : function(id) {
				return BaseService.get('/api/yunshui/platformInfo/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_platform_info对象
			getAll : function() {
				return BaseService.get('/api/yunshui/platformInfo/getall');
			},
			
			//DataTable查询yunshui_platform_info对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/platformInfo/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_platform_info对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/platformInfo/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/platformInfo/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //计量管理	    
        MeasurementService :{
			
			//获取能源平衡网络图文件路径
			getEnergyBalancePath : function(orgId) {
				return BaseService.get('/api/yunshui/measurement/getEnergyBalancePath',{
						'orgId':orgId
					});
			},
			
			//获取文件路径
			getPath : function(orgId, type) {
				return BaseService.get('/api/yunshui/measurement/getPath',{
						'orgId':orgId, 'type':type
					});
			},
			
			//获取文件路径列表
			getPathMap : function(orgId) {
				return BaseService.get('/api/yunshui/measurement/getPathMap',{
						'orgId':orgId
					});
			},
			
			//getSvgData
			getSvgData : function(svgDataList, startTime, endTime, dateType) {
				return BaseService.post('/api/yunshui/measurement/getSvgData',{
						'svgDataList':svgDataList, 'startTime':startTime, 'endTime':endTime, 'dateType':dateType
					});
			},
			
			//上传能源平衡网络图文件
			uploadEnergyBalanceFile : function(orgId, upFile, formulas) {
				return BaseService.upload('/api/yunshui/measurement/uploadEnergyBalanceFile',{
						'orgId':orgId, 'upFile':upFile, 'formulas':formulas
					});
			},
			
			//上传文件
			uploadFile : function(orgId, type, upFile, formulas) {
				return BaseService.upload('/api/yunshui/measurement/uploadFile',{
						'orgId':orgId, 'type':type, 'upFile':upFile, 'formulas':formulas
					});
			},
		},
	    //companyAuditinfoController	    
        CompanyAuditinfoService :{
			
			//增加9.6.18 获取审查结果对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/companyAuditinfo/add',mapParams);
			},
			
			//删除9.6.18 获取审查结果对象
			del : function(id) {
				return BaseService.post('/api/yunshui/companyAuditinfo/del',{
						'id':id
					});
			},
			
			//修改9.6.18 获取审查结果对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/companyAuditinfo/edit',mapParams);
			},
			
			//修改9.6.18 获取审查结果对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/companyAuditinfo/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取9.6.18 获取审查结果对象
			get : function(id) {
				return BaseService.get('/api/yunshui/companyAuditinfo/get',{
						'id':id
					});
			},
			
			//查询所有9.6.18 获取审查结果对象
			getAll : function() {
				return BaseService.get('/api/yunshui/companyAuditinfo/getall');
			},
			
			//DataTable查询9.6.18 获取审查结果对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/companyAuditinfo/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示9.6.18 获取审查结果对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/companyAuditinfo/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取政府平台审查结果信息
			getPlatFormAuditinfos : function(checkPlanId) {
				return BaseService.get('/api/yunshui/companyAuditinfo/getplatformauditinfos',{
						'checkPlanId':checkPlanId
					});
			},
		},
	    //yunshui_gauge_configure	    
        CompanyGaugesService :{
			
			//DataTable查询yunshui_gauge_configure对象
			getDataTable : function(dataTable, tableType) {
				return BaseService.post('/api/yunshui/companyGauges/getdatatable',{
						'dataTable':dataTable, 'tableType':tableType
					});
			},
			
			//测试审查实体转换接口
			testFind : function(orgId, tableType) {
				return BaseService.post('/api/yunshui/companyGauges/testFind',{
						'orgId':orgId, 'tableType':tableType
					});
			},
		},
	    //yunshui_energy_conservation	    
        EnergyConservationService :{
			
			//增加yunshui_energy_conservation对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/energyConservation/add',mapParams);
			},
			
			//删除yunshui_energy_conservation对象
			del : function(id) {
				return BaseService.post('/api/yunshui/energyConservation/del',{
						'id':id
					});
			},
			
			//修改yunshui_energy_conservation对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/energyConservation/edit',mapParams);
			},
			
			//修改yunshui_energy_conservation对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/energyConservation/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_energy_conservation对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/energyConservation/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_energy_conservation对象
			get : function(id) {
				return BaseService.get('/api/yunshui/energyConservation/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_energy_conservation对象
			getAll : function() {
				return BaseService.get('/api/yunshui/energyConservation/getall');
			},
			
			//DataTable查询yunshui_energy_conservation对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/energyConservation/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_energy_conservation对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/energyConservation/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/energyConservation/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_gauge_configure	    
        CompanyCalculaterService :{
			
			//DataTable查询审查-能源计量人员一览表
			getDataTable : function(dataTable) {
				return BaseService.post('/api/yunshui/companyCalculater/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//测试审查实体转换接口
			testFind : function(orgId) {
				return BaseService.post('/api/yunshui/companyCalculater/testFind',{
						'orgId':orgId
					});
			},
		},
	    //yunshui_upload_detail	    
        UploadDetailService :{
			
			//增加yunshui_upload_detail对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/uploadDetail/add',mapParams);
			},
			
			//删除yunshui_upload_detail对象
			del : function(id) {
				return BaseService.post('/api/yunshui/uploadDetail/del',{
						'id':id
					});
			},
			
			//修改yunshui_upload_detail对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/uploadDetail/edit',mapParams);
			},
			
			//修改yunshui_upload_detail对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/uploadDetail/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_upload_detail对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/uploadDetail/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_upload_detail对象
			get : function(id) {
				return BaseService.get('/api/yunshui/uploadDetail/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_upload_detail对象
			getAll : function() {
				return BaseService.get('/api/yunshui/uploadDetail/getall');
			},
			
			//DataTable查询yunshui_upload_detail对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/uploadDetail/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_upload_detail对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/uploadDetail/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/uploadDetail/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_unit_configure	    
        UnitConfigureService :{
			
			//增加yunshui_unit_configure对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/unitConfigure/add',mapParams);
			},
			
			//删除yunshui_unit_configure对象
			del : function(id) {
				return BaseService.post('/api/yunshui/unitConfigure/del',{
						'id':id
					});
			},
			
			//修改yunshui_unit_configure对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/unitConfigure/edit',mapParams);
			},
			
			//修改yunshui_unit_configure对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/unitConfigure/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_unit_configure对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/unitConfigure/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_unit_configure对象
			get : function(id) {
				return BaseService.get('/api/yunshui/unitConfigure/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_unit_configure对象
			getAll : function() {
				return BaseService.get('/api/yunshui/unitConfigure/getall');
			},
			
			//DataTable查询yunshui_unit_configure对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/unitConfigure/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_unit_configure对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/unitConfigure/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/unitConfigure/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_calculater	    
        CalculaterService :{
			
			//增加yunshui_calculater对象
			add : function(mapParams) {
						return BaseService.upload('/api/yunshui/calculater/add',mapParams);
			},
			
			//删除yunshui_calculater对象
			del : function(id) {
				return BaseService.post('/api/yunshui/calculater/del',{
						'id':id
					});
			},
			
			//修改yunshui_calculater对象
			edit : function(mapParams) {
						return BaseService.upload('/api/yunshui/calculater/edit',mapParams);
			},
			
			//修改yunshui_calculater对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/calculater/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_calculater对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/calculater/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_calculater对象
			get : function(id) {
				return BaseService.get('/api/yunshui/calculater/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_calculater对象
			getAll : function() {
				return BaseService.get('/api/yunshui/calculater/getall');
			},
			
			//DataTable查询yunshui_calculater对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/calculater/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_calculater对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/calculater/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/calculater/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
	    //yunshui_energy_sys	    
        EnergySysService :{
			
			//增加yunshui_energy_sys对象
			add : function(mapParams) {
						return BaseService.post('/api/yunshui/energySys/add',mapParams);
			},
			
			//删除yunshui_energy_sys对象
			del : function(id) {
				return BaseService.post('/api/yunshui/energySys/del',{
						'id':id
					});
			},
			
			//修改yunshui_energy_sys对象
			edit : function(mapParams) {
						return BaseService.post('/api/yunshui/energySys/edit',mapParams);
			},
			
			//修改yunshui_energy_sys对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/yunshui/energySys/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出yunshui_energy_sys对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/yunshui/energySys/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取yunshui_energy_sys对象
			get : function(id) {
				return BaseService.get('/api/yunshui/energySys/get',{
						'id':id
					});
			},
			
			//查询所有yunshui_energy_sys对象
			getAll : function() {
				return BaseService.get('/api/yunshui/energySys/getall');
			},
			
			//DataTable查询yunshui_energy_sys对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/yunshui/energySys/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示yunshui_energy_sys对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/yunshui/energySys/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入文件
			importFile : function(upFile, orgId) {
				return BaseService.upload('/api/yunshui/energySys/importFile',{
						'upFile':upFile, 'orgId':orgId
					});
			},
		},
 
}

export default yunshui;