import { BaseService } from '../SDNRequest';

const sdata = {
	    //运行分析	    
        OperationAnalysisService :{
			
			//导出BCMS运行数据
			exportBCMSDatas : function(orgIds, fromDt, toDt) {
				return BaseService.download('/api/sdata/operationAnalysis/exportBCMSDatas',{
						'orgIds':orgIds, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//导出储能充放记录
			exportChargeDischargeList : function(orgId, fromDt, toDt, chargeStates) {
				return BaseService.download('/api/sdata/operationAnalysis/exportChargeDischargeList',{
						'orgId':orgId, 'fromDt':fromDt, 'toDt':toDt, 'chargeStates':chargeStates
					});
			},
			
			//导出PCS运行数据
			exportPcsDatas : function(orgIds, fromDt, toDt, itemCodeList) {
				return BaseService.download('/api/sdata/operationAnalysis/exportPcsDatas',{
						'orgIds':orgIds, 'fromDt':fromDt, 'toDt':toDt, 'itemCodeList':itemCodeList
					});
			},
			
			//获取BCMS运行数据
			getBCMSDatas : function(orgIds, fromDt, toDt, itemCode) {
				return BaseService.get('/api/sdata/operationAnalysis/getBCMSDatas',{
						'orgIds':orgIds, 'fromDt':fromDt, 'toDt':toDt, 'itemCode':itemCode
					});
			},
			
			//储能充放记录
			getChargeDischargeList : function(orgId, fromDt, toDt, chargeStates) {
				return BaseService.get('/api/sdata/operationAnalysis/getChargeDischargeList',{
						'orgId':orgId, 'fromDt':fromDt, 'toDt':toDt, 'chargeStates':chargeStates
					});
			},
			
			//获取PCS运行数据
			getPcsDatas : function(orgIds, fromDt, toDt, itemCodeList) {
				return BaseService.get('/api/sdata/operationAnalysis/getPcsDatas',{
						'orgIds':orgIds, 'fromDt':fromDt, 'toDt':toDt, 'itemCodeList':itemCodeList
					});
			},
			
			//获取子监控对象列表
			getSubDeviceByParentId : function(pOrgId, typeCode) {
				return BaseService.get('/api/sdata/operationAnalysis/getSubDeviceByParentId',{
						'pOrgId':pOrgId, 'typeCode':typeCode
					});
			},
			
			//获取储能单元
			getUnitBySiteIdAndTypeCode : function(orgId, typeCode) {
				return BaseService.get('/api/sdata/operationAnalysis/getUnitBySiteIdAndTypeCode',{
						'orgId':orgId, 'typeCode':typeCode
					});
			},
		},
	    //充放电计划曲线操作记录	    
        PlanCurveReportService :{
			
			//策略批量下发
			batchIssuePlanCurveReport : function(planCurveReportIds, planSendDt, interval, loadType, userName, password) {
				return BaseService.post('/api/sdata/planCurveReport/batchIssuePlanCurveReport',{
						'planCurveReportIds':planCurveReportIds, 'planSendDt':planSendDt, 'interval':interval, 'loadType':loadType, 'userName':userName, 'password':password
					});
			},
			
			//导出曲线
			exportCurves : function(ammeterIds, fromDt, toDt) {
				return BaseService.download('/api/sdata/planCurveReport/exportCurves',{
						'ammeterIds':ammeterIds, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//导出策略下发列表
			exportIssueDataTable : function(dataTable, date) {
				return BaseService.download('/api/sdata/planCurveReport/exportIssueDataTable',{
						'dataTable':dataTable, 'date':date
					});
			},
			
			//导出充放电计划曲线操作记录
			exportPlanCurveReport : function(dataTable, date) {
				return BaseService.download('/api/sdata/planCurveReport/exportPlanCurveReport',{
						'dataTable':dataTable, 'date':date
					});
			},
			
			//导出策略曲线模板
			exportPlanTemplate : function(dateTime) {
				return BaseService.download('/api/sdata/planCurveReport/exportPlanTemplate',{
						'dateTime':dateTime
					});
			},
			
			//导出策略曲线模板-每5分钟
			exportPlanTemplateFiveMinutes : function(dateTime) {
				return BaseService.download('/api/sdata/planCurveReport/exportPlanTemplateFiveMinutes',{
						'dateTime':dateTime
					});
			},
			
			//DataTable查询充放电计划曲线操作记录对象
			getDataTable : function(dataTable, date) {
				return BaseService.get('/api/sdata/planCurveReport/getdatatable',{
						'dataTable':dataTable, 'date':date
					});
			},
			
			//策略下发列表
			getEmsBySiteIds : function(siteIds) {
				return BaseService.get('/api/sdata/planCurveReport/getEmsBySiteIds',{
						'siteIds':siteIds
					});
			},
			
			//策略下发列表
			getIssueDataTable : function(dataTable, date) {
				return BaseService.get('/api/sdata/planCurveReport/getIssueDataTable',{
						'dataTable':dataTable, 'date':date
					});
			},
			
			//一天充放电计划曲线
			getOneDayPower : function(ammeterIds, interval, date) {
				return BaseService.get('/api/sdata/planCurveReport/getOneDayPower',{
						'ammeterIds':ammeterIds, 'interval':interval, 'date':date
					});
			},
			
			//导入策略曲线模板
			importPlanTemplate : function(upFile) {
				return BaseService.upload('/api/sdata/planCurveReport/importPlanTemplate',{
						'upFile':upFile
					});
			},
			
			//导入策略曲线模板-每5分钟
			importPlanTemplateFiveMinutes : function(upFile) {
				return BaseService.upload('/api/sdata/planCurveReport/importPlanTemplateFiveMinutes',{
						'upFile':upFile
					});
			},
			
			//单个策略下发
			issuePlanCurveReport : function(planCurveReportId, planSendDt, loadType, interval, userName, password) {
				return BaseService.post('/api/sdata/planCurveReport/issuePlanCurveReport',{
						'planCurveReportId':planCurveReportId, 'planSendDt':planSendDt, 'loadType':loadType, 'interval':interval, 'userName':userName, 'password':password
					});
			},
		},
	    //充放电计策略查询监控	    
        PlanCurveMonitorQryService :{
			
			//导出储能系统监控
			exportPlanCurveMonitored : function(ammeterId, interval, fromDt, toDt) {
				return BaseService.download('/api/sdata/planCurveQuery/exportPlanCurveMonitored',{
						'ammeterId':ammeterId, 'interval':interval, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//储能设备概览
			getEUnitMonitoring : function(siteOrgId) {
				return BaseService.get('/api/sdata/planCurveQuery/getEUnitMonitoring',{
						'siteOrgId':siteOrgId
					});
			},
			
			//储能系统监控
			planCurveMonitored : function(ammeterId, interval, fromDt, toDt) {
				return BaseService.get('/api/sdata/planCurveQuery/planCurveMonitored',{
						'ammeterId':ammeterId, 'interval':interval, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//查询多个储能系统策略线
			queryPlanCurve : function(ammeterIds, interval, fromDt, toDt) {
				return BaseService.get('/api/sdata/planCurveQuery/queryPlanCurve',{
						'ammeterIds':ammeterIds, 'interval':interval, 'fromDt':fromDt, 'toDt':toDt
					});
			},
		},
	    //储能系统总览图表	    
        ChartService :{
			
			//查询PCS和BCMS的状态
			getDeviceState : function(orgId) {
				return BaseService.get('/api/sdata/chart/getDeviceState',{
						'orgId':orgId
					});
			},
			
			//总览可视化
			getGFChartData : function(orgId, interval, fromDt, toDt, itemCodeList) {
				return BaseService.get('/api/sdata/chart/getGFChartData',{
						'orgId':orgId, 'interval':interval, 'fromDt':fromDt, 'toDt':toDt, 'itemCodeList':itemCodeList
					});
			},
			
			//总览可视化
			getIncomeChartData : function(orgId, interval, fromDt, toDt, itemCodeList) {
				return BaseService.get('/api/sdata/chart/getIncomeChartData',{
						'orgId':orgId, 'interval':interval, 'fromDt':fromDt, 'toDt':toDt, 'itemCodeList':itemCodeList
					});
			},
			
			//实时状态数据
			getRealTimeChartData : function(orgId) {
				return BaseService.get('/api/sdata/chart/getRealTimeChartData',{
						'orgId':orgId
					});
			},
			
			//累计收益或发电量
			getTotalData : function(orgId) {
				return BaseService.get('/api/sdata/chart/getTotalData',{
						'orgId':orgId
					});
			},
			
			//设置电量及费用任务
			setPPandCZforGF : function(dataAmmeterId, interval, dt, pp, cz) {
				return BaseService.post('/api/sdata/chart/setPPandCZforGF',{
						'dataAmmeterId':dataAmmeterId, 'interval':interval, 'dt':dt, 'pp':pp, 'cz':cz
					});
			},
			
			//设置反向电量及费用任务
			setRPandRCZ : function(dataAmmeterId, interval, dt, rp, rcz) {
				return BaseService.post('/api/sdata/chart/setRPandRCZ',{
						'dataAmmeterId':dataAmmeterId, 'interval':interval, 'dt':dt, 'rp':rp, 'rcz':rcz
					});
			},
		},
 
}

export default sdata;