export const SYSTEM_START ="SYSTEM_START";

export const USER_INFO = "USER_INFO";
export const URL_INFO = "URL_INFO";
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const REQUEST_USER = "REQUEST_USER";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const USER_LOGOUT = "USER_LOGOUT";
export const EDIT_USER_INFO = 'EDIT_USER_INFO';
export const EDIT_USER_INFO_SUCCESS = 'EDIT_USER_INFO_SUCCESS';
export const UPLOAD_USER_PORTRAIT = "UPLOAD_USER_PORTRAIT";

export const GET_GROUP_FIRM_TREE = 'GET_GROUP_FIRM_TREE';
export const GET_GROUP_FIRM_TREE_SUCCESS = 'GET_GROUP_FIRM_TREE_SUCCESS';
export const SET_SELECTED_FIRM = 'SET_SELECTED_FIRM';
export const GET_USER_FIRMS = 'GET_USER_FIRMS';
export const GET_USER_FIRMS_SUCCESS = 'GET_USER_FIRMS_SUCCESS';

export const LOAD_NAVIGATION_MENU = 'LOAD_NAVIGATION_MENU';
export const SET_NAVIGATION_MENU = 'SET_NAVIGATION_MENU';
export const SET_CURRENT_NAVIGATION_ROUTE = 'SET_CURRENT_NAVIGATION_ROUTE';
export const SWITCH_NAVIGATION_MENU = 'SWITCH_NAVIGATION_MENU';

export const GET_SYSTEM_INFO = 'GET_SYSTEM_INFO';
export const GET_ALL_EFFECTIVE_SUCCESS = 'GET_ALL_EFFECTIVE_SUCCESS';
export const SET_SHOW_SUB_DEVICE = 'SET_SHOW_SUB_DEVICE';
export const GET_FIRM_SUB_TREE_BEGIN = 'GET_FIRM_SUB_TREE_BEGIN';
export const GET_FIRM_SUB_TREE_END = 'GET_FIRM_SUB_TREE_END';
export const GET_FIRM_SUB_TREE = 'GET_FIRM_SUB_TREE';
export const CLEAR_FIRM_SUB_TREE = 'CLEAR_FIRM_SUB_TREE';
export const GET_FIRM_SUB_TREE_SUCCESS = 'GET_FIRM_SUB_TREE_SUCCESS';
export const SELECT_OBJECT_NODE = 'SELECT_OBJECT_NODE';
export const CHECK_OBJECT_NODE = 'CHECK_OBJECT_NODE';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_USE_POWER_LIST = 'GET_USE_POWER_LIST';
export const GET_USE_POWER_LIST_SUCCESS = 'GET_USE_POWER_LIST_SUCCESS';

export const SET_SELECTED_MONI_UNIT = 'SET_SELECTED_MONI_UNIT';
