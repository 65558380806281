import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";
import moment from "moment";

const Scheme = Loadable({
  loader: () => import("./Scheme/"),
  loading: Loading,
});

const Agreement = Loadable({
  loader: () => import("./Agreement/"),
  loading: Loading,
});

const Parameter = Loadable({
  loader: () => import("./Parameter/"),
  loading: Loading,
});

export const routes = [
  {
    path: "/SDNPowerPrice/Scheme",
    exact: true,
    component: Scheme,
    name: "Scheme",
  },
  {
    path: "/SDNPowerPrice/Agreement",
    exact: true,
    component: Agreement,
    name: "Agreement",
  },
  {
    path: "/SDNPowerPrice/Parameter",
    exact: true,
    component: Parameter,
    name: "Parameter",
  },
];

export const dts = (dt, fm) => (dt ? moment(dt).format(fm || "YYYY-MM-DD") : "-");
export const pts = pt => {
  switch (pt) {
    case 1:
      return "统一峰谷电价";
    case 2:
      return "自定义峰谷电价";
    case 3:
      return "广东交易";
    default:
      return "-";
  }
};
