import Loadable from "react-loadable";
import Loading from "../../common/ui/components/Loading";

const Overview = Loadable({
  loader: () => import("./Overview"),
  loading: Loading,
});
const PVOverview = Loadable({
  loader: () => import("./PVOverview"),
  loading: Loading,
});
const ChunengOverview = Loadable({
  loader: () => import("./ChunengOverview"),
  loading: Loading,
});
const Setting = Loadable({
  loader: () => import("./Chunengcelve/Setting"),
  loading: Loading,
});
const Publish = Loadable({
  loader: () => import("./Chunengcelve/Publish"),
  loading: Loading,
});
const Query = Loadable({
  loader: () => import("./Chunengcelve/Query"),
  loading: Loading,
});
const Monitor = Loadable({
  loader: () => import("./Chunengcelve/Monitor"),
  loading: Loading,
});
const Analysis = Loadable({
  loader: () => import("./CostBenefitAnalysis"),
  loading: Loading,
});
const ChargeAnalysis = Loadable({
  loader: () => import("./Running/ChargeAnalysis"),
  loading: Loading,
});
const ChargeRecord = Loadable({
  loader: () => import("./Running/ChargeRecord"),
  loading: Loading,
});
const PCSAnalysis = Loadable({
  loader: () => import("./Running/PCSAnalysis"),
  loading: Loading,
});
const BatteryAnalysis = Loadable({
  loader: () => import("./Running/BatteryAnalysis"),
  loading: Loading,
});

export const routes = [
  {
    path: "/SDNGuangchuchong/Overview",
    exact: true,
    component: Overview,
    name: "Overview",
  },
  {
    path: "/SDNGuangchuchong/PVOverview",
    exact: true,
    component: PVOverview,
    name: "PVOverview",
  },
  {
    path: "/SDNGuangchuchong/ChunengOverview",
    exact: true,
    component: ChunengOverview,
    name: "ChunengOverview",
  },
  {
    path: "/SDNGuangchuchong/Chunengcelve/Setting",
    exact: true,
    component: Setting,
    name: "Setting",
  },
  {
    path: "/SDNGuangchuchong/Chunengcelve/Publish",
    exact: true,
    component: Publish,
    name: "Publish",
  },
  {
    path: "/SDNGuangchuchong/Chunengcelve/Query",
    exact: true,
    component: Query,
    name: "Query",
  },
  {
    path: "/SDNGuangchuchong/Chunengcelve/Monitor",
    exact: true,
    component: Monitor,
    name: "Monitor",
  },
  {
    path: "/SDNGuangchuchong/CostBenefitAnalysis",
    exact: true,
    component: Analysis,
    name: "Analysis",
  },
  {
    path: "/SDNGuangchuchong/Running/ChargeAnalysis",
    exact: true,
    component: ChargeAnalysis,
    name: "ChargeAnalysis",
  },
  {
    path: "/SDNGuangchuchong/Running/ChargeRecord",
    exact: true,
    component: ChargeRecord,
    name: "ChargeRecord",
  },
  {
    path: "/SDNGuangchuchong/Running/PCSAnalysis",
    exact: true,
    component: PCSAnalysis,
    name: "PCSAnalysis",
  },
  {
    path: "/SDNGuangchuchong/Running/BatteryAnalysis",
    exact: true,
    component: BatteryAnalysis,
    name: "BatteryAnalysis",
  },
];
