import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise';
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './SDNSaga';

import { config } from "./config";
import {
  handleBodyClasses,
  dumpLayoutToStorage,
  layoutReducer,
  layoutInit
} from "../layout";

import { i18nReducer, i18nInit } from "../common/i18n";

import { SDNSystemReducer, SYSTEM_START  } from "../common/system";
import SDNUserAdminReducer from "../views/SDNUserAdmin/SDNUserAdminReducer";

import { SDNMonitorChartReducer } from '../views/SDNMonitoring/SDNMonitorChart/SDNMonitorChartModel';
import { SDNEnergyReducer } from '../views/SDNMonitoring/SDNEnergy/SDNEnergyModel';
//能效管理
import SDNPowerOverviewReducer from "../views/SDNPowerAdmin/SDNPowerOverview/SDNPowerOverviewReduce";
import { SDNPowerAnalysisReducer } from "../views/SDNPowerAdmin/SDNPowerAnalysis/SDNPowerAnalysisModel";
import { SDNCompareAnalysisReducer } from "../views/SDNPowerAdmin/SDNCompareAnalysis/SDNCompareAnalysisModel";
import { SDNCoulometryChargeReducer } from '../views/SDNPowerAdmin/SDNCoulometryChargeAnalysis/SDNCoulometryChargeModel';
import { SDNLoadAnalysisReducer } from '../views/SDNPowerAdmin/SDNLoadAnalysis/SDNLoadAnalysisModel';
import { SDNUnitConfigReducer } from '../views/SDNPowerAdmin/SDNUnitConfig/SDNUnitConfigModel';
import { SDNPowerRankReducer } from '../views/SDNPowerAdmin/SDNPowerRank/SDNPowerRankModel';
import { SDNPowerFlowReducer } from '../views/SDNPowerAdmin/SDNPowerFlow/SDNPowerFlowModel';
import SDNSysAdminReducer from "../views/SDNSysAdmin/SDNSysAdminReducer";
import SDNEquipmentReducer from "../views/SDNEquipment/SDNEquipmentReducer";
import SDNAlertReducer from "../views/SDNEventAlert/SDNAlertReducer";
import SDNBaseConfigReducer from "../views/SDNBaseConfig/SDNBaseConfigReducer";
import SDNOperationAdminReducer from "../views/SDNOperationAdmin/SDNOperationAdminReducer";
import ActivitiesReducer from "../common/activities/ActivitiesReducer";
import {SDNDemandAnalysisReducer} from "../views/SDNPowerAdmin/SDNDemandAnalysis/model";
import {SDNElectricQualityReducer} from "../views/SDNPowerAdmin/SDNElectricQualityAnalysis/model";
import {peakAnalysisReducer} from "../views/SDNPowerAdmin/SDNPeakAnalysis/model";
import {SDNStatementStatisticsReducer} from "../views/SDNPowerAdmin/SDNStatementStatistics/SDNStatementStatisticsModel";

import {SDNDataInputReducer} from "../views/SDNPowerAdmin/SDNDataInput/model";
import {SDNPowerBillReducer} from "../views/SDNPowerAudit/SDNPowerBill/model";
import {SDNLedgerDetailReducer} from "../views/SDNEquipment/SDNLedgerDetail/model";
import {powerPeakAnalysisReducer} from "../views/SDNPowerAdmin/SDNPowerPeakAnalysis/model";
import {powerTimeShareAnalysisReducer} from "../views/SDNPowerAdmin/SDNTimeShareAnalysis/model";
import {powerCycleAnalysisReducer} from "../views/SDNPowerAdmin/SDNCycleAnalysis/model";

import {SDNDataUploadReducer} from "../views/SDNDataUpload/model";
import {HNDataUploadReducer} from "../views/HunanDataUpload/model";
import {QueryCurveReducer} from "../views/SDNGuangchuchong/Chunengcelve/Query/model";
import {SDNPowerStatisticReducer} from "../views/SDNPowerStatistic/model";



export const rootReducer = combineReducers({
  i18n: i18nReducer,

  system: SDNSystemReducer,
  layout: layoutReducer,
  userAdmin: SDNUserAdminReducer,
  monitorChart: SDNMonitorChartReducer,
  energy: SDNEnergyReducer,
  //能效管理
  powerOverview: SDNPowerOverviewReducer,
  powerAnalysis: SDNPowerAnalysisReducer,
  compareAnalysis: SDNCompareAnalysisReducer,
  peakAnalysis: peakAnalysisReducer,
  powerPeakAnalysis: powerPeakAnalysisReducer,
  powerTimeShareAnalysis: powerTimeShareAnalysisReducer,
  powerCycleAnalysis: powerCycleAnalysisReducer,
  coulometryCharge: SDNCoulometryChargeReducer,
  demandAnalysis: SDNDemandAnalysisReducer,
  powerBill: SDNPowerBillReducer,
  ledgerDetail: SDNLedgerDetailReducer,
  electricQuality: SDNElectricQualityReducer,
  statementStatistics: SDNStatementStatisticsReducer,
  loadAnalysis: SDNLoadAnalysisReducer,
  unitConfig: SDNUnitConfigReducer,
  powerRank: SDNPowerRankReducer,
  powerFlow: SDNPowerFlowReducer,

  sysAdmin: SDNSysAdminReducer,
  eventAlert: SDNAlertReducer,
  baseConfig: SDNBaseConfigReducer,
  equipment:SDNEquipmentReducer,
  operationAdmin: SDNOperationAdminReducer,
  activities: ActivitiesReducer,

  dataInput: SDNDataInputReducer,

  dataUpload: SDNDataUploadReducer,
  hnDataUpload: HNDataUploadReducer,
  queryPlanCurve: QueryCurveReducer,
  powerStatistic: SDNPowerStatisticReducer,  
});

const sagaMiddleware = createSagaMiddleware();

const logger = createLogger({
  // ...options
});

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      promiseMiddleware,
      sagaMiddleware,
      handleBodyClasses,
      dumpLayoutToStorage,
      // logger
    )
  )
);

sagaMiddleware.run(rootSaga);

store.dispatch(layoutInit());
store.dispatch({type: SYSTEM_START});

if (config.voice_command_auto) {

}

export default store;
