import { BaseService } from '../SDNRequest';

const archive = {
	    //用户管理单元类型	    
        ConsumerUnitTypeService :{
			
			//增加用户管理单元类型对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/consumerUnitType/add',mapParams);
			},
			
			//删除用户管理单元类型对象
			del : function(id) {
				return BaseService.post('/api/archive/consumerUnitType/del',{
						'id':id
					});
			},
			
			//修改用户管理单元类型对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/consumerUnitType/edit',mapParams);
			},
			
			//修改用户管理单元类型对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/consumerUnitType/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出用户管理单元类型对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/consumerUnitType/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取用户管理单元类型对象
			get : function(id) {
				return BaseService.get('/api/archive/consumerUnitType/get',{
						'id':id
					});
			},
			
			//查询所有用户管理单元类型对象
			getAll : function() {
				return BaseService.get('/api/archive/consumerUnitType/getall');
			},
			
			//DataTable查询用户管理单元类型对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/consumerUnitType/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示用户管理单元类型对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/consumerUnitType/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //用户	    
        ConsumerService :{
			
			//增加用户对象和logo文件
			add : function(consumer, upFile, iconFile) {
				return BaseService.upload('/api/archive/consumer/add',{
						'consumer':consumer, 'upFile':upFile, 'iconFile':iconFile
					});
			},
			
			//删除用户对象
			del : function(id) {
				return BaseService.post('/api/archive/consumer/del',{
						'id':id
					});
			},
			
			//修改用户对象和logo文件
			edit : function(consumer, upFile, iconFile) {
				return BaseService.upload('/api/archive/consumer/edit',{
						'consumer':consumer, 'upFile':upFile, 'iconFile':iconFile
					});
			},
			
			//修改用户对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/consumer/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出用户对象到文件
			exportAll : function(orgCode, orgName, industryId, areaCode, enteTypeId, address, state, orderBy, fieldName) {
				return BaseService.download('/api/archive/consumer/exportall',{
						'orgCode':orgCode, 'orgName':orgName, 'industryId':industryId, 'areaCode':areaCode, 'enteTypeId':enteTypeId, 'address':address, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//根据ID获取用户对象
			get : function(id) {
				return BaseService.get('/api/archive/consumer/get',{
						'id':id
					});
			},
			
			//查询所有用户对象
			getAll : function() {
				return BaseService.get('/api/archive/consumer/getall');
			},
			
			//查询所有用户对象
			getAllEffective : function() {
				return BaseService.get('/api/archive/consumer/getAllEffective');
			},
			
			//获取所有用户（ID 及 对应的 NAME）
			getAllIdRefNames : function() {
				return BaseService.get('/api/archive/consumer/getAllIdRefNames');
			},
			
			//根据用户名称模糊查询
			getAssociationByName : function(inputName) {
				return BaseService.get('/api/archive/consumer/getAssociationByName',{
						'inputName':inputName
					});
			},
			
			//获取企业及 对应的集团树形结构
			getConsumerAndGroup : function() {
				return BaseService.get('/api/archive/consumer/getConsumerAndGroup');
			},
			
			//获取员工所属的企业和集团信息
			getConsumerAndGroupByEmp : function() {
				return BaseService.get('/api/archive/consumer/getConsumerAndGroupByEmp');
			},
			
			//查询用户基础信息
			getConsumerBasicInfo : function(consumerId) {
				return BaseService.get('/api/archive/consumer/getConsumerBasicInfo',{
						'consumerId':consumerId
					});
			},
			
			//区域内的用户分布
			getConsumerByArea : function() {
				return BaseService.get('/api/archive/consumer/getConsumerByArea');
			},
			
			//用户信息列表
			getConsumerDataTable : function(dataTable) {
				return BaseService.post('/api/archive/consumer/getConsumerDataTable',{
						'dataTable':dataTable
					});
			},
			
			//用户分布，统计有用户区域的用户数
			getConsumerDistribution : function() {
				return BaseService.get('/api/archive/consumer/getConsumerDistribution');
			},
			
			//根据ID获取用户对象
			getConsumerInfo : function(id) {
				return BaseService.get('/api/archive/consumer/getConsumerInfo',{
						'id':id
					});
			},
			
			//全部联系人列表DataTable
			getConsumersAndEmpDataTable : function(dataTable) {
				return BaseService.post('/api/archive/consumer/getConsumersAndEmpDataTable',{
						'dataTable':dataTable
					});
			},
			
			//有效有用户数量
			getCount : function() {
				return BaseService.get('/api/archive/consumer/getCount');
			},
			
			//DataTable查询用户对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/consumer/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//企业下拉菜单数据获取
			getGroupList : function() {
				return BaseService.get('/api/archive/consumer/getGroupList');
			},
			
			//获取单个用户的统计信息
			getOneConsumerStatis : function(consumerId) {
				return BaseService.get('/api/archive/consumer/oneConsumerStatis',{
						'consumerId':consumerId
					});
			},
			
			//分页显示用户对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/consumer/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询用户对象
			searchAll : function(orgCode, orgName, industryId, areaCode, enteTypeId, address, state, orderBy) {
				return BaseService.get('/api/archive/consumer/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'industryId':industryId, 'areaCode':areaCode, 'enteTypeId':enteTypeId, 'address':address, 'state':state, 'orderBy':orderBy
					});
			},
			
			//条件查询用户对象
			searchPage : function(orgCode, orgName, industryId, areaCode, enteTypeId, address, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/consumer/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'industryId':industryId, 'areaCode':areaCode, 'enteTypeId':enteTypeId, 'address':address, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //供应商	    
        SupplierService :{
			
			//增加供应商对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/supplier/add',mapParams);
			},
			
			//删除供应商对象
			del : function(id) {
				return BaseService.post('/api/archive/supplier/del',{
						'id':id
					});
			},
			
			//修改供应商对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/supplier/edit',mapParams);
			},
			
			//修改供应商对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/supplier/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出供应商对象到文件
			exportAll : function(areaCode, address, state, orderBy, fieldName) {
				return BaseService.download('/api/archive/supplier/exportall',{
						'areaCode':areaCode, 'address':address, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出供应商对象到文件
			exportDataTable : function(dataTable, areaCode, address, state, fieldName) {
				return BaseService.download('/api/archive/supplier/exportdatatable',{
						'dataTable':dataTable, 'areaCode':areaCode, 'address':address, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取供应商对象
			get : function(id) {
				return BaseService.get('/api/archive/supplier/get',{
						'id':id
					});
			},
			
			//查询所有供应商对象
			getAll : function() {
				return BaseService.get('/api/archive/supplier/getall');
			},
			
			//DataTable查询供应商对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/supplier/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示供应商对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/supplier/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询供应商对象
			searchAll : function(areaCode, address, state, orderBy) {
				return BaseService.get('/api/archive/supplier/searchall',{
						'areaCode':areaCode, 'address':address, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询供应商对象
			searchDataTable : function(dataTable, areaCode, address, state) {
				return BaseService.get('/api/archive/supplier/searchdatatable',{
						'dataTable':dataTable, 'areaCode':areaCode, 'address':address, 'state':state
					});
			},
			
			//条件查询供应商对象
			searchPage : function(areaCode, address, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/supplier/searchpage',{
						'areaCode':areaCode, 'address':address, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //拍摄方案	    
        ShootingSchemeService :{
			
			//增加拍摄方案对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/shootingScheme/add',mapParams);
			},
			
			//删除拍摄方案对象
			del : function(id) {
				return BaseService.post('/api/archive/shootingScheme/del',{
						'id':id
					});
			},
			
			//修改拍摄方案对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/shootingScheme/edit',mapParams);
			},
			
			//修改拍摄方案对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/shootingScheme/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出拍摄方案对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/shootingScheme/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取拍摄方案对象
			get : function(id) {
				return BaseService.get('/api/archive/shootingScheme/get',{
						'id':id
					});
			},
			
			//查询所有拍摄方案对象
			getAll : function() {
				return BaseService.get('/api/archive/shootingScheme/getall');
			},
			
			//DataTable查询拍摄方案对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/shootingScheme/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询dateTable 查询当天监控点的拍摄方案
			getDateTableByMonitorObject : function(dataTable, monitorObjectId) {
				return BaseService.post('/api/archive/shootingScheme/getDateTableByMonitorObject',{
						'dataTable':dataTable, 'monitorObjectId':monitorObjectId
					});
			},
			
			//通过监控对象 查询当天监控点的拍摄方案
			getMonitorItemByObject : function(monitorObjectId) {
				return BaseService.get('/api/archive/shootingScheme/getMonitorItemByObject',{
						'monitorObjectId':monitorObjectId
					});
			},
			
			//分页显示拍摄方案对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/shootingScheme/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //站点通讯录	    
        MonitorSiteContactService :{
			
			//增加站点通讯录对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/monitorSiteContact/add',mapParams);
			},
			
			//删除站点通讯录对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorSiteContact/del',{
						'id':id
					});
			},
			
			//修改站点通讯录对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/monitorSiteContact/edit',mapParams);
			},
			
			//修改站点通讯录对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorSiteContact/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出站点通讯录对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/monitorSiteContact/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//站点通讯录-导出
			exportSiteContact : function() {
				return BaseService.download('/api/archive/monitorSiteContact/exportSiteContact');
			},
			
			//根据ID获取站点通讯录对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorSiteContact/get',{
						'id':id
					});
			},
			
			//查询所有站点通讯录对象
			getAll : function() {
				return BaseService.get('/api/archive/monitorSiteContact/getall');
			},
			
			//站点通讯录DataTable
			getContactListDataTable : function(dataTable) {
				return BaseService.get('/api/archive/monitorSiteContact/getContactListDataTable',{
						'dataTable':dataTable
					});
			},
			
			//DataTable查询站点通讯录对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/monitorSiteContact/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示站点通讯录对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorSiteContact/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//查询单条站点通讯录对象
			getSingleContact : function(id) {
				return BaseService.get('/api/archive/monitorSiteContact/getSingleContact',{
						'id':id
					});
			},
			
			//站点通讯录-导入模板
			getSiteContactTemplate : function() {
				return BaseService.download('/api/archive/monitorSiteContact/getSiteContactTemplate');
			},
			
			//站点通讯录。导入 
			importSiteContact : function(upFile) {
				return BaseService.upload('/api/archive/monitorSiteContact/importSiteContact',{
						'upFile':upFile
					});
			},
		},
	    //测量点关系	    
        AmmeterRelationService :{
			
			//增加测量点关系对象
			add : function(ammeterRelations) {
				return BaseService.post('/api/archive/ammeterRelation/add',{
						'ammeterRelations':ammeterRelations
					});
			},
			
			//删除测量点关系对象
			del : function(id) {
				return BaseService.post('/api/archive/ammeterRelation/del',{
						'id':id
					});
			},
			
			//修改测量点关系对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/ammeterRelation/edit',mapParams);
			},
			
			//修改测量点关系对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/ammeterRelation/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出测量点关系对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/ammeterRelation/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取测量点关系对象
			get : function(id) {
				return BaseService.get('/api/archive/ammeterRelation/get',{
						'id':id
					});
			},
			
			//查询所有测量点关系对象
			getAll : function() {
				return BaseService.get('/api/archive/ammeterRelation/getall');
			},
			
			//DataTable查询测量点关系对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/ammeterRelation/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//通过父测量点 查询测量点关系对象
			getListByPAmmeter : function(pAmmeterId) {
				return BaseService.get('/api/archive/ammeterRelation/getListByPAmmeter',{
						'pAmmeterId':pAmmeterId
					});
			},
			
			//分页显示测量点关系对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/ammeterRelation/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //图元配置	    
        GraphEntityService :{
			
			//增加图元配置对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/graphEntity/add',mapParams);
			},
			
			//删除图元配置对象
			del : function(id) {
				return BaseService.post('/api/archive/graphEntity/del',{
						'id':id
					});
			},
			
			//修改图元配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/graphEntity/edit',mapParams);
			},
			
			//修改图元配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/graphEntity/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出图元配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/graphEntity/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取图元配置对象
			get : function(id) {
				return BaseService.get('/api/archive/graphEntity/get',{
						'id':id
					});
			},
			
			//查询所有图元配置对象
			getAll : function() {
				return BaseService.get('/api/archive/graphEntity/getall');
			},
			
			//查询监控对象的图元
			getAllByMonitorObjectType : function(monitorObjectTypeId) {
				return BaseService.get('/api/archive/graphEntity/getAllByMonitorObjectType',{
						'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//DataTable查询图元配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/graphEntity/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示图元配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/graphEntity/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //班次配置	    
        ClassConfigService :{
			
			//增加班次配置对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/classConfig/add',mapParams);
			},
			
			//删除班次配置对象
			del : function(id) {
				return BaseService.post('/api/archive/classConfig/del',{
						'id':id
					});
			},
			
			//修改班次配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/classConfig/edit',mapParams);
			},
			
			//修改班次配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/classConfig/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//重新计算班次能耗
			editRecalcBcpp : function(consumerId, dataAmmeterId, fromDt, toDt) {
				return BaseService.post('/api/archive/classConfig/editRecalcBcpp',{
						'consumerId':consumerId, 'dataAmmeterId':dataAmmeterId, 'fromDt':fromDt, 'toDt':toDt
					});
			},
			
			//通过DataTable导出班次配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/classConfig/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取班次配置对象
			get : function(id) {
				return BaseService.get('/api/archive/classConfig/get',{
						'id':id
					});
			},
			
			//查询所有班次配置对象
			getAll : function() {
				return BaseService.get('/api/archive/classConfig/getall');
			},
			
			//DataTable查询班次配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/classConfig/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//根据公司Id查询
			getListByOrgId : function(orgId) {
				return BaseService.get('/api/archive/classConfig/getListByOrgId',{
						'orgId':orgId
					});
			},
			
			//分页显示班次配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/classConfig/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//刷新公司的全部班次到缓存
			refreshAllToRedis : function(orgId) {
				return BaseService.get('/api/archive/classConfig/refreshAllToRedis',{
						'orgId':orgId
					});
			},
		},
	    //组织单元测量关系	    
        OrgAmmeterService :{
			
			//增加组织单元测量关系对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/orgAmmeter/add',mapParams);
			},
			
			//删除组织单元测量关系对象
			del : function(id) {
				return BaseService.post('/api/archive/orgAmmeter/del',{
						'id':id
					});
			},
			
			//修改组织单元测量关系对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/orgAmmeter/edit',mapParams);
			},
			
			//修改组织单元测量关系对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/orgAmmeter/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取组织单元测量关系对象
			get : function(id) {
				return BaseService.get('/api/archive/orgAmmeter/get',{
						'id':id
					});
			},
			
			//查询所有组织单元测量关系对象
			getAll : function() {
				return BaseService.get('/api/archive/orgAmmeter/getall');
			},
			
			//根据组织单元Id获取组织单元测量关系对象
			getAmmeter : function(orgId) {
				return BaseService.get('/api/archive/orgAmmeter/getAmmeter',{
						'orgId':orgId
					});
			},
			
			//根据组织单元Id获取组织单元计量数据类型
			getDataType : function(orgId) {
				return BaseService.get('/api/archive/orgAmmeter/getDataType',{
						'orgId':orgId
					});
			},
			
			//分页显示组织单元测量关系对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/orgAmmeter/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//查询 组织单元下 计量信息
			getPageByOrgId : function(dataTable, orgId) {
				return BaseService.post('/api/archive/orgAmmeter/getPageByOrgId',{
						'dataTable':dataTable, 'orgId':orgId
					});
			},
		},
	    //监控对象类型扩展字段	    
        MonitorTypeExtendService :{
			
			//增加监控对象类型扩展字段对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/monitorTypeExtend/add',mapParams);
			},
			
			//删除监控对象类型扩展字段对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorTypeExtend/del',{
						'id':id
					});
			},
			
			//修改监控对象类型扩展字段对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/monitorTypeExtend/edit',mapParams);
			},
			
			//修改监控对象类型扩展字段对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorTypeExtend/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改扩展参数值
			editValue : function(orgExtendId, monitorTypeExtendId, orgId, value) {
				return BaseService.post('/api/archive/monitorTypeExtend/editValue',{
						'orgExtendId':orgExtendId, 'monitorTypeExtendId':monitorTypeExtendId, 'orgId':orgId, 'value':value
					});
			},
			
			//通过DataTable导出监控对象类型扩展字段对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/monitorTypeExtend/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取监控对象类型扩展字段对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorTypeExtend/get',{
						'id':id
					});
			},
			
			//查询所有监控对象类型扩展字段对象
			getAll : function() {
				return BaseService.get('/api/archive/monitorTypeExtend/getall');
			},
			
			//Datatable显示监控对象类型扩展字段对
			getDatatableByObjectType : function(dataTable, monitorObjectTypeId) {
				return BaseService.get('/api/archive/monitorTypeExtend/getDatatableByObjectType',{
						'dataTable':dataTable, 'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//根据ID获取图元配置-控制类型-扩展信息
			getGraphEntityExtendInfo : function(monitorObjectTypeId) {
				return BaseService.get('/api/archive/monitorTypeExtend/getGraphEntityExtendInfo',{
						'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//分页显示监控对象类型扩展字段对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorTypeExtend/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //视频控制	    
        IVideoControlService :{
			
			//光圈加
			apertureAdd : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/apertureAdd',{
						'cameraId':cameraId
					});
			},
			
			//光圈减
			apertureMinus : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/apertureMinus',{
						'cameraId':cameraId
					});
			},
			
			//变焦加
			findTurningAdd : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/findTurningAdd',{
						'cameraId':cameraId
					});
			},
			
			//变焦减
			findTurningMinus : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/findTurningMinus',{
						'cameraId':cameraId
					});
			},
			
			//向下
			turnDown : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/turnDown',{
						'cameraId':cameraId
					});
			},
			
			//向左
			turnLeft : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/turnLeft',{
						'cameraId':cameraId
					});
			},
			
			//向左下
			turnLeftDown : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/turnLeftDown',{
						'cameraId':cameraId
					});
			},
			
			//向左上
			turnLeftUp : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/turnLeftUp',{
						'cameraId':cameraId
					});
			},
			
			//向右
			turnRight : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/turnRight',{
						'cameraId':cameraId
					});
			},
			
			//向右下
			turnRightDown : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/turnRightDown',{
						'cameraId':cameraId
					});
			},
			
			//向右上
			turnRightUp : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/turnRightUp',{
						'cameraId':cameraId
					});
			},
			
			//向上
			turnUp : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/turnUp',{
						'cameraId':cameraId
					});
			},
			
			//变倍加
			zoomAdd : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/zoomAdd',{
						'cameraId':cameraId
					});
			},
			
			//变倍减
			zoomMinus : function(cameraId) {
				return BaseService.post('/api/archive/iVideoControl/zoomMinus ',{
						'cameraId':cameraId
					});
			},
		},
	    //设备型号	    
        DeviceModelService :{
			
			//增加设备型号对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/deviceModel/add',mapParams);
			},
			
			//删除设备型号对象
			del : function(id) {
				return BaseService.post('/api/archive/deviceModel/del',{
						'id':id
					});
			},
			
			//修改设备型号对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/deviceModel/edit',mapParams);
			},
			
			//修改设备型号对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/deviceModel/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出设备型号对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/deviceModel/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取设备型号对象
			get : function(id) {
				return BaseService.get('/api/archive/deviceModel/get',{
						'id':id
					});
			},
			
			//查询所有设备型号对象
			getAll : function() {
				return BaseService.get('/api/archive/deviceModel/getall');
			},
			
			//DataTable查询设备型号对象
			getDataTable : function(dataTable) {
				return BaseService.post('/api/archive/deviceModel/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询设备型号通过监控对象类型
			getDeviceModelList : function(monitorObjectTypeId, orderBy) {
				return BaseService.get('/api/archive/deviceModel/getDeviceModelList',{
						'monitorObjectTypeId':monitorObjectTypeId, 'orderBy':orderBy
					});
			},
			
			//查询设备型号通过监控对象
			getDeviceModelListByObject : function(orgId) {
				return BaseService.get('/api/archive/deviceModel/getDeviceModelListByObject',{
						'orgId':orgId
					});
			},
			
			//分页显示设备型号对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/deviceModel/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控图	    
        MonitorDiagramService :{
			
			//增加监控图对象
			add : function(mapParams) {
						return BaseService.upload('/api/archive/monitorDiagram/add',mapParams);
			},
			
			//删除监控图对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorDiagram/del',{
						'id':id
					});
			},
			
			//修改监控图对象
			edit : function(mapParams) {
						return BaseService.upload('/api/archive/monitorDiagram/edit',mapParams);
			},
			
			//修改监控图对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorDiagram/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出监控图对象到文件
			exportAll : function(state, orderBy, fieldName) {
				return BaseService.download('/api/archive/monitorDiagram/exportall',{
						'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//根据ID获取监控图对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorDiagram/get',{
						'id':id
					});
			},
			
			//查询所有监控图对象
			getAll : function() {
				return BaseService.get('/api/archive/monitorDiagram/getall');
			},
			
			//站点下的监控图
			getBySiteId : function(siteId, monitorSysType, panelType) {
				return BaseService.get('/api/archive/monitorDiagram/getBySiteId',{
						'siteId':siteId, 'monitorSysType':monitorSysType, 'panelType':panelType
					});
			},
			
			//DataTable查询监控图对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/monitorDiagram/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//通过监控站id获取监控图
			getDatatableByMonitorSite : function(dataTable, monitorSiteId) {
				return BaseService.post('/api/archive/monitorDiagram/getDatatableByMonitorSite',{
						'dataTable':dataTable, 'monitorSiteId':monitorSiteId
					});
			},
			
			//分页显示监控图对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorDiagram/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询监控图对象
			searchAll : function(state, orderBy) {
				return BaseService.get('/api/archive/monitorDiagram/searchall',{
						'state':state, 'orderBy':orderBy
					});
			},
			
			//条件查询监控图对象
			searchPage : function(state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorDiagram/searchpage',{
						'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控对象	    
        MonitorObjectService :{
			
			//增加监控对象对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/monitorObject/add',mapParams);
			},
			
			//新增设备对象
			addDevice : function(mapParams) {
						return BaseService.post('/api/archive/monitorObject/addDevice',mapParams);
			},
			
			//新增总加组
			addGroupAmmeter : function(mapParams) {
						return BaseService.post('/api/archive/monitorObject/addGroupAmmeter',mapParams);
			},
			
			//新增位置区域
			addLocationArea : function(mapParams) {
						return BaseService.post('/api/archive/monitorObject/addLocationArea',mapParams);
			},
			
			//新增站点对象
			addMonitorSite : function(mapParams) {
						return BaseService.post('/api/archive/monitorObject/addMonitorSite',mapParams);
			},
			
			//删除监控对象对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorObject/del',{
						'id':id
					});
			},
			
			//删除下级对象
			delSubObject : function(id) {
				return BaseService.post('/api/archive/monitorObject/delSubObject',{
						'id':id
					});
			},
			
			//修改监控对象对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/monitorObject/edit',mapParams);
			},
			
			//修改监控对象对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorObject/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//调增减序号
			editIx : function(orgId, type) {
				return BaseService.post('/api/archive/monitorObject/editIx',{
						'orgId':orgId, 'type':type
					});
			},
			
			//修改监控对象对象的字段
			editfieldByType : function(id, fieldName, fieldValue, type) {
				return BaseService.post('/api/archive/monitorObject/editfieldByType',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'type':type
					});
			},
			
			//导出设备
			exportDevice : function(consumerId) {
				return BaseService.download('/api/archive/monitorObject/exportDevice',{
						'consumerId':consumerId
					});
			},
			
			//根据ID获取监控对象对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorObject/get',{
						'id':id
					});
			},
			
			//查询所有监控对象对象
			getAll : function() {
				return BaseService.get('/api/archive/monitorObject/getall');
			},
			
			//通过监控站和监控类型获取监控对象
			getAmmeterByMonitorSite : function(monitorSiteId, monitorType) {
				return BaseService.get('/api/archive/monitorObject/getAmmeterByMonitorSite',{
						'monitorSiteId':monitorSiteId, 'monitorType':monitorType
					});
			},
			
			//获取监控对象基础信息
			getBaseInfo : function(orgId) {
				return BaseService.get('/api/archive/monitorObject/getBaseInfo',{
						'orgId':orgId
					});
			},
			
			//监控站下的监控对象
			getBySiteId : function(siteId) {
				return BaseService.get('/api/archive/monitorObject/getBySiteId',{
						'siteId':siteId
					});
			},
			
			//获取用户下的监控对象树
			getConsumerMonitorObjectTree : function(consumerId) {
				return BaseService.get('/api/archive/monitorObject/getConsumerMonitorObjectTree',{
						'consumerId':consumerId
					});
			},
			
			//获取用户下所有站点一次设备树
			getConsumerTreeByClass : function(consumerId) {
				return BaseService.get('/api/archive/monitorObject/getConsumerTreeByClass',{
						'consumerId':consumerId
					});
			},
			
			//DataTable查询监控对象对象
			getDataTable : function(dataTable) {
				return BaseService.post('/api/archive/monitorObject/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//通过监控站和监控类型获取监控对象DataTable
			getDatatableByMonitorSite : function(dataTable, monitorSiteId, monitorType) {
				return BaseService.post('/api/archive/monitorObject/getDatatableByMonitorSite',{
						'dataTable':dataTable, 'monitorSiteId':monitorSiteId, 'monitorType':monitorType
					});
			},
			
			//查询设备信息和测量点信息通过监控对象id
			getDeviceAndAmmeter : function(id, monitorType) {
				return BaseService.get('/api/archive/monitorObject/getDeviceAndAmmeter',{
						'id':id, 'monitorType':monitorType
					});
			},
			
			//获取最新的监控对象的数据
			getLatestObjDatas : function(monitorObjId) {
				return BaseService.get('/api/archive/monitorObject/getLatestObjDatas',{
						'monitorObjId':monitorObjId
					});
			},
			
			//通过企业ID和监控类型获取监控对象
			getListByEnterpriseId : function(consumerId, monitorType) {
				return BaseService.get('/api/archive/monitorObject/getListByConsumerId',{
						'consumerId':consumerId, 'monitorType':monitorType
					});
			},
			
			//通过监控对象查询监控站下的类型监控对象
			getListByMonitorObject : function(monitorObjectId, monitorType) {
				return BaseService.get('/api/archive/monitorObject/getListByMonitorObject',{
						'monitorObjectId':monitorObjectId, 'monitorType':monitorType
					});
			},
			
			//通过监控站和监控类型获取监控对象
			getListByMonitorSite : function(monitorSiteId, monitorType) {
				return BaseService.get('/api/archive/monitorObject/getListByMonitorSite',{
						'monitorSiteId':monitorSiteId, 'monitorType':monitorType
					});
			},
			
			//根据ID获取监控对象对象
			getMap : function(id) {
				return BaseService.get('/api/archive/monitorObject/getMap',{
						'id':id
					});
			},
			
			//分页显示监控对象对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorObject/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取监控站下的位置区域和一次设备
			getPrimaryBySite : function(siteId) {
				return BaseService.get('/api/archive/monitorObject/getPrimaryBySite',{
						'siteId':siteId
					});
			},
			
			// 获取监控站下的位置区域和一次设备树
			getPrimaryDeviceTree : function(siteId) {
				return BaseService.get('/api/archive/monitorObject/getPrimaryDeviceTree',{
						'siteId':siteId
					});
			},
			
			//获取实时数据的参数
			getRealDataParams : function(siteId, objCodes, entityUrls) {
				return BaseService.post('/api/archive/monitorObject/getRealDataParams',{
						'siteId':siteId, 'objCodes':objCodes, 'entityUrls':entityUrls
					});
			},
			
			//获取实时数据
			getRealDatas : function(dataParamsJsonStr) {
				return BaseService.post('/api/archive/monitorObject/getRealDatas',{
						'dataParamsJsonStr':dataParamsJsonStr
					});
			},
			
			//获取监控站下的二次设备
			getSecondBySite : function(siteId) {
				return BaseService.get('/api/archive/monitorObject/getSecondBySite',{
						'siteId':siteId
					});
			},
			
			//获取监控站下的位置区域和二次设备树
			getSecondaryDeviceTree : function(siteId) {
				return BaseService.get('/api/archive/monitorObject/getSecondaryDeviceTree',{
						'siteId':siteId
					});
			},
			
			//DataTable查询下级对象
			getSubObjectByDataTable : function(dataTable, parentOrgId, orgType) {
				return BaseService.post('/api/archive/monitorObject/getSubObjectByDataTable',{
						'dataTable':dataTable, 'parentOrgId':parentOrgId, 'orgType':orgType
					});
			},
			
			//获取监控对象树节点基础信息
			getTreeNodeBaseInfo : function(monitorObjectId) {
				return BaseService.get('/api/archive/monitorObject/getTreeNodeBaseInfo',{
						'monitorObjectId':monitorObjectId
					});
			},
			
			//获取视频服务
			getVideoServer : function() {
				return BaseService.get('/api/archive/monitorObject/getVideoServer');
			},
			
			//查询关联电表最新实时电流电压
			queryUaIa : function(ammeterId) {
				return BaseService.get('/api/archive/monitorObject/queryUaIa',{
						'ammeterId':ammeterId
					});
			},
			
			//条件查询监控对象对象
			searchAll : function(orgCode, orgName, address, state, orderBy) {
				return BaseService.get('/api/archive/monitorObject/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'orderBy':orderBy
					});
			},
			
			//条件查询监控对象对象
			searchPage : function(orgCode, orgName, address, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorObject/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//修改监控对象对象的字段
			updateBaseInfo : function(orgId, fieldName, fieldValue) {
				return BaseService.post('/api/archive/monitorObject/updateBaseInfo',{
						'orgId':orgId, 'fieldName':fieldName, 'fieldValue':fieldValue
					});
			},
		},
	    //模板	    
        TemplateService :{
			
			//增加模板对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/template/add',mapParams);
			},
			
			//删除模板对象
			del : function(id) {
				return BaseService.post('/api/archive/template/del',{
						'id':id
					});
			},
			
			//修改模板对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/template/edit',mapParams);
			},
			
			//修改模板对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/template/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出模板对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/template/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取模板对象
			get : function(id) {
				return BaseService.get('/api/archive/template/get',{
						'id':id
					});
			},
			
			//查询所有模板对象
			getAll : function() {
				return BaseService.get('/api/archive/template/getall');
			},
			
			//查询所有模板对象（value/text 的格式）
			getAllForOption : function() {
				return BaseService.get('/api/archive/template/getAllForOption');
			},
			
			//DataTable查询模板对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/template/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示模板对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/template/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //设备文档	    
        DeviceFileService :{
			
			//增加设备文档
			add : function(deviceFile, upFile) {
				return BaseService.upload('/api/archive/deviceFile/add',{
						'deviceFile':deviceFile, 'upFile':upFile
					});
			},
			
			//删除设备文档对象
			del : function(id) {
				return BaseService.post('/api/archive/deviceFile/del',{
						'id':id
					});
			},
			
			//修改设备文档对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/deviceFile/edit',mapParams);
			},
			
			//修改设备文档对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/deviceFile/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出设备文档对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/deviceFile/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取设备文档对象
			get : function(id) {
				return BaseService.get('/api/archive/deviceFile/get',{
						'id':id
					});
			},
			
			//查询所有设备文档对象
			getAll : function() {
				return BaseService.get('/api/archive/deviceFile/getall');
			},
			
			//DataTable查询设备文档对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/deviceFile/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询设备文档和设备型号技术文档
			getDeviceAndModelFile : function(deviceId) {
				return BaseService.get('/api/archive/deviceFile/getDeviceAndModelFile',{
						'deviceId':deviceId
					});
			},
			
			//分页显示设备文档对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/deviceFile/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控数据项	    
        DataItemService :{
			
			//增加监控数据项对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/dataItem/add',mapParams);
			},
			
			//删除监控数据项对象
			del : function(id) {
				return BaseService.post('/api/archive/dataItem/del',{
						'id':id
					});
			},
			
			//修改监控数据项对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/dataItem/edit',mapParams);
			},
			
			//修改监控数据项对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/dataItem/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取监控数据项对象
			get : function(id) {
				return BaseService.get('/api/archive/dataItem/get',{
						'id':id
					});
			},
			
			//查询所有监控数据项对象
			getAll : function() {
				return BaseService.get('/api/archive/dataItem/getall');
			},
			
			//增加根据监控对象查询监控点的数据项
			getByPrimaryDevice : function(monitorObjectId, classifiedType) {
				return BaseService.get('/api/archive/dataItem/getDataItemsByObj',{
						'monitorObjectId':monitorObjectId, 'classifiedType':classifiedType
					});
			},
			
			//DataTable查询监控数据项对象
			getDataTable : function(dataTable, itemCategoryId) {
				return BaseService.post('/api/archive/dataItem/getdatatable',{
						'dataTable':dataTable, 'itemCategoryId':itemCategoryId
					});
			},
			
			//获取监控点分类
			getItemCategorys : function() {
				return BaseService.get('/api/archive/dataItem/getItemCategorys');
			},
			
			//分页显示监控数据项对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/dataItem/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据一次设备或位置区域查询还没有绑定监控点的数据项
			getUnUseByPrimary : function(monitorObjectId) {
				return BaseService.get('/api/archive/dataItem/getUnUseByPrimary',{
						'monitorObjectId':monitorObjectId
					});
			},
		},
	    //grafana账号信息与企业关系表	    
        GrafanaAccountService :{
			
			//增加grafana账号信息与企业关系表对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/grafanaAccount/add',mapParams);
			},
			
			//删除grafana账号信息与企业关系表对象
			del : function(id) {
				return BaseService.post('/api/archive/grafanaAccount/del',{
						'id':id
					});
			},
			
			//修改grafana账号信息与企业关系表对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/grafanaAccount/edit',mapParams);
			},
			
			//修改grafana账号信息与企业关系表对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/grafanaAccount/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改初始URL
			editUrl : function(enterpriseId, url) {
				return BaseService.post('/api/archive/grafanaAccount/editUrl',{
						'enterpriseId':enterpriseId, 'url':url
					});
			},
			
			//通过DataTable导出grafana账号信息与企业关系表对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/grafanaAccount/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取grafana账号信息与企业关系表对象
			get : function(id) {
				return BaseService.get('/api/archive/grafanaAccount/get',{
						'id':id
					});
			},
			
			//查询所有grafana账号信息与企业关系表对象
			getAll : function() {
				return BaseService.get('/api/archive/grafanaAccount/getall');
			},
			
			//DataTable查询grafana账号信息与企业关系表对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/grafanaAccount/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//登录账号设置对应Grafana账号Cookie
			getGrafanaToken : function(enterpriseId, rebuild) {
				return BaseService.get('/api/archive/grafanaAccount/getGrafanaToken',{
						'enterpriseId':enterpriseId, 'rebuild':rebuild
					});
			},
			
			//分页显示grafana账号信息与企业关系表对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/grafanaAccount/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //计量数据分类定义	    
        DataClassifyService :{
			
			//增加计量数据分类定义对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/dataClassify/add',mapParams);
			},
			
			//删除计量数据分类定义对象
			del : function(id) {
				return BaseService.post('/api/archive/dataClassify/del',{
						'id':id
					});
			},
			
			//修改计量数据分类定义对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/dataClassify/edit',mapParams);
			},
			
			//修改计量数据分类定义对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/dataClassify/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出计量数据分类定义对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/dataClassify/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取计量数据分类定义对象
			get : function(id) {
				return BaseService.get('/api/archive/dataClassify/get',{
						'id':id
					});
			},
			
			//查询所有计量数据分类定义对象
			getAll : function() {
				return BaseService.get('/api/archive/dataClassify/getall');
			},
			
			//DataTable查询计量数据分类定义对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/dataClassify/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//根据计量数据类型 查询量数据分类定义
			getListByDataType : function(dataType) {
				return BaseService.get('/api/archive/dataClassify/getListByDataType',{
						'dataType':dataType
					});
			},
			
			//获取用电性质通过节点和测量方式
			getListByOrgIdAndMeasureMode : function(orgId, measureMode) {
				return BaseService.get('/api/archive/dataClassify/getListByOrgIdAndMeasureMode',{
						'orgId':orgId, 'measureMode':measureMode
					});
			},
			
			//分页显示计量数据分类定义对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/dataClassify/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控对象类型	    
        MonitorObjectTypeService :{
			
			//增加监控对象类型对象
			add : function(monitorObjectTypeJson, showIconFile, listIconFile) {
				return BaseService.upload('/api/archive/monitorObjectType/add',{
						'monitorObjectTypeJson':monitorObjectTypeJson, 'showIconFile':showIconFile, 'listIconFile':listIconFile
					});
			},
			
			//删除监控对象类型对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorObjectType/del',{
						'id':id
					});
			},
			
			//修改监控对象类型对象
			edit : function(monitorObjectTypeJson, showIconFile, listIconFile) {
				return BaseService.upload('/api/archive/monitorObjectType/edit',{
						'monitorObjectTypeJson':monitorObjectTypeJson, 'showIconFile':showIconFile, 'listIconFile':listIconFile
					});
			},
			
			//修改监控对象类型对象的扩展字段
			editExtFieldDef : function(id, index, field, value) {
				return BaseService.post('/api/archive/monitorObjectType/editExtFieldDef',{
						'id':id, 'index':index, 'field':field, 'value':value
					});
			},
			
			//修改监控对象类型对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorObjectType/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取监控对象类型对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorObjectType/get',{
						'id':id
					});
			},
			
			//查询所有监控对象类型对象
			getAll : function() {
				return BaseService.get('/api/archive/monitorObjectType/getall');
			},
			
			//获取可添加的节点类型
			getChildrenClassifiedType : function(parentOrgId) {
				return BaseService.get('/api/archive/monitorObjectType/getChildrenClassifiedType',{
						'parentOrgId':parentOrgId
					});
			},
			
			//DataTable查询监控对象类型对象
			getDataTable : function(dataTable) {
				return BaseService.post('/api/archive/monitorObjectType/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//根据ID获取监控对象类型对象
			getMap : function(id) {
				return BaseService.get('/api/archive/monitorObjectType/getMap',{
						'id':id
					});
			},
			
			//分页显示监控对象类型对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorObjectType/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据名称或编码查询对象类型
			getPageByNameOrCode : function(dataTable, classifiedType, parentOrgId) {
				return BaseService.post('/api/archive/monitorObjectType/getPageByNameOrCode',{
						'dataTable':dataTable, 'classifiedType':classifiedType, 'parentOrgId':parentOrgId
					});
			},
		},
	    //企业能耗总览配置	    
        ConsumerEnergyConfigService :{
			
			//增加企业能耗总览配置对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/consumerEnergyConfig/add',mapParams);
			},
			
			//删除企业能耗总览配置对象
			del : function(id) {
				return BaseService.post('/api/archive/consumerEnergyConfig/del',{
						'id':id
					});
			},
			
			//修改企业能耗总览配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/consumerEnergyConfig/edit',mapParams);
			},
			
			//修改企业能耗总览配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/consumerEnergyConfig/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出企业能耗总览配置对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/consumerEnergyConfig/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取企业能耗总览配置对象
			get : function(id) {
				return BaseService.get('/api/archive/consumerEnergyConfig/get',{
						'id':id
					});
			},
			
			//查询所有企业能耗总览配置对象
			getAll : function() {
				return BaseService.get('/api/archive/consumerEnergyConfig/getall');
			},
			
			//根据企业ID获取
			getByOrgId : function(orgId) {
				return BaseService.get('/api/archive/consumerEnergyConfig/getByOrgId',{
						'orgId':orgId
					});
			},
			
			//DataTable查询企业能耗总览配置对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/consumerEnergyConfig/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示企业能耗总览配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/consumerEnergyConfig/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //设备	    
        DeviceService :{
			
			//增加设备对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/device/add',mapParams);
			},
			
			//删除设备对象
			del : function(id) {
				return BaseService.post('/api/archive/device/del',{
						'id':id
					});
			},
			
			//下载设备导入模板
			downloadTemplate : function(monitorObjectTypes) {
				return BaseService.download('/api/archive/device/downloadTemplate',{
						'monitorObjectTypes':monitorObjectTypes
					});
			},
			
			//修改设备对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/device/edit',mapParams);
			},
			
			//修改设备对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/device/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出设备对象到文件
			exportAll : function(state, orderBy, fieldName) {
				return BaseService.download('/api/archive/device/exportall',{
						'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出设备对象到文件
			exportDataTable : function(dataTable, state, fieldName) {
				return BaseService.download('/api/archive/device/exportdatatable',{
						'dataTable':dataTable, 'state':state, 'fieldName':fieldName
					});
			},
			
			//导出设备
			exportDevice : function(monitorSiteId) {
				return BaseService.download('/api/archive/device/exportDevice',{
						'monitorSiteId':monitorSiteId
					});
			},
			
			//根据ID获取设备对象
			get : function(id) {
				return BaseService.get('/api/archive/device/get',{
						'id':id
					});
			},
			
			//查询所有设备对象
			getAll : function() {
				return BaseService.get('/api/archive/device/getall');
			},
			
			//根据监控站查询设备和监控对象类型
			getBySite : function(siteId, objectSchemaId) {
				return BaseService.get('/api/archive/device/getBySite',{
						'siteId':siteId, 'objectSchemaId':objectSchemaId
					});
			},
			
			//DataTable查询设备对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/device/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//根据ID获取设备对象
			getItemsOfDevice : function(deviceId) {
				return BaseService.get('/api/archive/device/getItemsOfDevice',{
						'deviceId':deviceId
					});
			},
			
			//分页显示设备对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/device/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//导入设备
			importDevice : function(params, upFile) {
				return BaseService.upload('/api/archive/device/importDevice',{
						'params':params, 'upFile':upFile
					});
			},
			
			//条件查询设备对象
			searchAll : function(orgId, state, orderBy) {
				return BaseService.get('/api/archive/device/searchall',{
						'orgId':orgId, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询设备对象
			searchDataTable : function(dataTable, state) {
				return BaseService.get('/api/archive/device/searchdatatable',{
						'dataTable':dataTable, 'state':state
					});
			},
			
			//条件查询设备对象
			searchPage : function(state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/device/searchpage',{
						'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //对象扩展属性	    
        OrgExtendService :{
			
			//增加对象扩展属性对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/orgExtend/add',mapParams);
			},
			
			//删除对象扩展属性对象
			del : function(id) {
				return BaseService.post('/api/archive/orgExtend/del',{
						'id':id
					});
			},
			
			//修改对象扩展属性对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/orgExtend/edit',mapParams);
			},
			
			//修改对象扩展属性对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/orgExtend/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出对象扩展属性对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/orgExtend/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取对象扩展属性对象
			get : function(id) {
				return BaseService.get('/api/archive/orgExtend/get',{
						'id':id
					});
			},
			
			//查询所有对象扩展属性对象
			getAll : function() {
				return BaseService.get('/api/archive/orgExtend/getall');
			},
			
			//DataTable查询对象扩展属性对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/orgExtend/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//获取监控对象扩展参数
			getExtendByMonitorObjectId : function(monitorObjectId) {
				return BaseService.get('/api/archive/orgExtend/getExtendByMonitorObjectId',{
						'monitorObjectId':monitorObjectId
					});
			},
			
			//分页显示对象扩展属性对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/orgExtend/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取监控对象个性化参数
			getPersonalByMonitorObjectId : function(monitorObjectId) {
				return BaseService.get('/api/archive/orgExtend/getPersonalByMonitorObjectId',{
						'monitorObjectId':monitorObjectId
					});
			},
		},
	    //图档资料	    
        OrgFileService :{
			
			//增加图档资料对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/orgFile/add',mapParams);
			},
			
			//删除图档资料对象
			del : function(id) {
				return BaseService.post('/api/archive/orgFile/del',{
						'id':id
					});
			},
			
			//修改图档资料对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/orgFile/edit',mapParams);
			},
			
			//修改图档资料对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/orgFile/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出图档资料对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/orgFile/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取图档资料对象
			get : function(id) {
				return BaseService.get('/api/archive/orgFile/get',{
						'id':id
					});
			},
			
			//查询所有图档资料对象
			getAll : function(fileType, orgId) {
				return BaseService.get('/api/archive/orgFile/getall',{
						'fileType':fileType, 'orgId':orgId
					});
			},
			
			//DataTable查询图档资料对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/orgFile/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示图档资料对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/orgFile/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//上传图档
			upload : function(orgId, orgFile) {
				return BaseService.upload('/api/archive/orgFile/upload',{
						'orgId':orgId, 'orgFile':orgFile
					});
			},
		},
	    //设备型号技术文档	    
        DeviceModelFileService :{
			
			//增加设备型号技术文档对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/deviceModelFile/add',mapParams);
			},
			
			//删除设备型号技术文档对象
			del : function(id) {
				return BaseService.post('/api/archive/deviceModelFile/del',{
						'id':id
					});
			},
			
			//修改设备型号技术文档对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/deviceModelFile/edit',mapParams);
			},
			
			//修改设备型号技术文档对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/deviceModelFile/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出设备型号技术文档对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/deviceModelFile/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取设备型号技术文档对象
			get : function(id) {
				return BaseService.get('/api/archive/deviceModelFile/get',{
						'id':id
					});
			},
			
			//查询所有设备型号技术文档对象
			getAll : function() {
				return BaseService.get('/api/archive/deviceModelFile/getall');
			},
			
			//DataTable查询设备型号技术文档对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/deviceModelFile/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示设备型号技术文档对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/deviceModelFile/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //集团	    
        GroupService :{
			
			//增加集团对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/group/add',mapParams);
			},
			
			//增加集团对象
			addGroup : function(mapParams) {
						return BaseService.upload('/api/archive/group/addGroup',mapParams);
			},
			
			//删除集团对象
			del : function(id) {
				return BaseService.post('/api/archive/group/del',{
						'id':id
					});
			},
			
			//修改集团对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/group/edit',mapParams);
			},
			
			//修改集团对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/group/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改集团对象
			editGroup : function(mapParams) {
						return BaseService.upload('/api/archive/group/editGroup',mapParams);
			},
			
			//通过DataTable导出集团对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/group/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取集团对象
			get : function(id) {
				return BaseService.get('/api/archive/group/get',{
						'id':id
					});
			},
			
			//查询所有集团对象
			getAll : function() {
				return BaseService.get('/api/archive/group/getall');
			},
			
			//DataTable查询集团对象
			getDataTable : function(dataTable) {
				return BaseService.post('/api/archive/group/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示集团对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/group/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控站	    
        MonitorSiteService :{
			
			//增加监控站对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/monitorSite/add',mapParams);
			},
			
			//删除监控站对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorSite/del',{
						'id':id
					});
			},
			
			//修改监控站对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/monitorSite/edit',mapParams);
			},
			
			//修改监控站对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorSite/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//导出监控站对象到文件
			exportAll : function(orgCode, orgName, address, state, orderBy, fieldName) {
				return BaseService.download('/api/archive/monitorSite/exportall',{
						'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出监控站对象到文件
			exportDataTable : function(dataTable, orgCode, orgName, address, state, fieldName) {
				return BaseService.download('/api/archive/monitorSite/exportdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取监控站对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorSite/get',{
						'id':id
					});
			},
			
			//查询所有监控站对象
			getAll : function() {
				return BaseService.get('/api/archive/monitorSite/getall');
			},
			
			//查询所有站点，按前端要求，变量名设为id与text
			getAllSiteName : function() {
				return BaseService.get('/api/archive/monitorSite/getAllSiteName');
			},
			
			//根据输入的名称联想相关的站点
			getAssociationByName : function(inputName) {
				return BaseService.get('/api/archive/monitorSite/getAssociationByName',{
						'inputName':inputName
					});
			},
			
			//获取用户下的站点列表
			getByConsumerId : function(consumerId) {
				return BaseService.get('/api/archive/monitorSite/getByConsumerId',{
						'consumerId':consumerId
					});
			},
			
			//获取用户下的站点列表
			getConsumerAndSite : function() {
				return BaseService.get('/api/archive/monitorSite/getConsumerAndSite');
			},
			
			//获取有效的站点个数
			getCount : function() {
				return BaseService.get('/api/archive/monitorSite/getCount');
			},
			
			//DataTable 查询监控站，站点下的监控对象和监控点
			getDataTable : function(dataTable) {
				return BaseService.post('/api/archive/monitorSite/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//获取站点列表和数据中心Id
			getListSiteAndAmmeter : function(consumerId) {
				return BaseService.get('/api/archive/monitorSite/getListSiteAndAmmeter',{
						'consumerId':consumerId
					});
			},
			
			//区域下的监控站
			getMonitorSiteByArea : function() {
				return BaseService.get('/api/archive/monitorSite/getMonitorSiteByArea');
			},
			
			//获取站点数据
			getMonitorSiteDetails : function(siteId) {
				return BaseService.get('/api/archive/monitorSite/getMonitorSiteDetails',{
						'siteId':siteId
					});
			},
			
			//获取监控站地图分布统计
			getMonitorSiteDistribution : function() {
				return BaseService.get('/api/archive/monitorSite/getMonitorSiteDistribution');
			},
			
			//获得所有的监控站，按区域划分
			getMonitorSiteOrderedByArea : function() {
				return BaseService.get('/api/archive/monitorSite/getMonitorSiteOrderedByArea');
			},
			
			//分页显示监控站对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorSite/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取单个站点信息和设备统计信息
			getSiteAndDeviceCnt : function(monitorSiteId) {
				return BaseService.get('/api/archive/monitorSite/getSiteAndDeviceCnt',{
						'monitorSiteId':monitorSiteId
					});
			},
			
			//站点用电
			getSiteElecDatas : function(dataAmmeterId, dataCodes, interval, beginDate, endDate) {
				return BaseService.get('/api/archive/monitorSite/getSiteElecDatas',{
						'dataAmmeterId':dataAmmeterId, 'dataCodes':dataCodes, 'interval':interval, 'beginDate':beginDate, 'endDate':endDate
					});
			},
			
			//根据企业id查站列表
			getSiteListByEnterpriseId : function(enterpriseId) {
				return BaseService.get('/api/archive/monitorSite/getSiteListByEnterpriseId',{
						'enterpriseId':enterpriseId
					});
			},
			
			//根据登录账号获取可见站点列表
			getSiteListByLoginInfo : function(enterpriseId) {
				return BaseService.get('/api/archive/monitorSite/getSiteListByLoginInfo',{
						'enterpriseId':enterpriseId
					});
			},
			
			//获取单个站点下的统计信息
			oneMonitorSiteStatis : function(monitorSiteId) {
				return BaseService.get('/api/archive/monitorSite/oneMonitorSiteStatis',{
						'monitorSiteId':monitorSiteId
					});
			},
			
			//条件查询监控站对象
			searchAll : function(orgCode, orgName, address, state, orderBy) {
				return BaseService.get('/api/archive/monitorSite/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询监控站对象
			searchDataTable : function(dataTable, orgCode, orgName, address, state) {
				return BaseService.get('/api/archive/monitorSite/searchdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state
					});
			},
			
			//条件查询监控站对象
			searchPage : function(orgCode, orgName, address, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorSite/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//给监控站选定模板
			selectTemplate : function(siteId, templateId) {
				return BaseService.post('/api/archive/monitorSite/selectTemplate',{
						'siteId':siteId, 'templateId':templateId
					});
			},
		},
	    //管理单元	    
        ConsumerUnitService :{
			
			//增加管理单元对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/consumerUnit/add',mapParams);
			},
			
			//删除管理单元对象
			del : function(id) {
				return BaseService.post('/api/archive/consumerUnit/del',{
						'id':id
					});
			},
			
			//修改管理单元对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/consumerUnit/edit',mapParams);
			},
			
			//修改管理单元对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/consumerUnit/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//调增减序号
			editIx : function(pOrgId, orgId, type) {
				return BaseService.post('/api/archive/consumerUnit/editIx',{
						'pOrgId':pOrgId, 'orgId':orgId, 'type':type
					});
			},
			
			//导出管理单元对象到文件
			exportAll : function(orgCode, orgName, address, state, orderBy, fieldName) {
				return BaseService.download('/api/archive/consumerUnit/exportall',{
						'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出管理单元对象到文件
			exportDataTable : function(dataTable, orgCode, orgName, address, state, fieldName) {
				return BaseService.download('/api/archive/consumerUnit/exportdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取管理单元对象
			get : function(id) {
				return BaseService.get('/api/archive/consumerUnit/get',{
						'id':id
					});
			},
			
			//查询所有管理单元对象
			getAll : function() {
				return BaseService.get('/api/archive/consumerUnit/getall');
			},
			
			//查询用户及全部子管理单元
			getByConsumer : function(consumerId, dataClassifyId) {
				return BaseService.get('/api/archive/consumerUnit/getByConsumer',{
						'consumerId':consumerId, 'dataClassifyId':dataClassifyId
					});
			},
			
			//DataTable查询管理单元对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/consumerUnit/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询父级的下级管理单元
			getListBypOrgId : function(pOrgId) {
				return BaseService.get('/api/archive/consumerUnit/getListBypOrgId',{
						'pOrgId':pOrgId
					});
			},
			
			//分页显示管理单元对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/consumerUnit/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询管理单元对象
			searchAll : function(orgCode, orgName, address, state, orderBy) {
				return BaseService.get('/api/archive/consumerUnit/searchall',{
						'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询管理单元对象
			searchDataTable : function(dataTable, orgCode, orgName, address, state) {
				return BaseService.get('/api/archive/consumerUnit/searchdatatable',{
						'dataTable':dataTable, 'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state
					});
			},
			
			//条件查询管理单元对象
			searchPage : function(orgCode, orgName, address, state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/consumerUnit/searchpage',{
						'orgCode':orgCode, 'orgName':orgName, 'address':address, 'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//设置管理单元默认序号
			setIxToAll : function() {
				return BaseService.post('/api/archive/consumerUnit/setIxToAll');
			},
		},
	    //远程控制	    
        IRemoteControlService :{
			
			//中电云端下发控制指令
			cloudCmdSet : function(mapParams) {
						return BaseService.post('/api/archive/iRemoteControl/cloudCmdSet',mapParams);
			},
			
			//发送控制命令
			sendControl : function(iotId, identifier, value) {
				return BaseService.post('/api/archive/iRemoteControl/sendControl',{
						'iotId':iotId, 'identifier':identifier, 'value':value
					});
			},
			
			//发送控制命令
			sendRelayControl : function(monitorItemId, displayMpNo, mpNos_status) {
				return BaseService.post('/api/archive/iRemoteControl/sendRelayControlOld',{
						'monitorItemId':monitorItemId, 'displayMpNo':displayMpNo, 'mpNos_status':mpNos_status
					});
			},
			
			//发送控制命令
			sendRelayControlIOT : function(monitorItemId, displayMpNo, mpNos_status, isRead) {
				return BaseService.post('/api/archive/iRemoteControl/sendRelayControl',{
						'monitorItemId':monitorItemId, 'displayMpNo':displayMpNo, 'mpNos_status':mpNos_status, 'isRead':isRead
					});
			},
		},
	    //析标系数表	    
        CoefficientService :{
			
			//增加析标系数表对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/coefficient/add',mapParams);
			},
			
			//删除析标系数表对象
			del : function(id) {
				return BaseService.post('/api/archive/coefficient/del',{
						'id':id
					});
			},
			
			//修改析标系数表对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/coefficient/edit',mapParams);
			},
			
			//批量设置
			editAll : function(json) {
				return BaseService.post('/api/archive/coefficient/editAll',{
						'json':json
					});
			},
			
			//导出月能源账单
			exportMonthData : function(orgId, dt, type) {
				return BaseService.download('/api/archive/coefficient/exportMonthData',{
						'orgId':orgId, 'dt':dt, 'type':type
					});
			},
			
			//导出年能源账单
			exportYearData : function(orgId, startDt, endDt, type) {
				return BaseService.download('/api/archive/coefficient/exportYearData',{
						'orgId':orgId, 'startDt':startDt, 'endDt':endDt, 'type':type
					});
			},
			
			//根据ID获取析标系数表对象
			get : function(id) {
				return BaseService.get('/api/archive/coefficient/get',{
						'id':id
					});
			},
			
			//查询所有析标系数表对象
			getAll : function() {
				return BaseService.get('/api/archive/coefficient/getall');
			},
			
			//根据用户查询所有析标系数表对象
			getAllByOrgId : function(orgId) {
				return BaseService.get('/api/archive/coefficient/getAllByOrgId',{
						'orgId':orgId
					});
			},
			
			//获得月能源账单
			getMonthData : function(orgId, dt) {
				return BaseService.get('/api/archive/coefficient/getMonthData',{
						'orgId':orgId, 'dt':dt
					});
			},
			
			//获得年能源账单
			getYearData : function(orgId, startDt, endDt) {
				return BaseService.get('/api/archive/coefficient/getYearData',{
						'orgId':orgId, 'startDt':startDt, 'endDt':endDt
					});
			},
		},
	    //设备扩展属性	    
        DeviceExtendService :{
			
			//修改设备扩展属性对象
			addOrEdit : function(mapParams) {
						return BaseService.post('/api/archive/deviceExtend/addOrEdit',mapParams);
			},
			
			//删除设备扩展属性对象
			del : function(id) {
				return BaseService.post('/api/archive/deviceExtend/del',{
						'id':id
					});
			},
			
			//修改设备扩展属性对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/deviceExtend/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取设备扩展属性对象
			get : function(id) {
				return BaseService.get('/api/archive/deviceExtend/get',{
						'id':id
					});
			},
			
			//查询所有设备扩展属性对象
			getAll : function() {
				return BaseService.get('/api/archive/deviceExtend/getall');
			},
			
			//查询设备配置信息和扩展字段
			getDeviceExtendList : function(deviceId, monitorObjectTypeId) {
				return BaseService.get('/api/archive/deviceExtend/getDeviceExtendList',{
						'deviceId':deviceId, 'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//分页显示设备扩展属性对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/deviceExtend/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控点	    
        MonitorItemService :{
			
			//增加一个监控点
			addMonitorItem : function(mapParams) {
						return BaseService.post('/api/archive/monitorItem/addMonitorItem',mapParams);
			},
			
			//批量增加监控点
			addMonitorItems : function(dataItemIds, ammeterId, siteId) {
				return BaseService.post('/api/archive/monitorItem/addMonitorItems',{
						'dataItemIds':dataItemIds, 'ammeterId':ammeterId, 'siteId':siteId
					});
			},
			
			//获取站点下所有的监控点信息
			allUnderSiteItems : function(siteId, dataTypes) {
				return BaseService.get('/api/archive/monitorItem/allUnderSiteItems',{
						'siteId':siteId, 'dataTypes':dataTypes
					});
			},
			
			//获取当前监控点的遥调、遥控监控点 
			controlAdjustItems : function(monitorItemId) {
				return BaseService.get('/api/archive/monitorItem/controlAdjustItems',{
						'monitorItemId':monitorItemId
					});
			},
			
			//删除监控点对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorItem/del',{
						'id':id
					});
			},
			
			//修改监控点对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/monitorItem/edit',mapParams);
			},
			
			//修改监控点对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorItem/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//修改监控点对象
			editMonitorItems : function(monitorItems) {
				return BaseService.post('/api/archive/monitorItem/editMonitorItems',{
						'monitorItems':monitorItems
					});
			},
			
			//根据ID获取监控点对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorItem/get',{
						'id':id
					});
			},
			
			//查询所有监控点对象
			getAll : function() {
				return BaseService.get('/api/archive/monitorItem/getall');
			},
			
			//获取站点下摄像头监控点
			getCameraItem : function(siteId) {
				return BaseService.get('/api/archive/monitorItem/getCameraItem',{
						'siteId':siteId
					});
			},
			
			//查询监控对象的所有采集设备
			getCjObjectByObject : function(monitorObjectId) {
				return BaseService.get('/api/archive/monitorItem/getCjObjectByObject',{
						'monitorObjectId':monitorObjectId
					});
			},
			
			//获取站点下控制监控点
			getControlItem : function(siteId) {
				return BaseService.get('/api/archive/monitorItem/getControlItem',{
						'siteId':siteId
					});
			},
			
			//查询 查询监控对象（采集设备）下的数据项数据
			getDataItemsByObject : function(monitorObjectId, orgId) {
				return BaseService.get('/api/archive/monitorItem/getDataItemsByObject',{
						'monitorObjectId':monitorObjectId, 'orgId':orgId
					});
			},
			
			//查询dateTable 监控站下的所有监控点
			getDateTableBySite : function(dataTable, monitorSiteId) {
				return BaseService.post('/api/archive/monitorItem/getDateTableBySite',{
						'dataTable':dataTable, 'monitorSiteId':monitorSiteId
					});
			},
			
			//获取监控点的历史数据 
			getHistoryDatas : function(monitorItemId, dataType, interval, startTime, endTime) {
				return BaseService.get('/api/archive/monitorItem/getHistoryDatas',{
						'monitorItemId':monitorItemId, 'dataType':dataType, 'interval':interval, 'startTime':startTime, 'endTime':endTime
					});
			},
			
			//获取站点位置区域或二次设备对应的监控点
			getListBySecondaryDeviceDataTable : function(dataTable, orgId) {
				return BaseService.get('/api/archive/monitorItem/getListBySecondaryDeviceDataTable',{
						'dataTable':dataTable, 'orgId':orgId
					});
			},
			
			//通过监控对象 查询监控点
			getListMonitorItems : function(orgId, monitorType) {
				return BaseService.get('/api/archive/monitorItem/getListMonitorItems',{
						'orgId':orgId, 'monitorType':monitorType
					});
			},
			
			//查询一次设备（采集设备）下的数据项数据
			getMonitorDataItemsByObject : function(orgId) {
				return BaseService.get('/api/archive/monitorItem/getMonitorDataItemsByObject',{
						'orgId':orgId
					});
			},
			
			//查询二次设备下的数据项数据
			getMonitorDataItemsBySecondaryDevice : function(orgId) {
				return BaseService.get('/api/archive/monitorItem/getMonitorDataItemsBySecondaryDevice',{
						'orgId':orgId
					});
			},
			
			//查询监控站下的所有监控点
			getMonitorItemsBySite : function(monitorSiteId) {
				return BaseService.get('/api/archive/monitorItem/getMonitorItemsBySite',{
						'monitorSiteId':monitorSiteId
					});
			},
			
			//分页显示监控点对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorItem/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//获取当前监控点的遥视监控点
			televideoMonitorItems : function(monitorItemId) {
				return BaseService.get('/api/archive/monitorItem/televideoMonitorItems',{
						'monitorItemId':monitorItemId
					});
			},
			
			//视频历史记录 
			videoHistoryDatas : function(monitorItemIds, dataTypes, currMonitorItemId) {
				return BaseService.get('/api/archive/monitorItem/videoHistoryDatas',{
						'monitorItemIds':monitorItemIds, 'dataTypes':dataTypes, 'currMonitorItemId':currMonitorItemId
					});
			},
		},
	    //设备事件跟踪	    
        DeviceTraceService :{
			
			//增加设备事件跟踪对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/deviceTrace/add',mapParams);
			},
			
			//删除设备事件跟踪对象
			del : function(id) {
				return BaseService.post('/api/archive/deviceTrace/del',{
						'id':id
					});
			},
			
			//修改设备事件跟踪对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/deviceTrace/edit',mapParams);
			},
			
			//修改设备事件跟踪对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/deviceTrace/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出设备事件跟踪对象到文件
			exportDataTable : function(dataTable, state, fieldName) {
				return BaseService.download('/api/archive/deviceTrace/exportdatatable',{
						'dataTable':dataTable, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取设备事件跟踪对象
			get : function(id) {
				return BaseService.get('/api/archive/deviceTrace/get',{
						'id':id
					});
			},
			
			//查询所有设备事件跟踪对象
			getAll : function() {
				return BaseService.get('/api/archive/deviceTrace/getall');
			},
			
			//DataTable查询设备事件跟踪对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/deviceTrace/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示设备事件跟踪对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/deviceTrace/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//条件查询设备事件跟踪对象
			searchAll : function(deviceId, orderBy) {
				return BaseService.get('/api/archive/deviceTrace/searchall',{
						'deviceId':deviceId, 'orderBy':orderBy
					});
			},
			
			//DataTable条件查询设备事件跟踪对象
			searchDataTable : function(dataTable, state) {
				return BaseService.get('/api/archive/deviceTrace/searchdatatable',{
						'dataTable':dataTable, 'state':state
					});
			},
			
			//条件查询设备事件跟踪对象
			searchPage : function(state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/deviceTrace/searchpage',{
						'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控对象的监控点	    
        MonitorObjectItemService :{
			
			//增加监控对象的监控点对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/monitorObjectItem/add',mapParams);
			},
			
			//增加监控对象的监控点对象
			addMulti : function(monitorObjectItems) {
				return BaseService.post('/api/archive/monitorObjectItem/addMulti',{
						'monitorObjectItems':monitorObjectItems
					});
			},
			
			//删除监控对象的监控点对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorObjectItem/del',{
						'id':id
					});
			},
			
			//修改监控对象的监控点对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/monitorObjectItem/edit',mapParams);
			},
			
			//修改监控对象的监控点对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorObjectItem/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//通过DataTable导出监控对象的监控点对象到文件
			exportDataTable : function(dataTable, fieldName) {
				return BaseService.download('/api/archive/monitorObjectItem/exportdatatable',{
						'dataTable':dataTable, 'fieldName':fieldName
					});
			},
			
			//根据ID获取监控对象的监控点对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorObjectItem/get',{
						'id':id
					});
			},
			
			//查询所有监控对象的监控点对象
			getAll : function() {
				return BaseService.get('/api/archive/monitorObjectItem/getall');
			},
			
			//根据监控点查询关联的监控对象
			getByMonitorItem : function(itemId) {
				return BaseService.get('/api/archive/monitorObjectItem/getByMonitorItem',{
						'itemId':itemId
					});
			},
			
			//DataTable查询监控对象的监控点对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/monitorObjectItem/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//分页显示监控对象的监控点对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorObjectItem/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //测量点	    
        AmmeterService :{
			
			//增加测量点对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/ammeter/add',mapParams);
			},
			
			//删除测量点对象
			del : function(id) {
				return BaseService.post('/api/archive/ammeter/del',{
						'id':id
					});
			},
			
			//修改测量点对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/ammeter/edit',mapParams);
			},
			
			//修改计量名称
			editDataAmmeter : function(mapParams) {
						return BaseService.post('/api/archive/ammeter/editDataAmmeter',mapParams);
			},
			
			//修改测量点对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/ammeter/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//重新计算
			editRecalculation : function(ammeterId, fromDt, toDt, type, interval) {
				return BaseService.post('/api/archive/ammeter/editRecalculation',{
						'ammeterId':ammeterId, 'fromDt':fromDt, 'toDt':toDt, 'type':type, 'interval':interval
					});
			},
			
			//导出测量点对象到文件
			exportAll : function(state, orderBy, fieldName) {
				return BaseService.download('/api/archive/ammeter/exportall',{
						'state':state, 'orderBy':orderBy, 'fieldName':fieldName
					});
			},
			
			//通过DataTable导出测量点对象到文件
			exportDataTable : function(dataTable, state, fieldName) {
				return BaseService.download('/api/archive/ammeter/exportdatatable',{
						'dataTable':dataTable, 'state':state, 'fieldName':fieldName
					});
			},
			
			//根据ID获取测量点对象
			get : function(id) {
				return BaseService.get('/api/archive/ammeter/get',{
						'id':id
					});
			},
			
			//查询所有测量点对象
			getAll : function() {
				return BaseService.get('/api/archive/ammeter/getall');
			},
			
			//查询  用户下的计量表与监控站的树形关系
			getAmmetersAndSite : function(consumerId, dataType) {
				return BaseService.get('/api/archive/ammeter/getAmmetersAndSite',{
						'consumerId':consumerId, 'dataType':dataType
					});
			},
			
			//DataTable查询测量点对象
			getDataTable : function(dataTable) {
				return BaseService.get('/api/archive/ammeter/getdatatable',{
						'dataTable':dataTable
					});
			},
			
			//查询  通讯管理机的采集设备
			getListByConsumerId : function(orgId) {
				return BaseService.get('/api/archive/ammeter/getListByConsumerId',{
						'orgId':orgId
					});
			},
			
			//根据ID获取测量点
			getMap : function(id) {
				return BaseService.get('/api/archive/ammeter/getMap',{
						'id':id
					});
			},
			
			//分页显示测量点对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/ammeter/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据用户名称查询  获取数据中心电表
			getPageByConsumerAndDataType : function(dataTable, consumerId, pAmmeterId, dataType) {
				return BaseService.post('/api/archive/ammeter/getPageByConsumerAndDataType',{
						'dataTable':dataTable, 'consumerId':consumerId, 'pAmmeterId':pAmmeterId, 'dataType':dataType
					});
			},
			
			//根据用户名称查询  获取数据中心电表
			getSjzxAmmeters : function(consumerName) {
				return BaseService.get('/api/archive/ammeter/getSjzxAmmeters',{
						'consumerName':consumerName
					});
			},
			
			//条件查询测量点对象
			searchAll : function(state, orderBy) {
				return BaseService.get('/api/archive/ammeter/searchall',{
						'state':state, 'orderBy':orderBy
					});
			},
			
			//查询  通讯管理机的采集设备
			searchAmmeters : function(orgId) {
				return BaseService.get('/api/archive/ammeter/searchAmmeters',{
						'orgId':orgId
					});
			},
			
			//DataTable条件查询测量点对象
			searchDataTable : function(dataTable, state) {
				return BaseService.get('/api/archive/ammeter/searchdatatable',{
						'dataTable':dataTable, 'state':state
					});
			},
			
			//条件查询测量点对象
			searchPage : function(state, orderBy, pageNo, pageSize) {
				return BaseService.get('/api/archive/ammeter/searchpage',{
						'state':state, 'orderBy':orderBy, 'pageNo':pageNo, 'pageSize':pageSize
					});
			},
		},
	    //监控对象类型数据	    
        MonitorTypeDataService :{
			
			//增加监控对象类型数据对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/monitorTypeData/add',mapParams);
			},
			
			//给监控对象类型添加数据项
			addDataItem : function(monitorObjectTypeId, dataItemIdStr) {
				return BaseService.post('/api/archive/monitorTypeData/addDataItem',{
						'monitorObjectTypeId':monitorObjectTypeId, 'dataItemIdStr':dataItemIdStr
					});
			},
			
			//删除监控对象类型数据对象
			del : function(id) {
				return BaseService.post('/api/archive/monitorTypeData/del',{
						'id':id
					});
			},
			
			//修改监控对象类型数据对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/monitorTypeData/edit',mapParams);
			},
			
			//修改监控对象类型数据对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/monitorTypeData/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取监控对象类型数据对象
			get : function(id) {
				return BaseService.get('/api/archive/monitorTypeData/get',{
						'id':id
					});
			},
			
			//DataTable查询监控对象类型数据对象
			getDataTable : function(dataTable, itemCategoryId, monitorObjectTypeId) {
				return BaseService.post('/api/archive/monitorTypeData/getdatatable',{
						'dataTable':dataTable, 'itemCategoryId':itemCategoryId, 'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//查询监控对象类型未选择的数据项
			getDataTableUnselected : function(dataTable, itemCategoryId, monitorObjectTypeId) {
				return BaseService.post('/api/archive/monitorTypeData/getDataTableUnselected',{
						'dataTable':dataTable, 'itemCategoryId':itemCategoryId, 'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//DataTable查询监控对象类型的数据项
			getDatatableByObjectType : function(dataTable, monitorObjectTypeId, ammeterId, monitorObjectId) {
				return BaseService.post('/api/archive/monitorTypeData/getDatatableByObjectType',{
						'dataTable':dataTable, 'monitorObjectTypeId':monitorObjectTypeId, 'ammeterId':ammeterId, 'monitorObjectId':monitorObjectId
					});
			},
			
			//查询所有监控对象类型数据对象
			getList : function(monitorObjectTypeId) {
				return BaseService.get('/api/archive/monitorTypeData/getList',{
						'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//查询监控对象类型的数据项列表
			getListByObjectType : function(monitorObjectTypeId) {
				return BaseService.get('/api/archive/monitorTypeData/getListByObjectType',{
						'monitorObjectTypeId':monitorObjectTypeId
					});
			},
			
			//分页显示监控对象类型数据对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/monitorTypeData/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//查询监控对象类型的且没有监控点的数据项
			getUnSelectDataItemList : function(monitorObjectTypeId, ammeterId) {
				return BaseService.get('/api/archive/monitorTypeData/getUnSelectDataItemList',{
						'monitorObjectTypeId':monitorObjectTypeId, 'ammeterId':ammeterId
					});
			},
			
			//移除监控对象类型数据项
			removeDataItem : function(monitorObjectTypeId, dataItemId) {
				return BaseService.post('/api/archive/monitorTypeData/removeDataItem',{
						'monitorObjectTypeId':monitorObjectTypeId, 'dataItemId':dataItemId
					});
			},
		},
	    //图元参数配置	    
        GraphEntityParamService :{
			
			//增加图元参数配置对象
			add : function(mapParams) {
						return BaseService.post('/api/archive/graphEntityParam/add',mapParams);
			},
			
			//给图元类型添加数据项
			addDataItem : function(graphEntityId, dataItemIdStr) {
				return BaseService.post('/api/archive/graphEntityParam/addDataItem',{
						'graphEntityId':graphEntityId, 'dataItemIdStr':dataItemIdStr
					});
			},
			
			//删除图元参数配置对象
			del : function(id) {
				return BaseService.post('/api/archive/graphEntityParam/del',{
						'id':id
					});
			},
			
			//修改图元参数配置对象
			edit : function(mapParams) {
						return BaseService.post('/api/archive/graphEntityParam/edit',mapParams);
			},
			
			//修改图元参数配置对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/graphEntityParam/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取图元参数配置对象
			get : function(id) {
				return BaseService.get('/api/archive/graphEntityParam/get',{
						'id':id
					});
			},
			
			//查询所有图元参数配置对象
			getAll : function() {
				return BaseService.get('/api/archive/graphEntityParam/getall');
			},
			
			//DataTable查询图元对应的数据项
			getDataTable : function(dataTable, itemCategoryId, graphEntityId) {
				return BaseService.post('/api/archive/graphEntityParam/getdatatable',{
						'dataTable':dataTable, 'itemCategoryId':itemCategoryId, 'graphEntityId':graphEntityId
					});
			},
			
			//查询图元类型未选择的数据项
			getDataTableUnselected : function(dataTable, monitorObjectTypeId, graphEntityId) {
				return BaseService.post('/api/archive/graphEntityParam/getDataTableUnselected',{
						'dataTable':dataTable, 'monitorObjectTypeId':monitorObjectTypeId, 'graphEntityId':graphEntityId
					});
			},
			
			//分页显示图元参数配置对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/graphEntityParam/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//移除图元类型数据项
			removeDataItem : function(graphEntityId, dataItemId) {
				return BaseService.post('/api/archive/graphEntityParam/removeDataItem',{
						'graphEntityId':graphEntityId, 'dataItemId':dataItemId
					});
			},
		},
	    //管理单元类型	    
        UnitTypeService :{
			
			//增加管理单元类型对象
			add : function(unitType, orgId, upFile) {
				return BaseService.upload('/api/archive/unitType/add',{
						'unitType':unitType, 'orgId':orgId, 'upFile':upFile
					});
			},
			
			//删除管理单元类型对象
			del : function(id, orgId) {
				return BaseService.post('/api/archive/unitType/del',{
						'id':id, 'orgId':orgId
					});
			},
			
			//修改管理单元类型对象
			edit : function(unitType, orgId, upFile) {
				return BaseService.upload('/api/archive/unitType/edit',{
						'unitType':unitType, 'orgId':orgId, 'upFile':upFile
					});
			},
			
			//修改管理单元类型对象的字段
			editField : function(id, fieldName, fieldValue, remarks) {
				return BaseService.post('/api/archive/unitType/editfield',{
						'id':id, 'fieldName':fieldName, 'fieldValue':fieldValue, 'remarks':remarks
					});
			},
			
			//根据ID获取管理单元类型对象
			get : function(id) {
				return BaseService.get('/api/archive/unitType/get',{
						'id':id
					});
			},
			
			//查询所有管理单元类型对象
			getAll : function() {
				return BaseService.get('/api/archive/unitType/getall');
			},
			
			//DataTable查询管理单元类型对象
			getDataTable : function(dataTable, orgId) {
				return BaseService.post('/api/archive/unitType/getdatatable',{
						'dataTable':dataTable, 'orgId':orgId
					});
			},
			
			//查询 通用类型和用户单独类型的单元
			getList : function(orgId) {
				return BaseService.get('/api/archive/unitType/getList',{
						'orgId':orgId
					});
			},
			
			//分页显示管理单元类型对象
			getPage : function(pageNo, pageSize) {
				return BaseService.get('/api/archive/unitType/getpage',{
						'pageNo':pageNo, 'pageSize':pageSize
					});
			},
			
			//根据ID获取管理单元类型对象
			getUnitTypeInfo : function(id) {
				return BaseService.get('/api/archive/unitType/getUnitTypeInfo',{
						'id':id
					});
			},
		},
 
}

export default archive;